import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Icon } from '@material-ui/core';
import styles from './snack-bar.module.scss';

const variantIcon = {
  success: 'check_circle',
  warning: 'warning',
  error: 'error',
  info: 'info',
};

function MySnackbarContentWrapper(props) {
  const { message, onClose, variant, ...other } = props;
  const iconType = variantIcon[variant];

  return (
    <SnackbarContent
      className={styles[variant]}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={styles.message}>
          <Icon className={`${styles.icon} ${styles.iconVariant}`}>
            {iconType}
          </Icon>
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <Icon className={styles.icon}>close</Icon>
        </IconButton>,
      ]}
      {...other}
    />
  );
}

export default function CustomizedSnackbars(props) {
  const { open: show = false, message, variant = 'success', onClose } = props;
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    setOpen(show);
  }, [show]);
  const handleClose = (event, reason) => {
    onClose();
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={8000}
        open={open}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={variant}
          message={message}
        />
      </Snackbar>
    </div>
  );
}

CustomizedSnackbars.propTypes = {
  message: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};
