import React from 'react';
import { InfoWindow } from 'react-google-maps';
import { Doughnut } from 'react-chartjs-2';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import 'twin.macro';

const DeliveryBoyInfoWidow = ({ infoWindowData, setIndex }) => {
  return (
    <InfoWindow
      position={infoWindowData.latestLatLng}
      onCloseClick={() => {
        setIndex(-1);
      }}
    >
      <div tw="grid grid-cols-1 gap-4">
        <div tw="inline-block uppercase font-bold mx-auto">
          <span tw="border-b-2 border-gray-500 pb-2">
            {infoWindowData.feName}
          </span>
        </div>
        <div tw="grid grid-cols-2">
          <div tw="flex items-center justify-center">
            <Doughnut
              data={{
                labels: ['Pending', 'Delivered', 'Undelivered'],
                datasets: [
                  {
                    backgroundColor: ['#667eea', '#48bb78', '#f56565'],
                    borderWidth: 0,
                    border: 0,
                    data: [
                      infoWindowData.pendingDel,
                      infoWindowData.del,
                      infoWindowData.undel,
                    ],
                  },
                ],
              }}
              options={{
                title: {
                  display: true,
                  text: 'Deliver',
                },
                legend: {
                  display: false,
                },
                cutoutPercentage: 60,
              }}
            />
          </div>
          <div tw="grid grid-cols-1 gap-2 my-4">
            <div tw="grid grid-cols-2 gap-2 text-right">
              <div tw="flex items-center">
                <div tw="h-2 w-2 bg-indigo-500 rounded-full mr-2 flex-shrink-0"></div>
                <div>Pending</div>
              </div>
              <div tw="font-bold">{infoWindowData.pendingDel}</div>
            </div>
            <div tw="grid grid-cols-2 gap-2 text-right">
              <div tw="flex items-center">
                <div tw="h-2 w-2 bg-green-500 rounded-full mr-2 flex-shrink-0"></div>
                <div>Delivered</div>
              </div>
              <div tw="font-bold">{infoWindowData.del}</div>
            </div>
            <div tw="grid grid-cols-2 gap-2 text-right">
              <div tw="flex items-center">
                <div tw="h-2 w-2 bg-red-500 rounded-full mr-2 flex-shrink-0"></div>
                <div>UnDelivered</div>
              </div>
              <div tw="font-bold">{infoWindowData.undel}</div>
            </div>
          </div>
        </div>

        <div tw="grid grid-cols-2">
          <div tw="flex items-center justify-center">
            <Doughnut
              data={{
                labels: [
                  'Pending',
                  'Picked Up',
                  'Cancelled',
                  'Rescheduled',
                  'N/A',
                ],
                datasets: [
                  {
                    backgroundColor: [
                      '#667eea',
                      '#48bb78',
                      '#f56565',
                      '#ecc94b',
                      '#a0aec0',
                    ],
                    borderWidth: 0,
                    border: 0,
                    data: [
                      infoWindowData.pendingPickup,
                      infoWindowData.pickup,
                      infoWindowData.cancelPickup,
                      infoWindowData.reschedulePickup,
                      infoWindowData.notAttemptedPickup,
                    ],
                  },
                ],
              }}
              options={{
                title: {
                  display: true,
                  text: 'Pickup',
                },
                legend: {
                  display: false,
                },
                cutoutPercentage: 60,
              }}
            />
          </div>
          <div tw="grid grid-cols-1 gap-2 my-4">
            <div tw="grid grid-cols-2 gap-2 text-right">
              <div tw="flex items-center">
                <div tw="h-2 w-2 bg-indigo-500 rounded-full mr-2 flex-shrink-0"></div>
                <div>Pending</div>
              </div>
              <div tw="font-bold">{infoWindowData.pendingPickup}</div>
            </div>
            <div tw="grid grid-cols-2 gap-2 text-right">
              <div tw="flex items-center">
                <div tw="h-2 w-2 bg-green-500 rounded-full mr-2 flex-shrink-0"></div>
                <div>Picked Up</div>
              </div>
              <div tw="font-bold">{infoWindowData.pickup}</div>
            </div>
            <div tw="grid grid-cols-2 gap-2 text-right">
              <div tw="flex items-center">
                <div tw="h-2 w-2 bg-red-500 rounded-full mr-2 flex-shrink-0"></div>
                <div>Cancelled</div>
              </div>
              <div tw="font-bold">{infoWindowData.cancelPickup}</div>
            </div>
            <div tw="grid grid-cols-2 gap-2 text-right">
              <div tw="flex items-center">
                <div tw="h-2 w-2 bg-yellow-500 rounded-full mr-2 flex-shrink-0"></div>
                <div>Rescheduled</div>
              </div>
              <div tw="font-bold">{infoWindowData.reschedulePickup}</div>
            </div>
            <div tw="grid grid-cols-2 gap-2 text-right">
              <div tw="flex items-center">
                <div tw="h-2 w-2 bg-gray-500 rounded-full mr-2 flex-shrink-0"></div>
                <div>N/A</div>
              </div>
              <div tw="font-bold">{infoWindowData.notAttemptedPickup}</div>
            </div>
          </div>
        </div>
      </div>
    </InfoWindow>
  );
};

export default DeliveryBoyInfoWidow;
