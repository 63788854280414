/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-array-index-key */
/*
  @Input Props
    rows: Array,
    columns: Array,
    orderBy: String (column name),
    meta: Object = {
      page: Number (page number),
      size: Number (page size),
      totalRecords: Number (total records in all pages)
    },
    autoPaginate: Boolean | default false
    handlePaginationChange: Function | handler function for server side pagination.
    autoPaginate should be false if using this
    (Paginate automatically with default page: 0, size: 5 & totalRecords: rows.length
    in this case meta field is not required)
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import Fab from '@material-ui/core/Fab';
import { IconButton, TablePagination, TableSortLabel } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './data-table.module.scss';
import { sortTable, getSorting } from '../../utils/common';
import TableConfigs from './table-configs';
import TablePaginationActions from './pagination';

export default class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: TableConfigs.pagination.page - 1,
      rowsPerPage: TableConfigs.pagination.size,
      order: 'desc',
      orderBy: '',
      autoPaginate: true,
      rows: [],
      columns: [],
      totalRecords: 0,
      noPaginate: false,
    };
  }

  componentDidMount() {
    this.reintializeState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.reintializeState(nextProps);
    const { searchTerm: oldSearch } = this.props;
    const { searchTerm: newSearch } = nextProps;
    if (oldSearch !== newSearch) {
      this.searchData(newSearch);
    }
  }

  reintializeState = (props) => {
    const { rows, columns, orderBy, meta, autoPaginate, noPaginate } = props;
    const { page, size, totalRecords } = meta;
    this.setState({
      page: autoPaginate ? TableConfigs.pagination.page - 1 : page - 1,
      rowsPerPage: autoPaginate ? TableConfigs.pagination.size : size,
      order: 'desc',
      orderBy,
      rows,
      columns,
      autoPaginate,
      noPaginate,
      totalRecords: autoPaginate ? rows.length : totalRecords,
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState(
      {
        page: newPage,
      },
      () => this.onPaginationChange(),
    );
  };

  onPaginationChange = () => {
    const { handlePaginationChange } = this.props;
    const { page, rowsPerPage, autoPaginate } = this.state;
    if (!autoPaginate) {
      handlePaginationChange(page + 1, rowsPerPage);
    }
  };

  handleChangeRowsPerPage = (event) => {
    let { rowsPerPage } = this.state;
    rowsPerPage = event.target.value;
    this.setState(
      {
        page: 0,
        rowsPerPage,
      },
      () => this.onPaginationChange(),
    );
  };

  handleRequestSort = (property) => {
    const { orderBy, order } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
    });
  };

  createRows(rows = [], columns = []) {
    const { order, orderBy, page, rowsPerPage, autoPaginate } = this.state;
    let sortedTable = sortTable(rows, getSorting(order, orderBy));

    if (autoPaginate) {
      sortedTable = sortedTable.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      );
    }
    return sortedTable.map((row, i) => (
      <TableRow key={i}>
        {columns.map((column, j) => {
          const { defaultValue = '-', className } = column;
          const canClick =
            column.onClick && (!column.clickIf || column.clickIf(row));
          const colSpan = column.colspan ? column.colspan : 0;
          const disabledRow = column.onClick && !canClick;

          switch (column.key) {
            case 'blank':
              break;
            case 'feNameTrack':
              return (
                <TableCell
                  className={className || ''}
                  // align='left'
                  style={{
                    width: '160px',
                    paddingLeft: 5,
                    fontSize: '11px',
                    // fontWeight: 'bold',
                    color: '#2C2C2C',
                    marginRight: 10,
                  }}
                  key={j}
                >
                  {row[column.key] !== null && row[column.key] !== undefined
                    ? row[column.key]
                    : defaultValue}
                </TableCell>
              );

            case 'index':
              return (
                <TableCell
                  className={className || ''}
                  onClick={canClick ? (e) => column.onClick(e, row) : null}
                  style={{
                    cursor: canClick ? 'pointer' : '',
                  }}
                  key={j}
                  colSpan={colSpan}
                >
                  {i + 1}
                </TableCell>
              );
            case 'actions':
              return (
                <TableCell
                  key={j}
                  className={className || ''}
                  onClick={canClick ? (e) => column.onClick(e, row) : null}
                  style={{ cursor: canClick ? 'pointer' : '' }}
                  colSpan={colSpan}
                >
                  {row.actions.map((action, k) => (
                    <IconButton
                      key={k}
                      color={action.color || 'primary'}
                      onClick={() => action.handler(row.id || i)}
                    >
                      <i className="material-icons"> {action.icon} </i>
                    </IconButton>
                  ))}
                </TableCell>
              );
            default:
              if (
                (column.link && !column.linkIf) ||
                (column.link && column.linkIf && column.linkIf(row))
              ) {
                return (
                  <TableCell
                    key={j}
                    className={`${styles['tableCell']} ${className || ''}`}
                    onClick={canClick ? (e) => column.onClick(e, row) : null}
                    style={{
                      cursor: canClick ? 'pointer' : '',
                      fontSize: '12px',
                    }}
                    colSpan={colSpan}
                  >
                    <Link
                      to={`${column.baseRoute}/${row[column.key]}`}
                      className={styles.rowlink}
                    >
                      {row[column.key] !== null && row[column.key] !== undefined
                        ? row[column.key]
                        : defaultValue}
                    </Link>
                  </TableCell>
                );
              }
              return (
                <TableCell
                  key={j}
                  className={`${styles['tableCell']} ${className || ''}`}
                  disabled={!canClick}
                  onClick={canClick ? (e) => column.onClick(e, row) : null}
                  colSpan={colSpan}
                  style={
                    canClick
                      ? column.customStyles
                        ? column.customStyles
                        : {
                            fontSize: '12px',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            color: '#009590',
                          }
                      : {
                          fontSize: '12px',
                          color: '#4b4b4b',
                          opacity: disabledRow ? 0.3 : 1,
                        }
                  }
                >
                  {row[column.key] !== null && row[column.key] !== undefined
                    ? row[column.key]
                    : defaultValue}
                  {column.tooltip && row['tooltip' + j] !== null ? (
                    <Tooltip
                      title={row['tooltip' + j]}
                      aria-label={row['tooltip' + j]}
                    >
                      {
                        {
                          InfoIcon: row.podCorrection ? (
                            <InfoIcon
                              fontSize="inherit"
                              color="error"
                              style={{ fontSize: '18px' }}
                            />
                          ) : (
                            <span></span>
                          ),
                          PhoneIphoneIcon: (
                            <PhoneIphoneIcon
                              fontSize="inherit"
                              style={{ fontSize: '18px', marginBottom: '-3px' }}
                            />
                          ),
                          default: (
                            <InfoIcon
                              fontSize="inherit"
                              color="error"
                              style={{ fontSize: '18px' }}
                            />
                          ),
                        }[column.icon]
                      }
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </TableCell>
              );
          }
        })}
      </TableRow>
    ));
  }

  searchData = (searchTerm = '') => {
    if (!searchTerm || !searchTerm.trim()) {
      return;
    }
    const searchString = searchTerm.trim().toLowerCase();
    //const { rows, columns } = this.state;
    const { rows, columns } = this.props;
    const filteredRows = rows.filter((row) => {
      const colLength = columns.length;
      for (let i = 0; i < colLength; i++) {
        const col = columns[i];
        let str = '';
        if (typeof row[col.key] === 'object' && row[col.key] !== null) {
          if (row[col.key].props && row[col.key].props.children) {
            str = (row[col.key].props.children || '')
              .toString()
              .trim()
              .toLowerCase();
          }
        } else {
          str = (row[col.key] || '').toString().trim().toLowerCase();
        }

        //str = (row[col.key] || '').toString().trim().toLowerCase();
        if (str.includes(searchString)) {
          return true;
        }
      }
      return false;
    });

    this.setState({ rows: filteredRows, totalRecords: filteredRows.length });
  };

  render() {
    const {
      page,
      rowsPerPage,
      orderBy,
      order,
      rows,
      columns,
      totalRecords,
      noPaginate,
    } = this.state;
    const { className } = this.props;
    return (
      <>
        <Table className={`${styles['table']} ${className || ''}`}>
          <TableHead className={styles['table__head']}>
            <TableRow>
              {columns.map((column) =>
                column.key === 'feNameTrack' ? (
                  <TableCell key={column.key}>
                    <TableSortLabel
                      style={{
                        fontSize: '11px',
                        fontWeight: 'bold',
                        color: '#2C2C2C',
                        width: 160,
                        marginRight: 10,
                      }}
                      classes={{
                        icon: styles.sortIcon,
                      }}
                      active={orderBy === column.key}
                      direction={order}
                      onClick={() => this.handleRequestSort(column.key)}
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell key={column.key}>
                    <TableSortLabel
                      style={{
                        fontSize: '11px',
                        fontWeight: 'bold',
                        color: '#2C2C2C',
                      }}
                      classes={{
                        icon: styles.sortIcon,
                      }}
                      active={orderBy === column.key}
                      direction={order}
                      onClick={() => this.handleRequestSort(column.key)}
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody className={styles['table__body']}>
            {this.createRows(rows, columns)}
          </TableBody>
        </Table>
        {!rows.length ? (
          <p className={styles['noRecords']}> No Records </p>
        ) : (
          ''
        )}
        {!noPaginate ? (
          <TablePagination
            rowsPerPageOptions={TableConfigs.pagination.available_sizes}
            component="div"
            count={totalRecords || rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        ) : null}
      </>
    );
  }
}

DataTable.propTypes = {
  handlePaginationChange: PropTypes.func,
  rows: PropTypes.array,
  columns: PropTypes.array,
  orderBy: PropTypes.string,
  meta: PropTypes.object,
  autoPaginate: PropTypes.bool,
};

DataTable.defaultProps = {
  rows: [],
  columns: [],
  orderBy: '',
  meta: {},
  handlePaginationChange: Function,
  autoPaginate: false,
};
