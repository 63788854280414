/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react';
import 'twin.macro';
import { useHistory } from 'react-router-dom';

function BackButton() {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <button
      tw="flex items-center justify-center p-2 mr-4 bg-white rounded-full shadow cursor-pointer focus:outline-none"
      onClick={handleGoBack}
    >
      <i className="material-icons">keyboard_backspace</i>
    </button>
  );
}

export default BackButton;
