import axios from 'axios';
import { PARTNER_URL } from '../environments';
import { responseErrorHander } from './network-interceptor.service';

export const getUnreadNotification = async (locationId) => {
  try {
    const result = await axios.post(`${PARTNER_URL}/event-notification/get`,
    {
      "isRead" : "N",
      locationIds: [locationId]
    })
    return result.data
  } catch (e) {
    return responseErrorHander(e);
  }
}

export const markNotificationAsRead = async (data) => {
  try {
    const result = await axios.put(`${PARTNER_URL}/event-notification`, data)
    return result.data
  } catch (e) {
    return responseErrorHander(e);
  }
}