import * as React from 'react';
import { useController } from 'react-hook-form';

import StyledTextFiled from '../styled-components/TextFiled';
import StyledError from '../styled-components/Error';

function TextField(
  { id = '', name = '', control, defaultValue = '', error = '', ...props },
  ref,
) {
  const { field } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <>
      <StyledTextFiled id={id} error={!!error} {...props} {...field} />
      {error ? <StyledError>{error}</StyledError> : null}
    </>
  );
}

export default React.forwardRef(TextField);
