import React from 'react';
import PatternInput from './pattern-input';

/**
 * Input field for only number input
 *
 *  maxallowedchars: For restricting maximum character of input
 *
 *  inputformat: for which type of input: values are BootstrapInput,TextField, InputBase and input. Default InputBase.
 * @param {..props, inputformat, maxallowedchars} props
 */
const NumberInput = (props) => {
  const validatepattern = /[^\d]/;
  const replacepattern = /[^\d]/g;

  return (
    <PatternInput
      {...props}
      validatepattern={validatepattern}
      replacepattern={replacepattern}
    />
  );
};

export default NumberInput;
