import React from 'react';
import { InfoWindow } from 'react-google-maps';

const SimpleInfoWindow = ({ simpleInfoWindow, setSimpleInfoWindow }) => {
  return (
    <InfoWindow
      position={simpleInfoWindow.position}
      onCloseClick={() => {
        setSimpleInfoWindow(false);
      }}
    >
      <div>{simpleInfoWindow.message}</div>
    </InfoWindow>
  );
};

export default SimpleInfoWindow;
