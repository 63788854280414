import axios from 'axios';
import { createQueryString } from '../utils/utility-functions';
import { responseErrorHander } from './network-interceptor.service';
import { AGGREGATOR_URL, PARTNER_URL, NETWORK_URL } from '../environments';



export const getTrackingByWaybills = async (page = 0, waybillNoList = '') => {
  const pageNo = !waybillNoList ? page : 0;
  waybillNoList = waybillNoList.split(',');
  try {
    const response = await axios.post(`${PARTNER_URL}/tracking/get`, {
      page: pageNo,
      waybillNoList,
    });
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const getETA = async (destinationCoordinates, originCoordinates) => {

  try {
    const response = await axios.post(`${PARTNER_URL}/tracking/eta`, {
      origin: originCoordinates.lat+ ","+originCoordinates.lng,
      destination: destinationCoordinates.lat +","+destinationCoordinates.lng,
    });
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const getWeybillDetails = async (client_key, waybillNo, pageNo = 0) => {
  const queryString = createQueryString({
    key: client_key,
    waybillNo,
    pageNo,
  });
  try {
    const response = await axios.get(
      `${PARTNER_URL}/tracking/get_detail${queryString}`,
    );
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const getLatLngUpdate = async(client_key, drsId) => {
  
  try {
    const response = await axios.get(
      `${PARTNER_URL}/tracking/latlng?drsId=${drsId}&key=${client_key}`,
    );
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
}

export const getWeybillDetailsInternal = async (waybillNo, pageNo = 0) => {
  const queryString = createQueryString({ pageNo, waybillNo });
  try {
    const response = await axios.get(
      `${PARTNER_URL}/tracking/internal_get_detail${queryString}`,
    );
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const getShipperDetails = async (customerCode) => {
  try {
    const response = await axios.get(
      `${NETWORK_URL}/customers/${customerCode}`,
    );
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const getExternalAPIDetails = async (waybillNo) => {
  try {
    const response = await axios.get(
      `${AGGREGATOR_URL}/meghraj/tracking?awbNo=${waybillNo}`,
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getExternalDRSDetails = async (waybillNo, imgId) => {
  try {
    const response = await axios.get(
      `${AGGREGATOR_URL}/meghraj/tracking/drs-image?awbNo=${waybillNo}&imageId=${imgId}`,
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
