import React from 'react';
import { Select } from '@material-ui/core';
import BootstrapInput from './bootstrap-input';

const BootstrapSelect = (props) => {
  const { children, inputProps, ...rest } = props;

  return (
    <Select {...rest} input={<BootstrapInput {...inputProps} />}>
      {children}
    </Select>
  );
};

export default BootstrapSelect;
