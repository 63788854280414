import {
  FETCH_FE_PERFORMANCE_REQUEST,
  FETCH_FE_PERFORMANCE_SUCCESS,
  FETCH_FE_PERFORMANCE_FAILURE,
} from '../types/fePerformance.types';

const initialState = {
  loading: null,
  data: [],
  error: '',
};

const fePerformanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FE_PERFORMANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FE_PERFORMANCE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_FE_PERFORMANCE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default fePerformanceReducer;
