let STORE;

const setupReduxService = (store) => {
  STORE = store;
};

export const isModulePermitted = (_module, _submodule, _action) => {
  const { login = {} } = STORE.getState();
  const { permissions = [] } = login;
  const moduleInfo = permissions.find(
    (perm) => perm.modules.toLowerCase() === _module.toLowerCase(),
  );
  if (!moduleInfo) {
    return false;
  }
  if (!moduleInfo.submodules || moduleInfo.submodules.length === 0) {
    return false;
  }
  if (!_submodule) {
    return true;
  }
  const submodule = moduleInfo.submodules.find(
    (subm) => subm.name.toLowerCase() === _submodule.toLowerCase(),
  );

  if (!submodule) {
    return false;
  }
  if (!submodule.actions || submodule.actions.length === 0) {
    return false;
  }
  if (!_action) {
    return true;
  }
  const action = submodule.actions.find(
    (act) => act.toLowerCase() === _action.toLowerCase(),
  );
  if (!action) {
    return false;
  }
  return true;
};

export default setupReduxService;
