/* eslint-disable import/prefer-default-export */

export const MODULES = {
  TRACKING: 'Tracking',
  DASHBOARD: 'Dashboard',
  LINEHAUL: 'Linehaul',
  REPORTS: 'Reports',
  CONFIGURATIONS: 'Configurations',
  OPERATIONS: 'Operations',
  COD: 'COD',
  BILLING: 'Billing',
};

export const SUBMODULES = {
  CUSTOMERS: 'Customer',
  LOCATIONS: 'Location',
  PERMISSIONS: 'Permissions',
  PRODUCTS: 'Product',
  ROLES: 'Role',
  USERS: 'Users',
  FRANCHISEES: 'Franchisee',
  INVENTORY: 'Inventory',
  UPLOAD: 'Upload',
  DRS: 'DRS',
  HANDOVER: 'Handover',
  POD: 'POD',
  PICKUP: 'Pickup',
  COURIERCONNECT: 'Courier Connect',
  TRIP: 'Trip',
  ROUTE: 'Route',
  DRIVER: 'Driver',
  VEHICLE: 'Vehicle',
  TRANSPORTER: 'Transporter',
  BAGGING: 'Bagging',
  DEBAGGING: 'Debagging',
  WAYBILLINVENTORY: 'Waybill Inventory',
  CODREMITTANCE: 'COD Remittance',
  CODAUDIT: 'COD Audit',
  CUSTREMITTANCE: 'COD Customer remittance',
  PINCODE: 'Pincode',
  UPLOADTRANSIT: 'Upload Common',
  BOOKING: 'Booking',
  ROUTE_CONNECTION: 'Route Connection',
  INVOICE: 'Invoice',
  RATE: 'Rate',
  VAS: 'VAS',
  ZONE: 'Zone',
  REGION: 'Region',
  STATUS_CODE: 'Status Code',
  COMMODITY_CODE: 'Commodity Code',
  PINCODE: 'Pincode'
};

export const ACTIONS = {
  CREATE: 'Create',
  DOWNLOAD: 'Download',
  UPLOAD: 'Upload',
  UPDATE: 'Update',
  DELETE: 'Delete',
  READ: 'Read',
  ASSIGN: 'assign',
};
export const idleTimeOutDuration = 60000 * 30; //30 minutes idle time
