import React, { useState, useEffect } from 'react';
import { Marker, DirectionsRenderer } from 'react-google-maps';

const MapDirections = ({
  setWayPointIndex,
  currentCoordinates,
  simpleInfoWindow,
  setSimpleInfoWindow,
  destinationCoordinates,
}) => {
  const [directions, setDirections] = useState();
  const [startPoint, setStartPoint] = useState(destinationCoordinates);
  const [endPoint, setEndPoint] = useState(currentCoordinates);

  useEffect(() => {
    // start point and end point
    ///start point -> driver location
    // end point -> destination location

    const google = window.google;

      const DirectionsService = new google.maps.DirectionsService();
      DirectionsService.route(
        {
          origin: new google.maps.LatLng(startPoint.lat, startPoint.lng),
          destination: new google.maps.LatLng(endPoint.lat, endPoint.lng),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections({
              directions: result,
            });
          } else {
            console.error(`error fetching directions ${result}`);
          }
        },
      );
  }, [currentCoordinates,destinationCoordinates]);

  if (directions) {
    return (
      <>
        <DirectionsRenderer
          options={{
            suppressMarkers: true,
            preserveViewport: true
          }}
          directions={directions.directions}
        />
        {startPoint && (
          <Marker
            position={{ lat: startPoint.lat, lng: startPoint.lng }}
            icon="https://cubicom-s3-bucket-image.s3-ap-southeast-1.amazonaws.com/map_assets/active-ballon-icon.svg"
            onClick={() => {
              setSimpleInfoWindow({
                ...simpleInfoWindow,
                position: { lat: startPoint.lat, lng: startPoint.lng },
                message: 'Start',
                toggle: true,
              });
            }}
          />
        )}
        {endPoint && endPoint.flag ? (
          <Marker
            position={{ lat: endPoint.lat, lng: endPoint.lng }}
            icon="https://cubicom-s3-bucket-image.s3-ap-southeast-1.amazonaws.com/map_assets/inactive-ballon-icon.svg"
            onClick={() => {
              setSimpleInfoWindow({
                ...simpleInfoWindow,
                position: { lat: endPoint.lat, lng: endPoint.lng },
                message: 'End',
                toggle: true,
              });
            }}
          />
        ) : (
          <Marker
            position={{ lat: endPoint.lat, lng: endPoint.lng }}
            icon="https://cubicom-s3-bucket-image.s3-ap-southeast-1.amazonaws.com/map_assets/biker-icon.svg"
          />
        )}
        {/* {wayPoints.length &&
          wayPoints.map((d, index) => {
            return (
              <WayPointsMarker
                key={index}
                data={d}
                index={index}
                setIndex={setWayPointIndex}
              />
            );
          })} */}
      </>
    );
  } else {
    return null;
  }
};

export default MapDirections;
