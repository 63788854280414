/* eslint-disable no-param-reassign */
import Axios from 'axios';
import { loader, errorHandler } from '../store/common/actions/common.actions';
import { clearAuth } from '../store/common/actions/login.actions';
import { IdleLogout } from '../app/common/utils/IdleLogout';

var jstz = require('jstimezonedetect');

export const responseErrorHander = (error = {}) => {
  const { data: response, statusText = 'Something went wrong!' } = error || {};
  if (!response) {
    return {
      status: {
        success: false,
        message: statusText,
      },
    };
  }
  if (response) {
    return {
      status: {
        success: false,
        message: response.status
          ? response.status.message
          : '' || 'Something went wrong!',
      },
    };
  }
  return {
    status: {
      success: false,
      message: statusText,
    },
  };
};

/* Loader Show/Hide logic */
let count = 0;
const showLoader = (store) => {
  store.dispatch(loader(true));
  count++;
};

const hideLoader = (store) => {
  if (count <= 1) {
    store.dispatch(loader(false));
    count = 0;
  } else {
    count--;
  }
};

/* Error Show/Hide logic */
const handleError = (store, err = null) => {
  if (err) {
    store.dispatch(errorHandler(err));
    if (err.status === 401) {
      store.dispatch(clearAuth());
    }
  }
};

const setLastAccesTS = (store) => {
  IdleLogout.setLastActivityTime();
};

const handleIdleUse = (store) => {
  IdleLogout.logoutUserIfTimeOutExceeded(store);
  IdleLogout.setLastActivityTime();
};

/* Function to validate|prepare|modify error object */
const prepareErrorObject = (error) => {
  let err = error.response ? error.response.data : error;
  if (typeof err === 'object') {
    err.timestamp = Date.now();
    err.config = error.config;
  } else {
    err = {};
    err.message = 'Something went wrong.';
    err.timestamp = Date.now();
  }
  return err;
};

export default {
  setupInterceptors: (store) => {
    // Requests interceptor
    Axios.interceptors.request.use(
      (config) => {
        const { headers = {}, url } = config;
        const { silent = false } = headers;
        var isUrl = url.includes('event-notification/get');
        if (isUrl) {
          hideLoader(store);
        } else if (!silent) {
          showLoader(store);
        }
        if (headers.silent) {
          delete headers.silent;
        }
        handleIdleUse(store);
        const { login = {} } = store.getState();
        const { auth = {} } = login;
        const { access_token } = auth;
        if (access_token) {
          config.headers.Authorization = `Bearer ${access_token}`;
        }

        if (!url.includes('token')) {
          config.headers['X-CLIENT_ID'] = 'cubicom_erp';
        }
        config.headers['Content-Type'] = 'application/json';
        config.headers['X-Time-Zone'] = jstz.determine().name();
        return config;
      },
      (error) => {
        hideLoader(store);
        handleError(store, error);
        return Promise.reject(error || null);
      },
    );

    // Response interceptor
    Axios.interceptors.response.use(
      (response) => {
        const { data = {} } = response;
        hideLoader(store);
        setLastAccesTS(store);
        if (data.status >= 400) {
          const err = prepareErrorObject(data);
          handleError(store, err);
        }
        return response;
      },
      (error) => {
        const err = prepareErrorObject(error);
        handleError(store, err);
        hideLoader(store);
        return Promise.reject(error ? error['response'] : null);
      },
    );
  },
};
