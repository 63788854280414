import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';

const DashboardIcon = (props) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    fill={props.fill}
    id={props.id}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs />
    <path id={props.id} style={{ fill: 'none' }} d="M0,0H24V24H0Z" />
    <path
      id={props.id}
      d="M3,11.889h7.111V3H3ZM3,19h7.111V13.667H3Zm8.889,0H19V10.111H11.889Zm0-16V8.333H19V3Z"
      transform="translate(1 1)"
    />
  </svg>
);

const OperationsIcon = (props) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    id={props.id}
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs />
    <g id={props.id} transform="translate(-22 -132)">
      <g id={props.id} transform="translate(25 37.305)">
        <path
          id={props.id}
          d="M8.26,103.3l-1.035,2.514a.247.247,0,0,1-.207.152l-5.295.465a.247.247,0,0,0-.225.252l.108,4.156a.247.247,0,0,0,.2.235l6.233,1.311a2.22,2.22,0,0,0,.462.048v-9.086C8.5,103,8.39,102.978,8.26,103.3Zm-.725,7.751-2.34-.377v-1.226h2.34Zm-1.023-5.682-6.24.631a.247.247,0,0,1-.248-.35l1.662-3.552a.247.247,0,0,1,.179-.138L8.72,100.7l-2.008,4.523A.247.247,0,0,1,6.512,105.364Zm9.253,1.063-5.295-.465a.247.247,0,0,1-.207-.152L9.228,103.3c-.131-.317-.238-.3-.238.047v9.086a2.22,2.22,0,0,0,.462-.048l6.233-1.311a.247.247,0,0,0,.2-.235l.108-4.156A.247.247,0,0,0,15.765,106.426Zm-2.179,2.22-.292.008-.006,1.84a.2.2,0,0,1-.185.173l-.479.069c-.109.016-.2-.04-.2-.125l0-1.933-.334.009c-.163,0-.266-.126-.183-.234l.8-1.037a.221.221,0,0,1,.333.014l.7,1C13.8,108.521,13.721,108.642,13.586,108.646Zm3.63-2.652-6.24-.631a.247.247,0,0,1-.2-.145l-2.008-4.523,6.856,1.259a.247.247,0,0,1,.179.138l1.662,3.552A.247.247,0,0,1,17.216,105.994Z"
          transform="translate(0)"
        />
      </g>
      <rect
        id={props.id}
        style={{ fill: 'none' }}
        width="24"
        height="24"
        transform="translate(22 132)"
      />
    </g>
  </svg>
);

const UploadIconConfig = (props) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
    id={props.id}
    width="28"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs />

    <path
      id={props.id}
      d="M18.494,12.359a.33.33,0,0,1,0,.467.327.327,0,0,1-.233.1H15.1V18.2h-6.6V12.923H5.33a.33.33,0,0,1-.233-.564L11.8,5Z"
    />
  </svg>
);

const UploadIcon = (props) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
    id={props.id}
    width="28"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs />
    <path
      id={props.id}
      d="M32,38h2.734v.547H32Z"
      transform="translate(-20.346 -24.405)"
    />
    <path
      id={props.id}
      d="M14.388,4.574H4V17.969h7.108V12.228a.82.82,0,0,1,.82-.82h2.46ZM5.093,9.768H7.28v.547H5.093Zm0,1.64H7.28v.547H5.093ZM7.28,16.876H5.093v-.547H7.28Zm2.734,0H7.827v-.547h2.187Zm0-1.64H5.093v-.547h4.921Zm0-1.64H5.093v-.547h4.921Zm0-1.64H7.827v-.547h2.187Zm3.28-1.64H7.827V9.768h5.467Zm0-1.914a.273.273,0,0,1-.273.273H5.367A.273.273,0,0,1,5.093,8.4V7.308a.273.273,0,0,1,.273-.273h7.654a.273.273,0,0,1,.273.273Z"
    />
    <path
      id={props.id}
      d="M34.734,32h-2.46a.273.273,0,0,0-.273.273v.82h2.734Z"
      transform="translate(-20.346 -20.045)"
    />
    <path
      id={props.id}
      d="M50,38h2.734v.547H50Z"
      transform="translate(-33.425 -24.405)"
    />
    <path
      id={props.id}
      d="M52.734,32.273A.273.273,0,0,0,52.46,32H50v1.093h2.734Z"
      transform="translate(-33.425 -20.045)"
    />
    <path
      id={props.id}
      d="M44.7,34.78l.4.263V32H44v3.043l.4-.263A.273.273,0,0,1,44.7,34.78Z"
      transform="translate(-29.065 -20.045)"
    />
    <path
      id={props.id}
      d="M32.273,46.921h7.108a.273.273,0,0,0,.273-.273V42H36.921v.82a.273.273,0,0,1-.425.227l-.668-.446-.668.446a.273.273,0,0,1-.425-.227V42H32v4.647A.273.273,0,0,0,32.273,46.921Zm6.287-3.007h.547v.547h-.547Zm-.82,1.914h.82v-.82h.547V46.1a.273.273,0,0,1-.273.273H37.741Z"
      transform="translate(-20.346 -27.311)"
    />
  </svg>
);

const DRSIcon = (props) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
    id={props.id}
    width="28"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs />
    <path
      id={props.id}
      d="M17.971,8.014,15.725,6.8,8.9,10.6l2.7,1.463.352-.19a5.049,5.049,0,0,1,2.025-1.1l3.986-2.159a.34.34,0,0,0,0-.6Zm-1.239,2.775a5.042,5.042,0,0,1,1.483.7V9.965ZM5.244,8.613,7.528,9.852l6.821-3.8L11.922,4.737a.662.662,0,0,0-.629,0L5.244,8.014a.34.34,0,0,0-.137.461A.343.343,0,0,0,5.244,8.613Zm5.626,4.613L5,9.965v5.558a.66.66,0,0,0,.34.578l5.607,3.115v-.981A5.063,5.063,0,0,1,10.87,13.226Zm4.454-1.235A3.675,3.675,0,1,0,19,15.667a3.676,3.676,0,0,0-3.676-3.676Zm2.065,3.882a.207.207,0,0,1-.206.207H15.737v1.445a.207.207,0,0,1-.206.207h-.413a.207.207,0,0,1-.206-.207V16.08H13.466a.207.207,0,0,1-.206-.207V15.46a.206.206,0,0,1,.206-.206h1.446V13.808a.206.206,0,0,1,.206-.206h.413a.206.206,0,0,1,.206.206v1.446h1.446a.206.206,0,0,1,.206.206Z"
    />
    <rect
      id={props.id}
      style={{ fill: 'none' }}
      width="24"
      height="24"
      transform="translate(0 0)"
    />
  </svg>
);

const PODIcon = (props) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    fill={props.fill}
    id={props.id}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs />
    <rect
      id={props.id}
      style={{ fill: '#ff13dc00' }}
      width="18"
      height="18"
      transform="translate(3 3)"
    />
    <rect id={props.id} width="5" height="1" transform="translate(13 8)" />
    <rect id={props.id} width="5" height="1" transform="translate(13 12)" />
    <rect id={props.id} width="6" height="1" transform="translate(6 14)" />
    <rect id={props.id} width="5" height="1" transform="translate(13 10)" />
    <rect id={props.id} width="5" height="1" transform="translate(13 14)" />
    <rect id={props.id} width="6" height="5" transform="translate(6 8)" />
    <path
      id={props.id}
      d="M19.5,5H4.5a.5.5,0,0,0-.5.5v12a.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5V5.5A.5.5,0,0,0,19.5,5ZM5,17V6H19V17Z"
    />
    <rect
      id={props.id}
      style={{ fill: 'none' }}
      width="24"
      height="24"
      transform="translate(0 0)"
    />
  </svg>
);

export const HandoverIcon = (props) => (
  <svg
    style={{ verticalAlign: 'middle', marginBottom: '-8px' }}
    fill={props.fill}
    id={props.id}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs />
    <g transform="translate(-4.207 -8)">
      <path
        className="a"
        d="M0,0H2.965V4.743H0Z"
        transform="translate(4.207 19.598) rotate(-34.978)"
      />
      <path
        className="a"
        d="M28.7,38.162a.563.563,0,0,0-.03-.305.593.593,0,0,0-.759-.356l-2.953,1.076a1.191,1.191,0,0,1-1.138.845,1.012,1.012,0,0,1-.21-.021l-3.2-.566.1-.581,3.2.566A.6.6,0,0,0,24.4,38.4a.524.524,0,0,0,.015-.249.6.6,0,0,0-.1-.255.607.607,0,0,0-.385-.246l-2.977-.525a5.705,5.705,0,0,0-2.624.163l-.053.015a5.514,5.514,0,0,0-1.574.771l-.931.649L17.641,41.4a3.227,3.227,0,0,1,2.4-.516l.531.089a3.258,3.258,0,0,0,1.684-.145l6.069-2.209A.593.593,0,0,0,28.7,38.162Z"
        transform="translate(-8.135 -20.431)"
      />
      <path
        className="a"
        d="M48.558,9.186V8.3a.3.3,0,0,0-.3-.3H45V9.186Z"
        transform="translate(-28.699 0)"
      />
      <path
        className="a"
        d="M37.679,9.865a.3.3,0,0,1,.421,0l.679.682V8H37v2.547Z"
        transform="translate(-23.071 0)"
      />
      <path
        className="a"
        d="M23,8.3v.889h3.558V8H23.3A.3.3,0,0,0,23,8.3Z"
        transform="translate(-13.222 0)"
      />
      <path
        className="a"
        d="M26.741,15.755a.293.293,0,0,1-.184-.273V14H23v6.59c.065-.024.13-.05.2-.068l.053-.018a6.224,6.224,0,0,1,2.888-.172l2.98.525a1.148,1.148,0,0,1,.548.258h2.084l1.049-.382a1.141,1.141,0,0,1,.279-.059V14H29.522v1.482a.293.293,0,0,1-.184.273.248.248,0,0,1-.113.024.3.3,0,0,1-.21-.086l-.975-.978-.975.978A.3.3,0,0,1,26.741,15.755Z"
        transform="translate(-13.222 -4.221)"
      />
    </g>
  </svg>
);

const ReportsIcon = (props) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    fill={props.fill}
    id={props.id}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs />
    <g id={props.id} transform="translate(0.863 0.863)">
      <rect
        id={props.id}
        style={{ fill: '#ff13dc00' }}
        width="16"
        height="16"
        transform="translate(3.137 3.137)"
      />
      <path
        id={props.id}
        d="M11.2,7.6h4.4L11.2,3.2V7.6M5.6,2H12l4.8,4.8v9.6A1.6,1.6,0,0,1,15.2,18H5.6A1.6,1.6,0,0,1,4,16.4V3.6A1.594,1.594,0,0,1,5.6,2m.8,14.4H8V11.6H6.4v4.8m3.2,0h1.6V10H9.6v6.4m3.2,0h1.6V13.2H12.8Z"
        transform="translate(1.137 1.137)"
      />
    </g>
    <rect
      style={{ fill: 'none' }}
      width="24"
      height="24"
      transform="translate(0 0)"
    />
  </svg>
);

const TrackingIcon = (props) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    fill={props.fill}
    id={props.id}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs />
    <g id={props.id} transform="translate(0.863 0.863)">
      <rect
        id={props.id}
        style={{ fill: '#ff13dc00' }}
        width="16"
        height="16"
        transform="translate(3.137 3.137)"
      />
      <path
        id={props.id}
        d="M15.656,4.344,14.528,5.472A6.407,6.407,0,1,1,9.2,3.656V5.272a4.8,4.8,0,1,0,4.192,1.336L12.264,7.736A3.208,3.208,0,1,1,9.2,6.912V8.624A1.584,1.584,0,0,0,8.4,10a1.6,1.6,0,0,0,3.2,0,1.574,1.574,0,0,0-.8-1.376V2H10a8,8,0,1,0,5.656,2.344Z"
        transform="translate(-2 -2)"
      />
    </g>
    <rect
      style={{ fill: 'none' }}
      width="24"
      height="24"
      transform="translate(0 0)"
    />
  </svg>
);

const ConfIcon = (props) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    fill={props.fill}
    id={props.id}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs />
    <path
      id={props.id}
      d="M19.45,10.9H17.894a5.962,5.962,0,0,0-.921-2.254l1.1-1.1a.551.551,0,0,0,0-.778l0,0-.837-.836a.55.55,0,0,0-.777,0l0,0-1.1,1.1A5.962,5.962,0,0,0,13.1,6.106V4.55a.55.55,0,0,0-.55-.55h-1.1a.55.55,0,0,0-.55.55h0V6.106a5.983,5.983,0,0,0-2.254.92l-1.1-1.1a.55.55,0,0,0-.777,0l0,0-.842.836a.551.551,0,0,0,0,.778l0,0,1.1,1.1A5.994,5.994,0,0,0,6.105,10.9H4.55a.549.549,0,0,0-.55.549v1.1a.55.55,0,0,0,.55.55H6.105a5.985,5.985,0,0,0,.921,2.254l-1.1,1.106a.55.55,0,0,0,0,.777h0l0,0,.836.836a.551.551,0,0,0,.778,0l0,0,1.1-1.1a5.978,5.978,0,0,0,2.253.92V19.45a.55.55,0,0,0,.55.55h1.1a.55.55,0,0,0,.55-.55V17.894a5.972,5.972,0,0,0,2.253-.92l1.1,1.1a.551.551,0,0,0,.778,0h0l0,0,.836-.836a.55.55,0,0,0,0-.778l0,0-1.1-1.1a5.964,5.964,0,0,0,.921-2.253H19.45a.55.55,0,0,0,.55-.55v-1.1a.549.549,0,0,0-.546-.553h0ZM14.207,12A2.207,2.207,0,1,1,12,9.793h0A2.207,2.207,0,0,1,14.207,12Z"
    />
    <rect
      id={props.id}
      style={{ fill: 'none' }}
      width="24"
      height="24"
      transform="translate(0 0)"
    />
  </svg>
);

const CustomerIcon = (props) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    fill={props.fill}
    id={props.id}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs />
    <g transform="translate(-45 -251)">
      <rect
        className="a"
        width="24"
        height="24"
        transform="translate(45 251)"
      />
      <g transform="translate(48 254)">
        <rect className="b" width="18" height="18" />
        <path
          className="c"
          d="M9,.5A8.5,8.5,0,1,0,17.5,9,8.5,8.5,0,0,0,9,.5Zm5.491,13.592a5.413,5.413,0,0,0-3.112-1.564.654.654,0,0,1-.566-.656v-.946a.657.657,0,0,1,.166-.422,5,5,0,0,0,1.14-3.118c0-2.36-1.254-3.68-3.143-3.68S5.8,5.074,5.8,7.385A5.051,5.051,0,0,0,6.989,10.5a.655.655,0,0,1,.167.422v.942a.65.65,0,0,1-.57.655,5.432,5.432,0,0,0-3.12,1.521,7.5,7.5,0,1,1,11.025.047Z"
        />
      </g>
    </g>
  </svg>
);

const LocationIcon = (props) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    fill={props.fill}
    id={props.id}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs />
    <rect className="a" width="24" height="24" transform="translate(0 0)" />
    <path
      id={props.id}
      className="b"
      d="M9,.962a6,6,0,0,0-6,6c0,3.313,6,10.875,6,10.875s6-7.562,6-10.875a6,6,0,0,0-6-6ZM9,9.325A2.325,2.325,0,1,1,11.325,7,2.325,2.325,0,0,1,9,9.325Z"
      transform="translate(3 3)"
    />
  </svg>
);

const PermissionIcon = (props) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    fill={props.fill}
    id={props.id}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs />
    <rect className="a" width="24" height="24" transform="translate(0 0)" />
    <path
      id={props.id}
      className="b"
      d="M16.562,4.155,13.549,1.141a.7.7,0,0,0-.989,0h0L6.144,7.557a4.15,4.15,0,0,0-1.948-.5,4.2,4.2,0,1,0,4.2,4.2A4.15,4.15,0,0,0,7.9,9.326L11.039,6.19l1.744,1.744a.35.35,0,0,0,.495,0h0l1.559-1.559L12.846,4.383l.574-.574L15.411,5.8l1.151-1.152a.35.35,0,0,0,0-.495ZM3.5,13.351a1.4,1.4,0,1,1,1.4-1.4A1.4,1.4,0,0,1,3.5,13.351Z"
      transform="translate(3.603 3.589)"
    />
  </svg>
);

const ProductIcon = (props) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    fill={props.fill}
    id={props.id}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs />
    <g transform="translate(-45 -251)">
      <rect
        className="a"
        width="24"
        height="24"
        transform="translate(45 251)"
      />
      <g transform="translate(48 254)">
        <rect className="b" width="18" height="18" />
        <path
          className="c"
          d="M8.2,17.8,1.411,14.026a.8.8,0,0,1-.411-.7V6.6l7.2,4Z"
        />
        <path
          className="c"
          d="M16.588,14.026,9.8,17.8V10.6l7.2-4v6.729a.8.8,0,0,1-.412.7Z"
        />
        <path
          className="c"
          d="M12.318,1.859,9.381.268a.8.8,0,0,0-.762,0L1.3,4.234a.412.412,0,0,0,0,.726l2.765,1.5Z"
        />
        <path
          className="c"
          d="M16.7,4.234,13.985,2.761l-8.257,4.6L9,9.132l7.7-4.173a.412.412,0,0,0,0-.725Z"
        />
      </g>
    </g>
  </svg>
);

const RoleIcon = (props) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    fill={props.fill}
    id={props.id}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs />
    <g transform="translate(-45 -251)">
      <rect
        className="a"
        width="24"
        height="24"
        transform="translate(45 251)"
      />
      <path
        id={props.id}
        className="b"
        d="M6.81,12.96a6.144,6.144,0,0,1,2.713-5.1.74.74,0,0,1,.166-.377,5.387,5.387,0,0,0,.981-1.84,4.953,4.953,0,0,0,.288-1.573A5.4,5.4,0,0,0,10.7,2.357,3.179,3.179,0,0,0,7.481,0,3.4,3.4,0,0,0,5.456.614a3.016,3.016,0,0,0-.65.665A4.511,4.511,0,0,0,3.982,4.05a4.727,4.727,0,0,0,.138,1.067A5.488,5.488,0,0,0,5.25,7.5a.722.722,0,0,1,.183.465c.016.418.042.733.042,1.016a.716.716,0,0,1-.625.722C.667,10.071.014,12.932.014,14.06c0,.125.024.763.024.763H7.1a6.142,6.142,0,0,1-.29-1.863Z"
        transform="translate(48 254.004)"
      />
      <path
        className="b"
        d="M17.615,12.271H16.407a3.49,3.49,0,0,0-.51-1.238l.86-.86a.345.345,0,0,0,0-.487l-.522-.523a.345.345,0,0,0-.488,0l-.86.86a3.491,3.491,0,0,0-1.238-.51V8.305a.345.345,0,0,0-.345-.345h-.69a.345.345,0,0,0-.345.345V9.512a3.492,3.492,0,0,0-1.238.51l-.86-.86a.345.345,0,0,0-.487,0l-.523.523a.345.345,0,0,0,0,.488l.86.86a3.49,3.49,0,0,0-.51,1.238H8.305a.345.345,0,0,0-.345.345v.689a.345.345,0,0,0,.345.345H9.512a3.49,3.49,0,0,0,.51,1.238l-.86.86a.345.345,0,0,0,0,.487h0l.523.522a.345.345,0,0,0,.488,0l.86-.86a3.491,3.491,0,0,0,1.238.51v1.207a.345.345,0,0,0,.345.345h.69a.345.345,0,0,0,.345-.345V16.407a3.491,3.491,0,0,0,1.238-.51l.86.86a.345.345,0,0,0,.487,0l.523-.522a.345.345,0,0,0,0-.487h0l-.86-.86a3.49,3.49,0,0,0,.51-1.238h1.208a.345.345,0,0,0,.345-.345v-.69a.345.345,0,0,0-.345-.345ZM12.96,14.757a1.8,1.8,0,1,1,1.8-1.8,1.8,1.8,0,0,1-1.8,1.8Z"
        transform="translate(48 254.004)"
      />
    </g>
  </svg>
);

const UsersIcon = (props) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    fill={props.fill}
    id={props.id}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs />
    <g transform="translate(-45 -251)">
      <rect
        className="a"
        width="24"
        height="24"
        transform="translate(45 251)"
      />
      <g transform="translate(49.001 255)">
        <rect
          className="b"
          width="16"
          height="16"
          transform="translate(-0.001)"
        />
        <path
          id={props.id}
          className="c"
          d="M13.036,9.212c-.64-.056-.651-.571-.651-1.213a5.845,5.845,0,0,0,1.373-3.483c0-2.109-1.2-3.516-2.928-3.516a2.8,2.8,0,0,0-1.191.255,4.751,4.751,0,0,1,2.329,4.4,6.933,6.933,0,0,1-1.076,3.533.383.383,0,0,0,.211.572A6,6,0,0,1,15.2,13.444h1.448a.387.387,0,0,0,.391-.383q0-.013,0-.027C16.776,10.24,13.912,9.288,13.036,9.212Z"
          transform="translate(-1.071 -0.111)"
        />
        <path
          className="c"
          d="M12.877,15.333a.414.414,0,0,0,.419-.409q0-.015,0-.031C13.019,11.9,9.951,10.882,9.012,10.8c-.686-.06-.7-.612-.7-1.3A6.264,6.264,0,0,0,9.787,5.767C9.787,3.507,8.5,2,6.648,2S3.511,3.507,3.511,5.768A6.264,6.264,0,0,0,4.983,9.5c0,.688-.011,1.24-.7,1.3C3.346,10.882.277,11.9,0,14.894a.414.414,0,0,0,.388.438H12.877Z"
          transform="translate(0 -0.222)"
        />
      </g>
    </g>
  </svg>
);

const InventoryIcon = (props) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    fill={props.fill}
    id={props.id}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs />
    <path
      id={props.id}
      className="a"
      d="M6.417,13.438A.439.439,0,0,0,5.979,13H3.792v1.75a.583.583,0,1,1-1.167,0V13H.437A.438.438,0,0,0,0,13.438v5.542a.438.438,0,0,0,.437.438H5.979a.439.439,0,0,0,.438-.437Z"
      transform="translate(0 -5.417)"
    />
    <path id={props.id} className="c" d="" transform="" />
    <path
      id={props.id}
      className="a"
      d="M19.417,13.438A.439.439,0,0,0,18.979,13H16.792v1.75a.583.583,0,0,1-1.167,0V13H13.438a.438.438,0,0,0-.437.438v5.542a.438.438,0,0,0,.438.438h5.542a.439.439,0,0,0,.438-.437Z"
      transform="translate(-5.417 -5.417)"
    />
    <path
      id={props.id}
      className="a"
      d="M12.917.437A.439.439,0,0,0,12.479,0H10.292V1.75a.583.583,0,0,1-1.167,0V0H6.938A.439.439,0,0,0,6.5.437V5.979a.438.438,0,0,0,.438.438h5.542a.439.439,0,0,0,.438-.437Z"
      transform="translate(-2.708)"
    />
  </svg>
);

const BatteryIcon = (props) => {
  return (
    <svg
      className="mx-auto"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path fill="none" opacity={0.4} d="M0 0h24v24H0z" />
      <path
        d="M22.266 10.759h-.643v-.643a.643.643 0 00-.643-.643h-.643V8.83a1.285 1.285 0 00-1.284-1.284H3.63a1.285 1.285 0 00-1.285 1.285v7.715a1.285 1.285 0 001.285 1.285h15.423a1.285 1.285 0 001.285-1.285v-.643h.643a.643.643 0 00.643-.643v-.643h.643a.643.643 0 00.643-.643v-2.57a.643.643 0 00-.644-.645zm-3.213 5.787H3.63V8.835h15.423z"
        fill="#2c2c2c"
      />
      <path fill="#ea8a72" d="M5 10h2v5H5z" />
      <path fill="#fad37b" d="M8 10h2v5H8z" />
    </svg>
  );
};

const SpeedometerIcon = (props) => {
  return (
    <svg
      className="mx-auto"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M19.762 7.671a.71.71 0 00-.058-.067.724.724 0 00-.067-.058 10.92 10.92 0 00-7.68-3.151 10.92 10.92 0 00-7.68 3.151.688.688 0 00-.067.058.7.7 0 00-.058.067A10.92 10.92 0 001 15.352a.694.694 0 00.694.694H22.22a.694.694 0 00.694-.694 10.92 10.92 0 00-3.152-7.681zm-5.011 6.987h-.468a2.429 2.429 0 00-.738-1.142l.2-.415a2.885 2.885 0 011.006 1.557zm-2.472-1.712a2.423 2.423 0 00-2.648 1.712h-.468a2.876 2.876 0 013.325-2.14zm3.421 1.712a3.809 3.809 0 00-1.531-2.4l1.659-3.363a.694.694 0 10-1.244-.614l-1.671 3.388a3.8 3.8 0 00-4.691 2.99h-5.81a9.528 9.528 0 012.309-5.561l.473.473a.694.694 0 00.981-.981L5.7 8.118a9.529 9.529 0 015.561-2.308v.79a.694.694 0 001.387 0v-.79a9.529 9.529 0 015.564 2.308l-.473.473a.694.694 0 00.981.981l.473-.473a9.528 9.528 0 012.307 5.559h-5.8z"
        fill="#4b4b4b"
      />
      <path fill="none" opacity={0.4} d="M0 0h24v24H0z" />
    </svg>
  );
};

const DistanceCoveredIcon = (props) => {
  return (
    <svg
      className="mx-auto"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path fill="none" opacity={0.4} d="M0 0h24v24H0z" />
      <path d="M17.831 16.037a1.774 1.774 0 00-1.671 1.187H8.823a1.78 1.78 0 100 1.186h7.337a1.777 1.777 0 101.671-2.373z" />
      <path
        d="M7.153 4.172A4.157 4.157 0 003 8.325c0 2.131 3.332 5.905 3.711 6.329a.593.593 0 00.883 0c.38-.424 3.711-4.2 3.711-6.329a4.157 4.157 0 00-4.152-4.153zm0 5.932a1.78 1.78 0 111.78-1.78 1.78 1.78 0 01-1.78 1.78z"
        fill="#ea8a72"
      />
      <path
        d="M17.67 4.172a4.157 4.157 0 00-4.153 4.153c0 2.131 3.332 5.905 3.711 6.329a.593.593 0 00.883 0c.38-.424 3.711-4.2 3.711-6.329a4.157 4.157 0 00-4.152-4.153zm0 5.932a1.78 1.78 0 111.78-1.78 1.78 1.78 0 01-1.78 1.78z"
        fill="#36b964"
      />
    </svg>
  );
};

const HaltIcon = (props) => {
  return (
    <svg
      className="mx-auto"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M14.9 5L19 9.1v5.8L14.9 19H9.1L5 14.9V9.1L9.1 5z"
        fill="#dc3d14"
      />
      <path fill="none" opacity={0.4} d="M0 0h24v24H0z" />
    </svg>
  );
};

const SpeedOfDeliveryIcon = (props) => {
  return (
    <svg
      className="mx-auto"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path fill="none" opacity={0.4} d="M0 0h24v24H0z" />
      <path
        d="M14.026 12.018a.364.364 0 11.364.364.364.364 0 01-.364-.364zm-6.2 0a6.56 6.56 0 116.56 6.56 6.56 6.56 0 01-6.555-6.56zm6.924 4.738a.364.364 0 00-.729 0v.364a.364.364 0 00.729 0zm4.738-5.1h-.364a.364.364 0 100 .729h.364a.364.364 0 000-.729zm-1.315-1.822a.365.365 0 00.5.133l.316-.182a.364.364 0 10-.364-.631l-.316.182a.365.365 0 00-.133.5zm.133 4.871l.316.182a.364.364 0 10.364-.631l-.316-.182a.364.364 0 00-.364.631zM16.578 8.23a.365.365 0 00.5-.133l.182-.315a.364.364 0 10-.631-.364l-.182.315a.364.364 0 00.131.497zm-.134 8.07l.182.315a.364.364 0 10.631-.364l-.182-.315a.364.364 0 10-.631.364zm-2.418-9.02a.364.364 0 10.729 0v-.364a.364.364 0 10-.729 0zm-.726 4.738a1.081 1.081 0 001.286 1.074l.949 1.658a.365.365 0 00.633-.362l-.95-1.66a1.082 1.082 0 00-.46-1.736V8.738a.364.364 0 00-.729 0v2.254a1.091 1.091 0 00-.729 1.026zm-1.773-4.236l.182.315a.364.364 0 10.631-.364l-.182-.315a.364.364 0 10-.631.364zm.133 8.97a.364.364 0 00.5-.133l.182-.315a.364.364 0 10-.631-.364l-.182.315a.364.364 0 00.133.5zm-1.867-6.97l.316.182a.364.364 0 00.364-.631l-.316-.182a.364.364 0 10-.364.631zm-.5 2.6h.364a.364.364 0 100-.729h-.364a.364.364 0 100 .729zm.866 2.5l.316-.182a.364.364 0 00-.364-.631l-.316.182a.364.364 0 10.364.631zM7.1 12.018a.364.364 0 00-.364-.364H3.822a.364.364 0 000 .729h2.916a.364.364 0 00.362-.365zM14.391 4a7.989 7.989 0 00-7.317 4.738h-4.71a.364.364 0 100 .729h4.949a.365.365 0 00.337-.226 7.289 7.289 0 110 5.553.365.365 0 00-.337-.226H5.28a.364.364 0 100 .729h1.793A8.018 8.018 0 1014.391 4z"
        fill="#4b4b4b"
      />
    </svg>
  );
};

const BaggingIcon = (props) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <defs>
        <style>{'.prefix__a{fill:none}.prefix__b{fill:#fff}'}</style>
      </defs>
      <path className="prefix__a" d="M4 4h16v16H4z" />
      <path className="prefix__a" d="M4 4h16v16H4z" />
      <path className="prefix__a" d="M0 0h24v24H0z" />
      <path
        className="prefix__b"
        d="M21.972 11.076l-2.216-3.067-.005-.005a.019.019 0 00-.01-.01l-.005-.005-.01-.008a.067.067 0 00-.015-.013.035.035 0 01-.01-.008.019.019 0 01-.013-.005.008.008 0 00-.005 0 .009.009 0 00-.008 0l-2.78-1-2.143-.768v-.232a.175.175 0 10-.35 0v.106l-.985-.371v.371l.985.373v.31a.175.175 0 00.35 0v-.186l2.024.728 2.336.836-7.108 2.7-7.108-2.7 2.334-.836 2.027-.728v.186a.175.175 0 00.35 0v-.317l.99-.373v-.37l-.99.37v-.106a.175.175 0 00-.174-.176.177.177 0 00-.176.176v.232l-2.143.768-2.78 1a.009.009 0 00-.008 0h-.005s-.01 0-.013.005a.035.035 0 00-.01.008.045.045 0 00-.018.013l-.008.008a.005.005 0 00-.005.005.02.02 0 00-.01.01l-.005.005-2.216 3.067a.184.184 0 00-.025.154.181.181 0 00.106.113l2.1.8v4.891a.174.174 0 00.113.164l7.609 2.9h.005a.059.059 0 00.02.005h.01a.1.1 0 00.028 0 .123.123 0 00.03 0 .009.009 0 00.008 0 .059.059 0 00.02-.005h.005l7.609-2.9a.174.174 0 00.113-.164v-4.884l2.1-.8a.176.176 0 00.081-.267zm-10.141 8.6l-2.7-1.027v-2.613a.032.032 0 010-.015v-.02c0-.007 0-.008-.005-.013l-.008-.02a.019.019 0 00-.005-.01c0-.008-.008-.013-.01-.02l-.008-.008-.013-.015a.025.025 0 01-.013-.01l-.015-.01-.015-.008-.015-.008a.064.064 0 01-.018-.005h-.015a.049.049 0 00-.02 0h-.01a.07.07 0 00-.023 0h-.013l-.02.008c-.005 0-.008 0-.013.005l-.018.008a.055.055 0 01-.013.008l-.005.005-.911.692-.959-1.45a.035.035 0 01-.008-.01.18.18 0 00-.138-.068.148.148 0 00-.033.005h-.01a.08.08 0 00-.023.008l-.018.008-.013.008a.068.068 0 00-.02.018.116.116 0 00-.018.018.044.044 0 00-.01.013c-.005.005-.005.01-.008.015a.052.052 0 00-.01.015v.015a.074.074 0 00-.008.025V17.71l-2.092-.8v-4.633l5.159 1.961a.173.173 0 00.06.013.177.177 0 00.144-.073l1.9-2.628zm7.609-2.764l-7.268 2.759v-8.122l1.9 2.628a.177.177 0 00.144.073.173.173 0 00.06-.013l5.159-1.961z"
      />
      <path
        className="prefix__b"
        d="M13.555 7.104l-1.318 1.318V2.237a.237.237 0 00-.474 0v6.185l-1.318-1.318a.237.237 0 00-.334.335l1.722 1.722a.234.234 0 00.036.029l.012.007a.2.2 0 00.029.015l.017.005a.207.207 0 00.028.009.241.241 0 00.047 0 .208.208 0 00.047 0l.028-.009.016-.005a.172.172 0 00.028-.015l.012-.007a.234.234 0 00.036-.029l1.722-1.722a.237.237 0 00-.334-.335z"
      />
    </svg>
  );
};

const DebaggingIcon = (props) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <defs>
        <style>{'.prefix__a{fill:none}.prefix__b{fill:#fff}'}</style>
      </defs>
      <path className="prefix__a" d="M4 4h16v16H4z" />
      <path className="prefix__a" d="M4 4h16v16H4z" />
      <path className="prefix__a" d="M0 0h24v24H0z" />
      <path
        className="prefix__b"
        d="M21.972 11.076l-2.216-3.067-.005-.005a.019.019 0 00-.01-.01l-.005-.005-.01-.008a.067.067 0 00-.015-.013.035.035 0 01-.01-.008.019.019 0 01-.013-.005.008.008 0 00-.005 0 .009.009 0 00-.008 0l-2.78-1-2.143-.768v-.232a.175.175 0 10-.35 0v.106l-.985-.371v.371l.985.373v.31a.175.175 0 00.35 0v-.186l2.024.728 2.336.836-7.108 2.7-7.108-2.7 2.334-.836 2.027-.728v.186a.175.175 0 00.35 0v-.317l.99-.373v-.37l-.99.37v-.106a.175.175 0 00-.174-.176.177.177 0 00-.176.176v.232l-2.143.768-2.78 1a.009.009 0 00-.008 0h-.005s-.01 0-.013.005a.035.035 0 00-.01.008.045.045 0 00-.018.013l-.008.008a.005.005 0 00-.005.005.02.02 0 00-.01.01l-.005.005-2.216 3.067a.184.184 0 00-.025.154.181.181 0 00.106.113l2.1.8v4.891a.174.174 0 00.113.164l7.609 2.9h.005a.059.059 0 00.02.005h.01a.1.1 0 00.028 0 .123.123 0 00.03 0 .009.009 0 00.008 0 .059.059 0 00.02-.005h.005l7.609-2.9a.174.174 0 00.113-.164v-4.884l2.1-.8a.176.176 0 00.081-.267zm-10.141 8.6l-2.7-1.027v-2.613a.032.032 0 010-.015v-.02c0-.007 0-.008-.005-.013l-.008-.02a.019.019 0 00-.005-.01c0-.008-.008-.013-.01-.02l-.008-.008-.013-.015a.025.025 0 01-.013-.01l-.015-.01-.015-.008-.015-.008a.064.064 0 01-.018-.005h-.015a.049.049 0 00-.02 0h-.01a.07.07 0 00-.023 0h-.013l-.02.008c-.005 0-.008 0-.013.005l-.018.008a.055.055 0 01-.013.008l-.005.005-.911.692-.959-1.45a.035.035 0 01-.008-.01.18.18 0 00-.138-.068.148.148 0 00-.033.005h-.01a.08.08 0 00-.023.008l-.018.008-.013.008a.068.068 0 00-.02.018.116.116 0 00-.018.018.044.044 0 00-.01.013c-.005.005-.005.01-.008.015a.052.052 0 00-.01.015v.015a.074.074 0 00-.008.025V17.71l-2.092-.8v-4.633l5.159 1.961a.173.173 0 00.06.013.177.177 0 00.144-.073l1.9-2.628zm7.609-2.764l-7.268 2.759v-8.122l1.9 2.628a.177.177 0 00.144.073.173.173 0 00.06-.013l5.159-1.961z"
      />
      <path
        className="prefix__b"
        d="M10.446 4.127l1.318-1.318v5.185a.237.237 0 00.474 0V2.809l1.318 1.318a.237.237 0 00.334-.335L12.168 2.07a.234.234 0 00-.036-.029l-.012-.007a.2.2 0 00-.029-.015l-.017-.005a.207.207 0 00-.028-.009.241.241 0 00-.047 0 .208.208 0 00-.047 0l-.028.009-.016.005a.172.172 0 00-.028.015l-.012.007a.234.234 0 00-.036.029L10.11 3.792a.237.237 0 00.334.335z"
      />
    </svg>
  );
};

const WaybillInventoryIcon = (props) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M6.21 2.64a2.868 2.868 0 00-2.85 2.85c0 1.5 2.58 4.71 2.67 4.86a.181.181 0 00.18.091.26.26 0 00.18-.09c.12-.12 2.67-3.33 2.67-4.86A2.868 2.868 0 006.21 2.64zm0 1.5a1.35 1.35 0 11-1.35 1.35 1.364 1.364 0 011.35-1.35zM15.36 18.6a3 3 0 11-4.23-2.73v2.85a1.23 1.23 0 102.46 0v-2.85a3.044 3.044 0 011.77 2.73z"
        fill="#fff"
        opacity={0.8}
      />
      <path
        d="M18.63 16.11a2.74 2.74 0 01-2.73 2.73h-.09v-.48h.09a2.28 2.28 0 002.28-2.28 2.304 2.304 0 00-2.31-2.28h-2.28v-.45h2.28a2.75 2.75 0 012.76 2.76zM11.13 13.35v.45h-2.4A2.74 2.74 0 016 11.07v-.24a.586.586 0 00.24.03.66.66 0 00.24-.03v.24a2.278 2.278 0 002.28 2.28h2.37zM20.37 11.521a.255.255 0 010 .21.247.247 0 01-.18.12h-5.7a.236.236 0 01-.24-.24v-1.86h2.91a.698.698 0 00.69-.69V6.3h2.31a.181.181 0 01.18.09.263.263 0 01.03.21l-1.14 2.61 1.14 2.31zM13.14 3.18v15.57a.78.78 0 11-1.56 0V3.18a.78.78 0 011.56 0z"
        fill="#fff"
        opacity={0.8}
      />
      <path
        d="M17.43 3.99v5.07a.236.236 0 01-.24.24h-3.6V3.78h3.6a.232.232 0 01.24.21z"
        fill="#fff"
        opacity={0.8}
      />
    </svg>
  );
};

function UserIcon(props) {
  return (
    <svg
      class="w-6 h-6"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
        clip-rule="evenodd"
      ></path>
    </svg>
  );
}

export {
  OperationsIcon,
  DashboardIcon,
  UploadIcon,
  UploadIconConfig,
  DRSIcon,
  PODIcon,
  ReportsIcon,
  ConfIcon,
  InventoryIcon,
  TrackingIcon,
  CustomerIcon,
  LocationIcon,
  PermissionIcon,
  ProductIcon,
  RoleIcon,
  UsersIcon,
  BatteryIcon,
  SpeedometerIcon,
  DistanceCoveredIcon,
  HaltIcon,
  SpeedOfDeliveryIcon,
  RefreshIcon,
  BaggingIcon,
  DebaggingIcon,
  WaybillInventoryIcon,
  UserIcon,
};
