export const SCAN_WAYBILL = 'SCAN_WAYBILL';
export const RESET_COUNT = 'RESET_COUNT';

export const scanWaybill = (waybillNumber) => ({
    type: SCAN_WAYBILL,
    payload: waybillNumber
});

export const resetCount = () => ({
    type: RESET_COUNT
});
