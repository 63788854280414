import * as types from './handoverActionTypes';

const initialState = {
	getHandoverDetails: {},
	tempHandover: {},
	tempDateRange: {},
};
export default function getHandoverDetails(state = initialState, actions) {
	switch (actions.type) {
		case types.GET_HANDOVER_INITIATED:
			return {
				...state,
				IsHandoverDetailsLoading: true,
				IsHandoverDetailsError: false,
				IsHandoverDetailsSuccess: false,
			};

		case types.GET_HANDOVER_SUCCESS:
			return {
				...state,
				IsHandoverDetailsLoading: false,
				IsHandoverDetailsError: false,
				IsHandoverDetailsSuccess: true,
				getHandoverDetails: actions.obj,
				tempHandover: actions.obj,
				tempDateRange: actions.res,
			};

		case types.GET_HANDOVER_FAILURE:
			return {
				...state,
				IsHandoverDetailsLoading: false,
				IsHandoverDetailsError: true,
				IsHandoverDetailsSuccess: false,
				getHandoverDetailsError: actions.BookingError,
			};
		case types.GET_HANDOVER_TEMP_CLEAR:
			return {
				...state,
				tempHandover: {},
				tempDateRange: {},
			};
		default:
			return state;
	}
}
