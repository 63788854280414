import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import Column from '../styled-components/Column';
import Row from '../styled-components/Row';
import Label from '../styled-components/Label';
import Logo from '../styled-components/Logo';
import LoginStyles from './login-style';
import {
  login,
  forgotPassword,
  getOtp,
} from '../../../services/authentication.service';

import {
  hideSnackBar,
  showSnackBar,
} from '../../../store/common/actions/common.actions';
import {
  setAuth,
  setUser,
  setLocationInUse,
  setUserRole,
  setUserEmail,
} from '../../../store/common/actions/login.actions';
import RoundedButton from '../../common/components/form-components/rounded-button';
import BootstrapInput from '../../common/components/form-components/bootstrap-input';
import NumberInput from '../../common/components/form-components/number-input';
import BootstrapSelect from '../../common/components/form-components/bootstrap-select';
// import { LocationOn } from '../../../assets-stage.addup.network/images';
import scss from './login.module.scss';
import userService from '../../../services/user.service';
import OtpInput from 'react-otp-input';
import OtpTimer from 'otp-timer';
import HardRefresh from '../../common/components/hard-refresh';
import SignUp from '../signup';
import BackButton from '../../common/components/back-button/BackButton';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import 'twin.macro';

const FLAG_SELECTOR_OPTION_LIST = [
  {
    id: '91',
    name: 'IND',
    displayText: 'IND(91)',
    locale: '',
    flag: require('./flagPicker/flagImage/in.svg'),
  },
  {
    id: '60',
    name: 'MY',
    displayText: 'MY(60)',
    locale: '',
    flag: require('./flagPicker/flagImage/my.svg'),
  },
];

let logo1;
let loginImage;

const Login = (props) => {
  const {
    action__hideSnackBar,
    action__showSnackBar,
    action__setAuth,
    action__setUser,
    action__setLocationInUse,
    action__setUserRole,
    action__setUserEmail,
    common: { snackbar = { show: false } },
  } = props;

  const intitialState = { username: '', password: '', otp: '' };
  const [values, setValues] = useState(intitialState);
  const [forgotpsw, setForgotpsw] = useState(false);
  const [isMobilePage, setMobilePage] = useState(true);
  const [iOtp, setiOtp] = useState('');
  const [cCode, setcCode] = useState('91');
  const [mobNumber, setmobNumber] = useState();
  const [errorMobile, setErrorMobile] = useState(false);
  const [isOtpSubmit, setIsOtpSubmit] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [notReg, setNotReg] = useState('');
  const [invalidOtp, setInvalidOtp] = useState('');
  const [otpError, setOtpError] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [showRest, setShowRest] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);

  const validateMobileNo = (value) => {
    const stat = new RegExp(/^[0-9]{1,12}$/i).test(value);
    if (!stat) {
      setErrorMobile(true);
      setNotReg('Invalid Number !');
    } else {
      setErrorMobile(false);
    }
  };
  
  const handleLoginOtpSubmit = (e) => {
    const subValues = {
      username: '',
      password: '',
      countryCode: cCode,
      mobileNo: mobNumber,
      otp: iOtp,
    };

    try {
      login(subValues)
        .then((loginResponse) => {
          const { access_token, error_description } = loginResponse;
          if (!access_token) {
            if (error_description === 'Bad credentials') {
              setOtpError(true);
              setInvalidOtp('Incorrect OTP Pin');
            } else {
              action__showSnackBar({
                variant: 'error',
                message: error_description,
              });
              setTimeout(() => {
                action__hideSnackBar();
              }, 5000);
            }
          } else {
            action__setAuth(loginResponse);
            userService
              .getLoggedInUser(values.username)
              .then( ({ status = {}, response = {} }) => {
                if (!status.success) {
                  action__showSnackBar({
                    variant: 'error',
                    message: status.message,
                  });
                  setTimeout(() => {
                    action__hideSnackBar();
                  }, 5000);
                } else {
                  action__setUser(response);
                  action__setLocationInUse(response.locationId);
                  action__setUserRole(response.roleName);
                }
              });
          }
        })
        .catch((error) => {
          if (error) {
            action__showSnackBar({
              variant: 'error',
              message: error.message,
            });
            setTimeout(() => {
              action__hideSnackBar();
            }, 5000);
          }
        });
    } catch (error) {
      if (error) {
        action__showSnackBar({ variant: 'error', message: error.message });
        setTimeout(() => {
          action__hideSnackBar();
        }, 5000);
      }
    }
  };

  const handleGetOtp = () => {
    const otpValue = { countryCode: cCode, mobileNo: mobNumber };

    try {
      getOtp(otpValue).then((response) => {
        const { status } = response;
        if (!status.success) {
          setIsOtpSubmit(false);
          if (status.message === 'Invalid Mobile Number') {
            setErrorMobile(true);
            setNotReg(
              'Mobile number not registered please contact your admin.',
            );
          } else {
            const currentValue = {
              variant: 'warning',
              message: status.message,
            };
            const modifiedSnackbar = { ...snackbar, ...currentValue };
            action__showSnackBar(modifiedSnackbar);
            setTimeout(() => {
              action__hideSnackBar();
            }, 5000);
          }
        } else {
          setIsOtpSubmit(true);
          setShowRest(true);
          setIsResend(true);
          setIsOtpSent(true);
          setTimeout(() => {
            setIsOtpSent(false);
          }, 30000);
        }
      });
    } catch (e) {}
  };
  const handleLoginSubmit = async (evt) => {
    evt.preventDefault();
    try {
      if (forgotpsw) {
        forgotPassword(values.username).then(
          (response) => {
            const { status = {} } = response;
            let isSuccess = false;
            let message = 'Error occured';
            if (status) {
              isSuccess = status.success;
              message = status.message;
            }
            const currentValue = {
              variant: isSuccess ? 'success' : 'error',
              message,
            };
            const modifiedSnackbar = { ...snackbar, ...currentValue };
            action__showSnackBar(modifiedSnackbar);
            setTimeout(() => {
              action__hideSnackBar();
            }, 5000);
            setForgotpsw(false);
          },
          (error) => {
            const { status = {} } = error;
            let isSuccess = false;
            let message = 'Error occured';
            if (status) {
              isSuccess = status.success;
              message = status.message ? status.message : message;
            }
            const currentValue = {
              variant: isSuccess ? 'success' : 'error',
              message,
            };
            const modifiedSnackbar = { ...snackbar, ...currentValue };
            action__showSnackBar(modifiedSnackbar);
            setTimeout(() => {
              action__hideSnackBar();
            }, 5000);
            setForgotpsw(false);
          },
        );
      } else {
        login(values)
          .then((loginResponse) => {
            const { access_token, error_description } = loginResponse;
            if (!access_token) {
              action__showSnackBar({
                variant: 'error',
                message: error_description,
              });
              setTimeout(() => {
                action__hideSnackBar();
              }, 5000);
            } else {
              action__setAuth(loginResponse);
              userService
                .getLoggedInUser(values.username)
                .then( ({ status = {}, response = {} }) => {
                  if (!status.success) {
                    action__showSnackBar({
                      variant: 'error',
                      message: status.message,
                    });
                    setTimeout(() => {
                      action__hideSnackBar();
                    }, 5000);
                  } else {
      
                    action__setUser(response);
                    action__setLocationInUse(response.locationId);
                    action__setUserRole(response.roleName);
                    action__setUserEmail(response.email);
                    if (caches) {
                      // Service worker cache should be cleared with caches.delete()
                      caches.keys().then(function (names) {
                        for (let name of names) caches.delete(name);
                      });
                    }
                    window.location.reload(true);
                  }
                });
            }
          })
          .catch((error) => {
            if (error) {
              action__showSnackBar({
                variant: 'error',
                message: error.message,
              });
              setTimeout(() => {
                action__hideSnackBar();
              }, 5000);
            }
          });
      }
    } catch (error) {
      if (error) {
        action__showSnackBar({ variant: 'error', message: error.message });
        setTimeout(() => {
          action__hideSnackBar();
        }, 5000);
      }
    }
  };

  const handleMobNo = () => {
    if (!mobNumber) {
      setErrorMobile(true);
      setNotReg('Required !');
    } else {
      setErrorMobile(false);
      validateMobileNo(mobNumber);
    }
  };
  // const handleChangeOtp = (otp) => setValues({ otp });

  const handleChange = (e) => {
    const { name = '', value = '' } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleForgotPassword = () => {
    setForgotpsw(true);
  };

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (!mobNumber) {
        setErrorMobile(true);
        setNotReg('Required !');
      } else {
        handleGetOtp();
      }
    }
  };

  const urlSplit = window.location.href.split('/');
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    loginImage = require('../../../assets-stage.addup.network/images/bg.svg');
    logo1 = require('../../../assets-stage.addup.network/images/kyte_logo.svg');
  } else if (urlSplit === 'meghrajexpress-stage.kyte.app') {
    loginImage = require(`../../../assets-${urlSplit[2]}/images/bg.svg`);
    logo1 = require(`../../../assets-${urlSplit[2]}/images/kyte_logo.svg`);
  } else {
    loginImage = require(`../../../assets-${urlSplit[2]}/images/bg.svg`);
    logo1 = require(`../../../assets-${urlSplit[2]}/images/kyte_logo.svg`);
  }

  return (
    <div style={LoginStyles.wrapper}>
      <Row
        className="flex items-start"
        // style={LoginStyles.rowFirst}
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          background: `url(${loginImage}) no-repeat fixed`,
          backgroundSize: 'contain',
        }}
      >
        {/* <HardRefresh /> */}
        <div style={LoginStyles.rightPaneWrapper}>
          {showRest ? <BackButton /> : null}

          <Column style={LoginStyles.rightPaneColumn}>
            <Row
              style={{
                marginBottom: 16,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span
                style={{ fontSize: 24, fontWeight: 'bold', lineHeight: '24px' }}
              >
                Welcome to
              </span>
            </Row>

            <div
              style={{
                display: 'flex',
                marginBottom: '20px',
                alignItems: 'flex-start',
              }}
            >
              <div style={{ marginRight: 0, marginBottom: '20px' }}>
                <div
                  style={{
                    backgroundImage: `url(${logo1})`,
                    width: '195px',
                    height: '79px',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
              </div>
              <div
                style={{
                  color: '#6E6E6E',
                  fontSize: 12,
                  width: 270,
                  marginTop: 45,
                  fontWeight: 'bold',
                  marginLeft: 5,
                }}
              >
                Enabling Logistics
              </div>
            </div>

            {isMobilePage ? (
              <div>
                <form /*onSubmit={handleLoginOtpSubmit}*/>
                  <InputLabel
                    style={{
                      fontSize: '12px',
                      marginBottom: '-12px',
                      marginLeft: '5px',
                      color: '#6E6E6E',
                    }}
                  >
                    Code
                  </InputLabel>
                  <InputLabel
                    style={{
                      fontSize: '12px',
                      marginBottom: '10px',
                      marginLeft: '113px',
                      color: '#6E6E6E',
                    }}
                  >
                    Mobile Number
                  </InputLabel>
                  <FormControl fullWidth>
                    <div className={scss.mobileInput}>
                      {!showRest ? (
                        <BootstrapSelect
                          style={{
                            marginRight: '10px',
                            width: '100px',
                            fontSize: '10px',
                          }}
                          type="text"
                          name="country code"
                          id="countryCode"
                          value={cCode}
                          onChange={(e) => setcCode(e.target.value)}
                        >
                          {/* <MenuItem value='91'><img src={IndianFlag}></img>+91</MenuItem>
													<MenuItem value='60'>60</MenuItem> */}

                          {FLAG_SELECTOR_OPTION_LIST.map((item, index) => (
                            <MenuItem value={item.id}>
                              <div tw="grid items-center grid-cols-2 gap-2">
                                <img
                                  src={item.flag}
                                  style={{
                                    height: '15px',
                                    width: '30px',
                                    marginRight: '4px',
                                  }}
                                ></img>{' '}
                                {`+${item.id}`}
                              </div>
                            </MenuItem>
                          ))}
                        </BootstrapSelect>
                      ) : (
                        <NumberInput
                          value={`+${cCode}`}
                          style={{
                            marginRight: '10px',
                            width: '80px',
                            height: '34px',

                            fontSize: '15px',
                          }}
                          variant="outlined"
                          inputformat="BootstrapInput"
                          disabled={true}
                        />
                      )}

                      <NumberInput
                        className={scss.inputMobile}
                        // type='text'
                        placeholder="Enter mobile number"
                        variant="outlined"
                        // inputProps={{ pattern: '[0-9]' }}
                        onKeyDown={_handleKeyDown}
                        required
                        value={mobNumber}
                        name="mobileNumber"
                        onChange={(e) => setmobNumber(e.target.value)}
                        onBlur={() => handleMobNo()}
                        disabled={showRest}
                        maxallowedchars="12"
                        inputformat="BootstrapInput"
                      />
                    </div>
                    {errorMobile ? (
                      <FormHelperText
                        error
                        style={{ margin: '10px', marginLeft: '100px' }}
                      >
                        {notReg}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                  {showRest ? null : (
                    <RoundedButton
                      disabled={!mobNumber || isOtpSent}
                      color="primary"
                      variant="contained"
                      style={{ marginTop: 30, padding: '6px 32px' }}
                      onClick={() => handleGetOtp()}
                    >
                      Send OTP
                    </RoundedButton>
                  )}
                </form>
                {showRest ? (
                  <div>
                    <form>
                      <div className={scss.container}>
                        <InputLabel
                          style={{
                            fontSize: '13px',
                            marginBottom: '10px',
                            color: '#6E6E6E',
                          }}
                        >
                          Enter the OTP that we just sent on above mobile
                          number.
                        </InputLabel>
                        <div className={scss.formOtpContainer}>
                          <OtpInput
                            onChange={(i) => setiOtp(i)}
                            containerStyle={scss.otpContainer}
                            inputStyle={scss.otpIn}
                            errorStyle={scss.otpError}
                            hasErrored={otpError}
                            focusStyle={scss.otpFocus}
                            numInputs={6}
                            value={iOtp}
                            separator={<p> </p>}
                          />
                          {/* <ScanInput
														name='otp'
														className={scss.otpInputCustom}
														value={iOtp}
														onChange={(e) => setiOtp(e.target.value)}
														onBlur={(e) => validateOtp(e.target.value)}
														maxallowedchars='6'
														// inputformat='BootstrapInput'
													/> */}
                          {otpError ? (
                            <FormHelperText error>{invalidOtp}</FormHelperText>
                          ) : null}
                        </div>
                        <div style={{ margin: '10px 0' }}>
                          {isResend ? (
                            <OtpTimer
                              ButtonText="Resend OTP"
                              textColor="#6E6E6E"
                              buttonColor="#00A29D"
                              background="#fafafa"
                              seconds={60}
                              minutes={0}
                              resend={() => handleGetOtp()}
                            />
                          ) : null}
                        </div>
                      </div>
                      <RoundedButton
                        // type='submit'
                        disabled={!isOtpSubmit}
                        color="primary"
                        variant="contained"
                        style={{ marginTop: 20, padding: '6px 12px' }}
                        onClick={() => handleLoginOtpSubmit()}
                      >
                        VERIFY OTP
                      </RoundedButton>
                    </form>
                  </div>
                ) : null}

                <div tw="flex items-center justify-between mt-6">
                  <div
                    className={scss.link}
                    onClick={() => {
                      setMobilePage(false);
                      setShowSignUp(false);
                      setShowEmail(true);
                    }}
                  >
                    <span className={scss.text}>Login with Username</span>
                  </div>
                  <div
                    className={scss.link}
                    onClick={() => {
                      setShowSignUp(true);
                      setMobilePage(false);
                    }}
                  >
                    <span className={scss.text}>SignUp</span>
                  </div>
                </div>
              </div>
            ) : null}

            {showEmail ? (
              <div>
                <form tw="space-y-4" onSubmit={handleLoginSubmit}>
                  <div tw="space-y-2">
                    <div>
                      <b> Email</b>
                    </div>

                    <BootstrapInput
                      placeholder="Enter email"
                      variant="outlined"
                      fullWidth
                      required
                      value={values.username}
                      name="username"
                      onChange={handleChange}
                      //onBlur={forgotpsw ? validateEmail : null}
                    />
                  </div>
                  {forgotpsw ? null : (
                    <div tw="space-y-2">
                      <div>
                        <b>Password</b>
                      </div>

                      <BootstrapInput
                        placeholder="Enter password"
                        name="password"
                        id="password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        required
                        value={values.password}
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  {forgotpsw ? null : (
                    <div style={{ marginTop: 8 }}>
                      <Row>
                        <Label
                          style={{
                            margin: 'auto',
                            marginRight: '0px',
                            letterSpacing: '0.66px',
                            color: '#8E8E8E',
                            fontSize: 11,
                            fontWeight: 'bold',
                            cursor: 'pointer',
                          }}
                          onClick={handleForgotPassword}
                        >
                          Forgot password?
                        </Label>
                      </Row>
                    </div>
                  )}

                  {forgotpsw ? (
                    [
                      <RoundedButton
                        type="submit"
                        style={{ marginTop: 30 }}
                        color="primary"
                        variant="contained"
                        //disabled={!isUsernameValid}
                      >
                        Reset Password
                      </RoundedButton>,
                      <RoundedButton
                        style={{
                          marginTop: 30,
                          marginLeft: 16,
                          padding: '6px 32px',
                        }}
                        color="primary"
                        variant="text"
                        onClick={() => setForgotpsw(false)}
                      >
                        Cancel
                      </RoundedButton>,
                    ]
                  ) : (
                    <RoundedButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{ marginTop: 30, padding: '6px 32px' }}
                    >
                      Login
                    </RoundedButton>
                  )}
                </form>

                <div tw="flex items-center justify-between mt-6">
                  <div
                    className={scss.link}
                    onClick={() => {
                      setMobilePage(true);
                      setShowEmail(false);
                      setShowSignUp(false);
                    }}
                  >
                    <span className={scss.text}>Login with OTP</span>
                  </div>
                  <div
                    className={scss.link}
                    onClick={() => {
                      setShowSignUp(true);
                      setShowEmail(false);
                    }}
                  >
                    <span className={scss.text}>SignUp</span>
                  </div>
                </div>
              </div>
            ) : null}
            {showSignUp ? (
              <>
                <SignUp
                  setMobilePage={() => {
                    setMobilePage(true);
                    setShowEmail(false);
                    setShowSignUp(false);
                  }}
                />
                <div tw="flex items-center justify-between mt-6">
                  <div
                    className={scss.link}
                    onClick={() => {
                      setMobilePage(false);
                      setShowSignUp(false);
                      setShowEmail(true);
                    }}
                  >
                    <span className={scss.text}>Login with Username</span>
                  </div>
                  <div
                    className={scss.link}
                    onClick={() => {
                      setMobilePage(true);
                      setShowEmail(false);
                      setShowSignUp(false);
                    }}
                  >
                    <span className={scss.text}>Login with OTP</span>
                  </div>
                </div>
              </>
            ) : null}
          </Column>
        </div>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  common: { ...state.common },
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      action__hideSnackBar: hideSnackBar,
      action__showSnackBar: showSnackBar,
      action__setAuth: setAuth,
      action__setUser: setUser,
      action__setLocationInUse: setLocationInUse,
      action__setUserRole: setUserRole,
      action__setUserEmail: setUserEmail,
    },
    dispatch,
  );

Login.propTypes = {
  common: PropTypes.object,
  action__hideSnackBar: PropTypes.func,
  action__showSnackBar: PropTypes.func,
  action__setAuth: PropTypes.func,
  action__setUser: PropTypes.func,
  action__setLocationInUse: PropTypes.func,
  action__setUserRole: PropTypes.func,
  action__setUserEmail: PropTypes.func,
};

Login.defaultProps = {
  common: {},
  action__hideSnackBar: Function,
  action__showSnackBar: Function,
  action__setAuth: Function,
  action__setUser: Function,
  action__setLocationInUse: Function,
  action__setUserRole: Function,
  action__setUserEmail: Function,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
