import { SCAN_WAYBILL, RESET_COUNT } from '../actions/do-handover.actions';

export default function DoHandoverReducer(state = { lastScannedWaybill: '', totalWaybillsScanned: 0 }, action) {
    switch (action.type) {
        case SCAN_WAYBILL:
            return {
                ...state,
                lastScannedWaybill: action.payload,
                totalWaybillsScanned: state.totalWaybillsScanned + 1
            };

        case RESET_COUNT:
            return {
                ...state,
                lastScannedWaybill: '',
                totalWaybillsScanned: 0
            };

        default:
            return state;
    }
}
