import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import CustomizedSnackbars from './common/utils/Snackbar';
import CustomizedModals from './common/utils/Popup';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import '../services/network-interceptor.service';
import Routes from './routes/routes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/">
        <Switch>
          <Route component={Routes} />
        </Switch>
      </BrowserRouter>
      <CustomizedSnackbars />
      <CustomizedModals />
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};
