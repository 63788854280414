import * as types from './inventory-actionTypes';

const initialState = {
  data: {},
  scanDetailsMsg: '',
  getInventoryScanDetails: [],
  getInventoryScanError: {},
};
export default function getInventoryScan(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_INVENTORY_SCAN_INITIATED:
      return {
        ...state,
        IsInventoryScanLoading: true,
        IsInventoryScanError: false,
        IsInventoryScanSuccess: false,
      };
    case types.GET_INVENTORY_SCAN_SUCCESS:
      return {
        ...state,
        IsInventoryScanLoading: false,
        IsInventoryScanError: false,
        IsInventoryScanSuccess: true,
        data: actions.data,
        scanDetailsMsg: actions.data.response.failureResponse[0].errorMessage,
        getInventoryScanDetails: state.getInventoryScanDetails,
      };

    case types.GET_INVENTORY_SCAN_WITHDATA_SUCCESS:
      return {
        ...state,
        IsInventoryScanLoading: false,
        IsInventoryScanError: false,
        IsInventoryScanSuccess: true,
        data: actions.data,
        // getInventoryScanDetails: actions.InventoryScan,
        scanDetailsMsg: '',
        getInventoryScanDetails: state.getInventoryScanDetails.concat(
          actions.data.response.inventoryDetails[0],
        ),
      };

    case types.GET_INVENTORY_SCAN_FAILURE:
      return {
        ...state,
        IsInventoryScanLoading: false,
        IsInventoryScanError: true,
        IsInventoryScanSuccess: false,
        data: actions.data,
        getInventoryScanDetails: state.getInventoryScanDetails,
        getInventoryScanError: actions.InventoryScanError,
      };
    default:
      return state;
  }
}
