import * as types from './inventory-actionTypes';
import axios from 'axios';
import { PARTNER_URL } from '../../../../../environments';
export function GetInventory(data, token) {
  const { isFilter, filter, dateRange, defRange1, ...rest } = data;
  const filterTerm = isFilter ? { ...filter } : '';
  const dateFilter = {
    dateRange: dateRange,
    defRange1,
    selectedLocId: data.filter.destinationLocationIds,
  };
  const temp = { ...rest, ...filterTerm };
  let body = JSON.stringify(temp);

  return function (dispatch) {
    dispatch(getInventoryInitiation());
    axios
      .post(`${PARTNER_URL}/inventory/get_list`, body, {
        Authorization: `Bearer ${token}`,
      })

      .then((res) => {
        if (
          res.data.status.success === true &&
          res.data.status.message === 'SUCCESS'
        ) {
          dispatch(getInventorySuccess(res.data, dateFilter));
        }
      })
      .catch((err) => {
        dispatch(getInventoryError(err));
      });
  };
}
export function getInventoryInitiation() {
  return {
    type: types.GET_INVENTORY_INITIATED,
  };
}
export function getTempClearInventory() {
  return {
    type: types.GET_INVENTORY_TEMP_CLEAR,
  };
}
export function getInventorySuccess(InventoryDetails, dateFilter) {
  return {
    type: types.GET_INVENTORY_SUCCESS,
    InventoryDetails,
    dateFilter,
  };
}

export function getInventoryError(InventoryError) {
  return {
    type: types.GET_INVENTORY_FAILURE,
    InventoryError,
  };
}
