import { getAllLocations } from '../../../services/locations.service';

export const getLocations = () => ({
  type: 'GET_LOCATIONS',
  promise: getAllLocations(0),
});

export const setLocationInUse = (locationId) => ({
  type: 'SET_LOCATION',
  payload: locationId,
});

export const setPermissions = (data) => ({
  type: 'SET_PERMISSIONS',
  payload: data,
});

export const clearAuth = () => ({
  type: 'CLEAR_AUTH',
});

export const setAuth = (data) => ({
  type: 'SET_AUTH',
  payload: data,
});

export const setUser = (data) => ({
  type: 'SET_USER',
  payload: data,
});

export const setUserRole = (data) => ({
  type: 'USER_ROLE',
  payload: data,
});

export const setUserEmail = (data) => ({
  type: 'USER_EMAIL',
  payload: data,
});
