import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Avatar } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import styles from './header.module.scss';
import BootstrapInput from '../form-components/bootstrap-input';
import Flyout from '../../utils/Flyout';
import {
  clearAuth,
  setLocationInUse,
} from '../../../../store/common/actions/login.actions';
import { getBookingLisrTempClear } from '../../../routes/operations/bookings/bookingAction';
import {
  getPRQTempClear,
  getPRSTempClear,
} from '../../../routes/operations/pickup/pickupAction';
import {
  getUnreadNotification,
  markNotificationAsRead,
} from '../../../../services/notification.service';
import { getHandoverTempClear } from '../../../routes/operations/handover-new/handoverAction';
import { getTempClearInventory } from '../../../routes/operations/inventory/inventory-list/inventory-actions';
import { InputBase, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { convertToLocalTimeStamp } from '../../utils/DateFormatter';
import { sortBy } from 'lodash';
import userClient from '../../../../utils/api/network/user/user-client';
import { useQuery } from 'react-query';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import 'twin.macro';

const StyledBadge = withStyles((theme) => ({
  badge: {
    color: '#fff',
  },
}))(Badge);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    'margin-top': '0px',
    width: '400px',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 5,
    },

    '&::-webkit-scrollbar-track': {
      background: '#ddd',
    },

    /* scrollbar handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#8e8e8e',
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid #ddd',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:focus': {
      // backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const Header = ({
  action__setLocationInUse,
  login,
  action__clearAuth,
  booking,
  PRQ,
  PRS,
  manifest,
  inventory,
  props,
  history,
}) => {
  const [open, setOpen] = useState(false);
  const [waybillNo, setWaybillNo] = useState();
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const result = useQuery({
    queryKey: ['loggedInUserDetails'],
    queryFn: userClient.getLoggedInUserDetails,
  });

  // useEffect(() => {
  //   loadData();
  //   setInterval(loadData, 5 * 60 * 1000);
  // }, []);

  // const loadData = async () => {
  //   try {
  //     const result = await getUnreadNotification(locationInUse);
  //     if (result.status.success) {
  //       setNotifications(result.response);
  //     }
  //   } catch (e) {
  //     ('error while getting notification', e);
  //   }
  // };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFlyout = () => {
    setOpen((open) => !open);
  };

  // const redirectHandler = (url) => {
  // 	history.push(url)
  // }

  const MakeUnreadHandler = async (id, url) => {
    const data = {
      id: id,
      isRead: 'Y',
    };
    await markNotificationAsRead(data);
    // loadData();
    setAnchorEl(null);
    history.push(url);
  };

  const handleChange = (event) => {
    action__setLocationInUse(event.target.value);
  };

  const handleSearchText = (e) => {
    setWaybillNo(e.target.value.split(/[ ,]+/));
  };

  const gotoTrackingPage = () => {
    if (
      waybillNo &&
      waybillNo.map(function (a) {
        return a.trim();
      }) !== ''
    ) {
      history.push(`/tracking?waybillNoList=${waybillNo}`);
      setWaybillNo('');
    }
  };

  const handleLogout = () => {
    document.title = 'Kyte';
    booking();
    PRQ();
    PRS();
    manifest();
    inventory();
    action__clearAuth();
  };

  if (!result.isSuccess) {
    return null;
  } else {
    const user = result.data.response;
    const { locationInUse } = login;
    const { locationDetails = [], locationId } = user;

    return (
      <div className={styles.container}>
        <div className={styles.contents}>
          <div>{''}</div>
          <div className={styles.trackSearch}>
            {/* <ScanInput */}
            <InputBase
              className={`${styles.input}`}
              placeholder="Enter waybill number to track"
              // inputProps={{ arial: 'Enter WaybillNo to Track' }}
              onChange={(e) => {
                handleSearchText(e);
              }}
              value={waybillNo}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  gotoTrackingPage();
                }
              }}
              exceptchar=","
            />
            <div>
              <IconButton
                type="button"
                className={`${styles.iconButton}`}
                aria-label="search"
                onClick={() => {
                  gotoTrackingPage();
                }}
              >
                <SearchIcon className={`${styles.searchIcon}`} />
              </IconButton>
            </div>
          </div>
          <div className={styles.actions}>
            <Select
              className={styles.select}
              labelId="location-selector"
              id="location-selector"
              value={locationInUse || locationId}
              onChange={handleChange}
              input={<BootstrapInput />}
            >
              {(user.roleName.toLowerCase() === 'admin' ||
                user.roleName.toLowerCase() === 'hub_manager') && (
                <MenuItem key="-1" value={-1}>
                  All
                </MenuItem>
              )}
              {locationDetails && locationDetails.length > 0
                ? sortBy(locationDetails, (location) => location.name).map(
                    (location) => (
                      <MenuItem key={location.id} value={location.id}>
                        {location.name}
                      </MenuItem>
                    ),
                  )
                : null}
            </Select>
            <div className={styles.notification}>
              <div
                aria-controls="customized-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <StyledBadge
                  badgeContent={
                    notifications.length > 0 ? notifications.length : null
                  }
                  max={99}
                  color="secondary"
                >
                  <NotificationsIcon />
                </StyledBadge>
              </div>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {notifications.length > 0 ? (
                  notifications.map((item, i) => {
                    return (
                      <StyledMenuItem key={i}>
                        <div
                          className={styles.RedirectBtn}
                          onClick={() =>
                            MakeUnreadHandler(item.id, item.redirectTo)
                          }
                        >
                          <div className={styles.notificationHead}>
                            <div className={styles.IconDiv}>
                              <div>
                                {item.notificationType === 'ERROR' ? (
                                  <ErrorIcon style={{ color: '#ED4C67' }} />
                                ) : item.notificationType === 'INFO' ? (
                                  <InfoIcon style={{ color: '#12CBC4' }} />
                                ) : (
                                  <WarningIcon style={{ color: '#F79F1F' }} />
                                )}
                              </div>
                              <div>
                                <p>{item.notificationType}!</p>
                              </div>
                            </div>
                            <div>
                              <h4>{item.notificationTitle}</h4>
                            </div>
                          </div>
                          <p>{item.notificationMsg}</p>
                          <p className={styles.TimeStyle}>
                            {convertToLocalTimeStamp(item.updatedOn)}
                          </p>
                        </div>
                      </StyledMenuItem>
                    );
                  })
                ) : (
                  <StyledMenuItem>
                    <div className={styles.RedirectBtn}>
                      <p>No message available</p>
                    </div>
                  </StyledMenuItem>
                )}
              </StyledMenu>
            </div>
            {user?.profileUrl ? (
              <img
                id="user-symbol"
                tw="bg-white rounded-full w-9 h-9 object-center object-cover cursor-pointer"
                src={user?.profileUrl}
                alt=""
                onClick={handleFlyout}
              />
            ) : (
              <div
                id="user-symbol"
                tw="bg-custom-003a8a rounded-full w-9 h-9 flex items-center justify-center cursor-pointer"
                onClick={handleFlyout}
              >
                <span tw="text-white font-semibold">
                  {user?.firstName?.charAt(0).toUpperCase()}
                  {user?.lastName?.charAt(0).toUpperCase()}
                </span>
              </div>
            )}
          </div>
          {open ? (
            <Flyout
              setLocationInUse={action__setLocationInUse}
              handleFlyout={handleFlyout}
              cstyle={{
                height: 152,
              }}
              uname={user ? user.firstName : ''}
              onLogout={() => handleLogout()}
            />
          ) : (
            <Flyout
              setLocationInUse={action__setLocationInUse}
              handleFlyout={handleFlyout}
              cstyle={{ border: 'transparent' }}
              uname={user ? user.userName : ''}
              onLogout={() => handleLogout()}
            />
          )}
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  login: { ...state.login },
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      action__clearAuth: clearAuth,
      action__setLocationInUse: setLocationInUse,
      booking: getBookingLisrTempClear,
      PRS: getPRSTempClear,
      PRQ: getPRQTempClear,
      manifest: getHandoverTempClear,
      inventory: getTempClearInventory,
    },
    dispatch,
  );

Header.propTypes = {
  action__clearAuth: PropTypes.func,
  action__setLocationInUse: PropTypes.func,
};

Header.defaultProps = {
  action__clearAuth: Function,
  action__setLocationInUse: Function,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
