export function loader(action) {
  return {
    type: 'LOADER_VIEW',
    payload: action,
  };
}

export function errorHandler(error) {
  return {
    type: 'ERROR_HANDLER',
    payload: error,
  };
}

export const showSnackBar = (data) => ({
  type: 'SHOW_SNACKBAR',
  payload: data,
});

export const hideSnackBar = () => ({
  type: 'HIDE_SNACKBAR',
});

export const showModal = (data) => ({
  type: 'SHOW_MODAL',
  payload: data,
});

export const hideModal = () => ({
  type: 'HIDE_MODAL',
});

export const setDateRange = (data) => ({
  type: 'SET_DATE_RANGE',
  payload: data,
});
