/** @jsx jsx */
import { jsx } from '@emotion/react';
import tw, { styled } from 'twin.macro';

const TextFiled = styled.input(({ disabled, error }) => [
  tw`border-0 block w-full px-3 py-2 rounded-md focus:outline-none ring-1 ring-gray-300`,
  disabled ? tw`bg-gray-200` : tw`focus:ring-2 focus:ring-blue-700 `,
  error ? tw`text-red-900 ring-red-300 focus:ring-2 focus:ring-red-500` : tw``,
]);

export default TextFiled;
