import React, { useState, useEffect } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import { connect } from 'react-redux';

import mapStyles from './mapStyles.json';
import MapDirections from './MapDirections';
import DeliveryBoyInfoWindow from './DeliveryBodyInfoWindow';
import WayPointsInfoWindow from './WayPointsInfoWindow';
import SimpleInfoWindow from './SimpleInfoWindow';

const Map = ({
  fePod,
  isMarkerShown,
  isDirectionShown,
  data,
  selectedDeliveryBoyIndex,
  destinationCoordinates,
  currentCoordinates
}) => {

  const [deliveryBoyInfoWindow, setDeliveryBoyInfoWindow] = useState({
    toggle: false,
  });
  const [wayPointsInfoWindow, setwayPointsInfoWindow] = useState({
    toggle: false,
  });
  const [simpleInfoWindow, setSimpleInfoWindow] = useState({
    toggle: false,
  });
  const [deliveryBoyIndex, setDeliveryBoyIndex] = useState(-1);
  const [wayPointIndex, setWayPointIndex] = useState(-1);

  useEffect(() => {
    if (deliveryBoyIndex === -1) {
      setDeliveryBoyInfoWindow({ toggle: false });
    } else {
      setDeliveryBoyInfoWindow({ ...data[deliveryBoyIndex], toggle: true });
    }
  }, [data, deliveryBoyIndex]);

  useEffect(() => {
    if (wayPointIndex === -1) {
      setwayPointsInfoWindow({ toggle: false });
    } else {
      setwayPointsInfoWindow({ ...fePod.data[wayPointIndex], toggle: true });
    }
  }, [fePod.data, wayPointIndex]);
 
  return (
    <>
      <GoogleMap
        defaultZoom={15}
        defaultOptions={{ styles: mapStyles }}
        defaultCenter={
          currentCoordinates?.lat
            ? {lat: currentCoordinates.lat, lng: currentCoordinates.lng}
            : { lat: 12.9716, lng: 77.5946 }
        }
      >
        
       
        {destinationCoordinates?.lat && currentCoordinates != null ? 
       (
        <MapDirections
        currentCoordinates={currentCoordinates}
        destinationCoordinates={destinationCoordinates}
        setWayPointIndex={setWayPointIndex}
        simpleInfoWindow={simpleInfoWindow}
        setSimpleInfoWindow={setSimpleInfoWindow}
        selectedDeliveryBoyIndex={selectedDeliveryBoyIndex}
        setDeliveryBoyIndex={setDeliveryBoyIndex}
      />
       )
       :
       (
          
          <Marker
          position={{ lat: destinationCoordinates?.lat, lng: destinationCoordinates?.lng }}
          icon="https://cubicom-s3-bucket-image.s3-ap-southeast-1.amazonaws.com/map_assets/active-ballon-icon.svg"
          onClick={() => {
            setSimpleInfoWindow({
              ...simpleInfoWindow,
              position: { lat: destinationCoordinates?.lat, lng: destinationCoordinates?.lng },
              message: 'Start',
              toggle: true,
            });
          }}
        />
        )}
      </GoogleMap>
      {deliveryBoyInfoWindow.toggle ? (
        <DeliveryBoyInfoWindow
          infoWindowData={deliveryBoyInfoWindow}
          setInfoWindowData={setDeliveryBoyInfoWindow}
          setIndex={setDeliveryBoyIndex}
        />
      ) : null}
      {wayPointsInfoWindow.toggle ? (
        <WayPointsInfoWindow
          infoWindowData={wayPointsInfoWindow}
          setInfoWindowData={setwayPointsInfoWindow}
          setIndex={setWayPointIndex}
        />
      ) : null}
      {simpleInfoWindow.toggle ? (
        <SimpleInfoWindow
          simpleInfoWindow={simpleInfoWindow}
          setSimpleInfoWindow={setSimpleInfoWindow}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    fePod: state.fePod,
  };
};

export default withScriptjs(withGoogleMap(connect(mapStateToProps, null)(Map)));
