import { handle } from 'redux-pack';

export default function CommonReducer(
  state = {
    loader: false,
    locations: [],
    permissions: [],
    snackbar: {},
    locationInUse: -1,
  },
  action,
) {
  switch (action.type) {
    case 'LOADER_VIEW':
      return { ...state, loader: action.payload };
    case 'ERROR_HANDLER':
      return { ...state, error: action.payload };
    case 'SHOW_SNACKBAR':
      return {
        ...state,
        snackbar: {
          ...action.payload,
          open: true,
        },
      };
    case 'HIDE_SNACKBAR':
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: false,
        },
      };
    case 'SHOW_MODAL':
      return {
        ...state,
        modal: {
          ...action.payload,
          open: true,
        },
      };
    case 'HIDE_MODAL':
      return {
        ...state,
        modal: {
          ...state.modal,
          open: false,
        },
      };
    case 'GET_LOCATIONS':
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          loader: true,
        }),
        finish: (prevState) => ({
          ...prevState,
          loader: false,
        }),
        failure: (prevState) => ({
          ...prevState,
          loader: false,
          locations: [],
        }),
        success: (prevState) => ({
          ...prevState,
          loader: false,
          locations: action.payload.locationResponse || [],
        }),
      });
    case 'SET_LOCATION':
      return {
        ...state,
        locationInUse: action.payload,
      };

    case 'SET_PERMISSIONS':
      return {
        ...state,
        permissions: action.payload,
      };

    case 'SET_AUTH':
      return {
        ...state,
        auth: action.payload,
      };

    case 'SET_DATE_RANGE': {
      return {
        ...state,
        dateRange: action.payload,
      };
    }

    default:
      return state;
  }
}
