import React, { useState } from 'react';
import { Link } from 'react-router-dom';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import 'twin.macro';

import styles from './side-nav.module.scss';

import {
  DashboardIcon,
  OperationsIcon,
  ReportsIcon,
  TrackingIcon,
  ConfIcon,
  UploadIcon,
  DRSIcon,
  PODIcon,
  InventoryIcon,
  UploadIconConfig,
  UserIcon,
} from '../icons';
import {
  UsersIconOn,
  RoleIconOn,
  ProductIconOn,
  PermissionIconOn,
  LocationOn,
  CustomerIconOn,
  FranchiseeIconOn,
  ManifestIcon,
  PickupIcon,
  CourierConnect,
  TransporterIcon,
  LineHaulTruck,
  RouteMaster,
  BaggingIcon,
  DebaggingIcon,
  WaybillInventoryIcon,
  CODIcon,
  CODRemittanceIcon,
  CODAuditIcon,
  CustRemittanceIcon,
  Trip,
  Driver,
  Vehicle,
} from '../../../../assets-stage.addup.network/images';
import { MODULES, SUBMODULES, ACTIONS } from '../../constants';
import { isModulePermitted } from '../../../../services/redux-state.service';
import { flatten, unflatten } from 'flat';

// const logo1 = require('../icons/Kyte.svg');
// // const logo = require('../icons/logo_black.svg');
// const logo = require('../icons/logo_white.svg');

let logo1;
let logo;

const SideNav = (props) => {
  const {
    history: {
      location: { pathname },
    },
    collapse,
    toggleSidebarCollapse,
  } = props;
  const isActive = (path) => pathname.includes(path);
  const isExactMatch = (path) => pathname === path;
  const [menus, setMenus] = useState({
    configurations: {
      isOpen: false,
      user: {
        isOpen: false,
      },
    },
    operations: {
      isOpen: false,
    },
    linehaul: {
      isOpen: false,
    },
    feeder: {
      isOpen: false,
    },
    pud: {
      isOpen: false,
    },
    cod: {
      isOpen: false,
    },
    billing: {
      isOpen: false,
    },
  });

  const handleOpenSubMenu = (menu, subMenu) => {
    const newMenus = flatten(menus);

    Object.keys(newMenus).forEach((key) => {
      if (!key.includes(menu)) {
        newMenus[key] = false;
      }
    });

    newMenus[`${subMenu}.isOpen`] = !newMenus[`${subMenu}.isOpen`];

    setMenus(unflatten(newMenus));
  };

  const urlSplit = window.location.href.split('/');
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    logo1 = require('../../../../assets-stage.addup.network/images/kyte.svg');
    logo = require('../../../../assets-stage.addup.network/images/logo_white.svg');
  } else if (urlSplit === 'meghrajexpress-stage.kyte.app') {
    logo1 = require(`../../../../assets-${urlSplit[2]}/images/kyte.svg`);
    logo = require(`../../../../assets-${urlSplit[2]}/images/logo_white.svg`);
  } else {
    logo1 = require(`../../../../assets-${urlSplit[2]}/images/kyte.svg`);
    logo = require(`../../../../assets-${urlSplit[2]}/images/logo_white.svg`);
  }

  return (
    <div
      onMouseOver={toggleSidebarCollapse}
      onMouseOut={toggleSidebarCollapse}
      className={`${styles.container} ${collapse ? styles.collapse : ''}`}
    >
      <div className={styles.headerIcon}>
        {collapse ? (
          <img className={styles.logo1} src={logo1} alt="Kyte" />
        ) : (
          <img className={styles.logo} src={logo} alt="Kyte" />
        )}
      </div>

      <ul className={styles.navigations}>
        {isModulePermitted(MODULES.DASHBOARD) ? (
          <li
            className={`${styles.nav} ${
              pathname === '/' || isActive('/dashboard') ? styles.active : ''
            }`}
          >
            <Link to="/" className={`${styles.link}`}>
              <DashboardIcon
                fill={
                  pathname === '/' || isActive('/dashboard')
                    ? // ? '#00a29d'
                      '#fff'
                    : '#fff'
                  // : '#b3b3b3'
                }
                id="1"
              />

              <span>Dashboard</span>
            </Link>
          </li>
        ) : null}
        {isModulePermitted(MODULES.OPERATIONS) ? (
          <li
            className={`${styles.nav} 
						${isActive('/operations') && !menus.operations.isOpen ? styles.active : ''}
						${menus.operations.isOpen ? styles.open : ''}`}
            onClick={() => handleOpenSubMenu('operations', 'operations')}
          >
            <p className={`${styles.link}`}>
              <OperationsIcon
                fill={
                  isActive('/operations') // ? '#00a29d'
                    ? '#fff'
                    : '#fff'
                  // : '#b3b3b3'
                }
                id="2"
              />
              <span>Operations</span>
              <i className="material-icons">
                {menus.operations.isOpen
                  ? 'keyboard_arrow_up'
                  : 'keyboard_arrow_down'}
              </i>
            </p>
          </li>
        ) : null}
        <div
          className={`${styles.level2} ${
            menus.operations.isOpen ? styles.open : ''
          } `}
        >
          <ul className={styles.navigations}>
            {/* {isModulePermitted(MODULES.OPERATIONS, SUBMODULES.PICKUP) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/operations/pickup-registration')
                    ? styles.active
                    : ''
                }`}
              >
                <Link
                  to="/operations/pickup-registration"
                  className={`${styles.link}`}
                >
                  <img src={PickupIcon} alt="Pickup Registration" />
                  <span style={{ marginLeft: '10px' }}>
                    Pickup Registration
                  </span>
                </Link>
              </li>
            ) : null} */}

            {isModulePermitted(MODULES.OPERATIONS, SUBMODULES.PICKUP) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/operations/pickup') ? styles.active : ''
                }`}
              >
                <Link to="/operations/pickup" className={`${styles.link}`}>
                  <img src={PickupIcon} alt="Pickup" />
                  <span style={{ marginLeft: '10px' }}>Pickup</span>
                </Link>
              </li>
            ) : null}
            {isModulePermitted(MODULES.OPERATIONS, SUBMODULES.BOOKING) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/operations/bookings') ? styles.active : ''
                }`}
              >
                <Link to="/operations/bookings" className={`${styles.link}`}>
                  <UploadIcon
                    fill={
                      isExactMatch('/operations/bookings') // ? '#00a29d'
                        ? '#fff'
                        : '#fff'
                      // : '#b3b3b3'
                    }
                    alt="Upload"
                  />
                  <span>Booking</span>
                </Link>
              </li>
            ) : null}
            {isModulePermitted(MODULES.OPERATIONS, SUBMODULES.INVENTORY) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/operations/inventory') ? styles.active : ''
                }`}
              >
                <Link to="/operations/inventory" className={`${styles.link}`}>
                  <div style={{ paddingLeft: '3px', paddingTop: '5px' }}>
                    <InventoryIcon
                      fill={
                        isExactMatch('/operations/inventory')
                          ? // ? '#00a29d'
                            '#fff'
                          : '#fff'
                        // : '#b3b3b3'
                      }
                      alt="Inventory"
                    />
                  </div>
                  <span style={{ marginLeft: '3px' }}>Inventory</span>
                </Link>
              </li>
            ) : null}
            {isModulePermitted(MODULES.OPERATIONS, SUBMODULES.BAGGING) ? (
              <li
                className={`${styles.nav}  ${
                  isExactMatch('/operations/bagging') ? styles.active : ''
                }`}
              >
                <Link to="/operations/bagging" className={`${styles.link}`}>
                  <img src={BaggingIcon} alt="bagging" />
                  <span style={{ marginLeft: '10px' }}>Bagging</span>
                </Link>
              </li>
            ) : null}
            {isModulePermitted(MODULES.OPERATIONS, SUBMODULES.DEBAGGING) ? (
              <li
                className={`${styles.nav}  ${
                  isExactMatch('/operations/debagging') ? styles.active : ''
                }`}
              >
                <Link to="/operations/debagging" className={`${styles.link}`}>
                  <img src={DebaggingIcon} alt="bagging" />

                  <span style={{ marginLeft: '10px' }}>De-Bagging</span>
                </Link>
              </li>
            ) : null}
            {isModulePermitted(MODULES.OPERATIONS, SUBMODULES.HANDOVER) ? (
              <li
                className={`${styles.nav}  ${
                  isExactMatch('/operations/handover') ? styles.active : ''
                }`}
              >
                <Link to="/operations/handover" className={`${styles.link}`}>
                  <img src={ManifestIcon} alt="manifest" />
                  <span style={{ marginLeft: '10px' }}>Manifest</span>
                </Link>
              </li>
            ) : null}
            {isModulePermitted(MODULES.OPERATIONS, SUBMODULES.DRS) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/operations/drs') ? styles.active : ''
                }`}
              >
                <Link to="/operations/drs" className={`${styles.link}`}>
                  <div className={styles.drsIcon}>
                    <DRSIcon
                      fill={
                        isExactMatch('/operations/drs') // ? '#00a29d'
                          ? '#fff'
                          : '#fff'
                        // : '#b3b3b3'
                      }
                      alt="DRS"
                    />
                  </div>
                  <span>DRS</span>
                </Link>
              </li>
            ) : null}

            {isModulePermitted(MODULES.OPERATIONS, SUBMODULES.POD) ? (
              <li
                className={`${styles.nav}  ${
                  isExactMatch('/operations/pod') ? styles.active : ''
                }`}
              >
                <Link to="/operations/pod" className={`${styles.link}`}>
                  <PODIcon
                    fill={
                      isExactMatch('/operations/pod') // ? '#00a29d'
                        ? '#fff'
                        : '#fff'
                      // : '#b3b3b3'
                    }
                    alt="POD"
                  />
                  <span style={{ marginLeft: '10px' }}>POD</span>
                </Link>
              </li>
            ) : null}
          </ul>
        </div>
        {isModulePermitted(MODULES.LINEHAUL) ? (
          <li
            className={`${styles.nav} 
						${isActive('/linehaul') && !menus.linehaul.isOpen ? styles.active : ''}
						${menus.linehaul.isOpen ? styles.open : ''}`}
            onClick={() => handleOpenSubMenu('linehaul', 'linehaul')}
          >
            <p className={`${styles.link}`} style={{ marginLeft: 5 }}>
              <img
                src={
                  isActive('/linehaul') ? LineHaulTruck : LineHaulTruck
                  // : PermissionIconOff
                }
                alt="linehaul"
              />
              <span>Line Haul (Hub - Hub)</span>
              <i className="material-icons">
                {menus.linehaul.isOpen
                  ? 'keyboard_arrow_up'
                  : 'keyboard_arrow_down'}
              </i>
            </p>
          </li>
        ) : null}
        <div
          className={`${styles.level2} ${
            menus.linehaul.isOpen ? styles.open : ''
          } `}
          style={{ marginTop: 2 }}
        >
          <ul className={styles.navigations}>
            {isModulePermitted(MODULES.LINEHAUL, SUBMODULES.TRIP) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/linehaul/trip') ? styles.active : ''
                }`}
              >
                <Link to="/linehaul/trip" className={`${styles.link}`}>
                  <img
                    src={isExactMatch('/linehaul/trip') ? Trip : Trip}
                    alt="trip"
                  />
                  <span>Trip</span>
                </Link>
              </li>
            ) : null}
            {isModulePermitted(MODULES.LINEHAUL, SUBMODULES.ROUTE) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/linehaul/routemaster') ? styles.active : ''
                }`}
              >
                <Link to="/linehaul/routemaster" className={`${styles.link}`}>
                  <img
                    src={
                      isExactMatch('/linehaul/routemaster')
                        ? RouteMaster
                        : RouteMaster
                    }
                    alt="routemaster"
                  />
                  <span>Route</span>
                </Link>
              </li>
            ) : null}

            {isModulePermitted(MODULES.LINEHAUL, SUBMODULES.DRIVER) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/linehaul/driver') ? styles.active : ''
                }`}
              >
                <Link to="/linehaul/driver" className={`${styles.link}`}>
                  <img
                    style={{ marginLeft: 5 }}
                    src={isExactMatch('/linehaul/driver') ? Driver : Driver}
                    alt="driver"
                  />
                  <span>Driver</span>
                </Link>
              </li>
            ) : null}

            {isModulePermitted(MODULES.LINEHAUL, SUBMODULES.VEHICLE) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/linehaul/vehicle') ? styles.active : ''
                }`}
              >
                <Link to="/linehaul/vehicle" className={`${styles.link}`}>
                  <img
                    style={{ marginLeft: 2 }}
                    src={isExactMatch('/linehaul/vehicle') ? Vehicle : Vehicle}
                    alt="vehicle"
                  />
                  <span>Vehicle</span>
                </Link>
              </li>
            ) : null}

            {isModulePermitted(MODULES.LINEHAUL, SUBMODULES.TRANSPORTER) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/linehaul/transporter') ? styles.active : ''
                }`}
                style={{ marginLeft: 5 }}
              >
                <Link to="/linehaul/transporter" className={`${styles.link}`}>
                  <img
                    src={
                      isExactMatch('/linehaul/transporter')
                        ? TransporterIcon
                        : TransporterIcon
                    }
                    alt="transporter"
                  />
                  <span>Transporter</span>
                </Link>
              </li>
            ) : null}

            {isModulePermitted(
              MODULES.LINEHAUL,
              SUBMODULES.ROUTE_CONNECTION,
            ) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/linehaul/route-connection')
                    ? styles.active
                    : ''
                }`}
                style={{ marginLeft: 5 }}
              >
                <Link
                  to="/linehaul/route-connection"
                  className={`${styles.link}`}
                >
                  <img
                    src={
                      isExactMatch('/linehaul/route-connection')
                        ? CourierConnect
                        : CourierConnect
                    }
                    alt="RouteConnection"
                  />
                  <span>Route Connection</span>
                </Link>
              </li>
            ) : null}
          </ul>
        </div>

        {/* <li
          className={`${styles.nav} 
						${isActive('/feeder') && !menus.feeder.isOpen ? styles.active : ''}
						${menus.feeder.isOpen ? styles.open : ''}`}
          onClick={() => handleOpenSubMenu('feeder', 'feeder')}
        >
          <p className={`${styles.link}`} style={{ marginLeft: 5 }}>
            <img
              src={
                isActive('/feeder') ? LineHaulTruck : LineHaulTruck
                // : PermissionIconOff
              }
              alt="linehaul"
            />
            <span>Feeder (Hub - Station)</span>
            <i className="material-icons">
              {menus.feeder.isOpen
                ? 'keyboard_arrow_up'
                : 'keyboard_arrow_down'}
            </i>
          </p>
        </li>

        <div
          className={`${styles.level2} ${
            menus.feeder.isOpen ? styles.open : ''
          } `}
          style={{ marginTop: 2 }}
        >
          <ul className={styles.navigations}>
            <li
              className={`${styles.nav} ${
                isExactMatch('/feeder/trip') ? styles.active : ''
              }`}
            >
              <Link to="/feeder/trip" className={`${styles.link}`}>
                <img
                  src={isExactMatch('/feeder/trip') ? Trip : Trip}
                  alt="trip"
                />
                <span>Trip</span>
              </Link>
            </li>
            <li
              className={`${styles.nav} ${
                isExactMatch('/feeder/routemaster') ? styles.active : ''
              }`}
            >
              <Link to="/feeder/routemaster" className={`${styles.link}`}>
                <img
                  src={
                    isExactMatch('/feeder/routemaster')
                      ? RouteMaster
                      : RouteMaster
                  }
                  alt="routemaster"
                />
                <span>Route</span>
              </Link>
            </li>

            <li
              className={`${styles.nav} ${
                isExactMatch('/feeder/driver') ? styles.active : ''
              }`}
            >
              <Link to="/feeder/driver" className={`${styles.link}`}>
                <img
                  style={{ marginLeft: 5 }}
                  src={isExactMatch('/feeder/driver') ? Driver : Driver}
                  alt="driver"
                />
                <span>Driver</span>
              </Link>
            </li>

            <li
              className={`${styles.nav} ${
                isExactMatch('/feeder/vehicle') ? styles.active : ''
              }`}
            >
              <Link to="/feeder/vehicle" className={`${styles.link}`}>
                <img
                  style={{ marginLeft: 2 }}
                  src={isExactMatch('/feeder/vehicle') ? Vehicle : Vehicle}
                  alt="vehicle"
                />
                <span>Vehicle</span>
              </Link>
            </li>

            <li
              className={`${styles.nav} ${
                isExactMatch('/feeder/transporter') ? styles.active : ''
              }`}
              style={{ marginLeft: 5 }}
            >
              <Link to="/feeder/transporter" className={`${styles.link}`}>
                <img
                  src={
                    isExactMatch('/feeder/transporter')
                      ? TransporterIcon
                      : TransporterIcon
                  }
                  alt="transporter"
                />
                <span>Transporter</span>
              </Link>
            </li>
          </ul>
        </div>
        <li
          className={`${styles.nav} 
						${isActive('/pud') && !menus.pud.isOpen ? styles.active : ''}
						${menus.pud.isOpen ? styles.open : ''}`}
          onClick={() => handleOpenSubMenu('pud', 'pud')}
        >
          <p className={`${styles.link}`} style={{ marginLeft: 5 }}>
            <img
              src={
                isActive('/pud') ? LineHaulTruck : LineHaulTruck
                // : PermissionIconOff
              }
              alt="linehaul"
            />
            <span>PUD (Local Pickup / Delivery)</span>
            <i className="material-icons">
              {menus.pud.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </i>
          </p>
        </li>
        <div
          className={`${styles.level2} ${menus.pud.isOpen ? styles.open : ''} `}
          style={{ marginTop: 2 }}
        >
          <ul className={styles.navigations}>
            <li
              className={`${styles.nav} ${
                isExactMatch('/pud/trip') ? styles.active : ''
              }`}
            >
              <Link to="/pud/trip" className={`${styles.link}`}>
                <img src={isExactMatch('/pud/trip') ? Trip : Trip} alt="trip" />
                <span>Trip</span>
              </Link>
            </li>
            <li
              className={`${styles.nav} ${
                isExactMatch('/pud/routemaster') ? styles.active : ''
              }`}
            >
              <Link to="/pud/routemaster" className={`${styles.link}`}>
                <img
                  src={
                    isExactMatch('/pud/routemaster') ? RouteMaster : RouteMaster
                  }
                  alt="routemaster"
                />
                <span>Route</span>
              </Link>
            </li>

            <li
              className={`${styles.nav} ${
                isExactMatch('/pud/driver') ? styles.active : ''
              }`}
            >
              <Link to="/pud/driver" className={`${styles.link}`}>
                <img
                  style={{ marginLeft: 5 }}
                  src={isExactMatch('/pud/driver') ? Driver : Driver}
                  alt="driver"
                />
                <span>Driver</span>
              </Link>
            </li>

            <li
              className={`${styles.nav} ${
                isExactMatch('/pud/vehicle') ? styles.active : ''
              }`}
            >
              <Link to="/pud/vehicle" className={`${styles.link}`}>
                <img
                  style={{ marginLeft: 2 }}
                  src={isExactMatch('/pud/vehicle') ? Vehicle : Vehicle}
                  alt="vehicle"
                />
                <span>Vehicle</span>
              </Link>
            </li>

            <li
              className={`${styles.nav} ${
                isExactMatch('/pud/transporter') ? styles.active : ''
              }`}
              style={{ marginLeft: 5 }}
            >
              <Link to="/pud/transporter" className={`${styles.link}`}>
                <img
                  src={
                    isExactMatch('/pud/transporter')
                      ? TransporterIcon
                      : TransporterIcon
                  }
                  alt="transporter"
                />
                <span>Transporter</span>
              </Link>
            </li>
          </ul>
        </div> */}

        {isModulePermitted(MODULES.COD) ? (
          <li
            className={`${styles.nav} 
						${isActive('/cod') && !menus.cod.isOpen ? styles.active : ''}
						${menus.cod.isOpen ? styles.open : ''}`}
            onClick={() => handleOpenSubMenu('cod', 'cod')}
          >
            <p className={`${styles.link}`} style={{ marginLeft: 5 }}>
              <img
                src={
                  isActive('/cod') ? CODIcon : CODIcon
                  // : PermissionIconOff
                }
                alt="cod"
              />
              <span>COD</span>
              <i className="material-icons">
                {menus.cod.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </i>
            </p>
          </li>
        ) : null}
        <div
          className={`${styles.level2} ${menus.cod.isOpen ? styles.open : ''} `}
        >
          <ul className={styles.navigations}>
            {isModulePermitted(MODULES.COD, SUBMODULES.CODREMITTANCE) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/cod/cod-remittance') ? styles.active : ''
                }`}
              >
                <Link to="/cod/cod-remittance" className={`${styles.link}`}>
                  <img
                    src={
                      isExactMatch('/cod/cod-remittance')
                        ? CODRemittanceIcon
                        : CODRemittanceIcon
                    }
                    alt="trip"
                  />
                  <span>COD Remittance</span>
                </Link>
              </li>
            ) : null}
            {isModulePermitted(MODULES.COD, SUBMODULES.CODAUDIT) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/cod/cod-audit') ? styles.active : ''
                }`}
              >
                <Link to="/cod/cod-audit" className={`${styles.link}`}>
                  <img
                    src={
                      isExactMatch('/cod/cod-audit')
                        ? CODAuditIcon
                        : CODAuditIcon
                    }
                    alt="routemaster"
                  />
                  <span>COD Audit</span>
                </Link>
              </li>
            ) : null}
            {isModulePermitted(MODULES.COD, SUBMODULES.CUSTREMITTANCE) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/cod/cust-remittance') ? styles.active : ''
                }`}
              >
                <Link to="/cod/cust-remittance" className={`${styles.link}`}>
                  <img
                    style={{ marginLeft: 5 }}
                    src={
                      isExactMatch('/cod/cust-remittance')
                        ? CustRemittanceIcon
                        : CustRemittanceIcon
                    }
                    alt="driver"
                  />
                  <span>Cust. Remittance</span>
                </Link>
              </li>
            ) : null}
          </ul>
        </div>

        <li
          className={`${styles.nav} 
						${isActive('/billing') && !menus.billing.isOpen ? styles.active : ''}
						${menus.billing.isOpen ? styles.open : ''}`}
          onClick={() => handleOpenSubMenu('billing', 'billing')}
        >
          <p className={`${styles.link}`} style={{ marginLeft: 5 }}>
            <UploadIcon
              fill={
                '#fff'
                // : '#b3b3b3'
              }
              alt="Billing"
            />
            <span>Billing</span>
            <i className="material-icons">
              {menus.billing.isOpen
                ? 'keyboard_arrow_up'
                : 'keyboard_arrow_down'}
            </i>
          </p>
        </li>

        <div
          className={`${styles.level2} ${
            menus.billing.isOpen ? styles.open : ''
          } `}
          style={{ marginTop: 2 }}
        >
          <ul className={styles.navigations}>
            <li
              className={`${styles.nav} ${
                isExactMatch('/billing/invoice') ? styles.active : ''
              }`}
            >
              <Link to="/billing/invoice" className={`${styles.link}`}>
                <ReportsIcon
                  fill={
                    isActive('/invoice') // ? '#00a29d'
                      ? '#fff'
                      : '#fff'
                    // : '#b3b3b3'
                  }
                  id="3"
                />
                <span>Invoice</span>
              </Link>
            </li>

            {isModulePermitted(MODULES.BILLING, SUBMODULES.RATE) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/billing/rate') ? styles.active : ''
                }`}
              >
                <Link to="/billing/rate" className={`${styles.link}`}>
                  <PODIcon
                    fill={
                      isExactMatch('/billing/rate') // ? '#00a29d'
                        ? '#fff'
                        : '#fff'
                      // : '#b3b3b3'
                    }
                    alt="POD"
                  />
                  <span>Rate</span>
                </Link>
              </li>
            ) : null}

            {isModulePermitted(MODULES.BILLING, SUBMODULES.VAS) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/billing/vas') ? styles.active : ''
                }`}
              >
                <Link to="/billing/vas" className={`${styles.link}`}>
                  <DRSIcon
                    fill={
                      isExactMatch('/billing/vas') // ? '#00a29d'
                        ? '#fff'
                        : '#fff'
                      // : '#b3b3b3'
                    }
                    alt="DRS"
                  />
                  <span>VAS</span>
                </Link>
              </li>
            ) : null}

            {isModulePermitted(MODULES.BILLING, SUBMODULES.ZONE) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/billing/zone') ? styles.active : ''
                }`}
              >
                <Link to="/billing/zone" className={`${styles.link}`}>
                  <img
                    src={
                      isExactMatch('/billing/zone') ? LocationOn : LocationOn
                      // : LocationOff
                    }
                    alt="Location"
                  />
                  <span>Zone</span>
                </Link>
              </li>
            ) : null}
          </ul>
        </div>

        {isModulePermitted(MODULES.TRACKING) ? (
          <li
            style={{ paddingLeft: '3px' }}
            className={`${styles.nav} ${
              isActive('/tracking') ? styles.active : ''
            }`}
          >
            <Link to="/tracking" className={`${styles.link}`}>
              <TrackingIcon
                fill={
                  isActive('/tracking') // ? '#00a29d'
                    ? '#fff'
                    : '#fff'
                  // : '#b3b3b3'
                }
                id="3"
              />
              <span>Tracking</span>
            </Link>
          </li>
        ) : null}
        {isModulePermitted(MODULES.REPORTS) ? (
          <li
            className={`${styles.nav} ${
              isActive('/reports') ? styles.active : ''
            }`}
          >
            <Link to="/reports" className={`${styles.link}`}>
              <ReportsIcon
                fill={
                  isActive('/reports') // ? '#00a29d'
                    ? '#fff'
                    : '#fff'
                  // : '#b3b3b3'
                }
                id="3"
              />
              <span>Reports</span>
            </Link>
          </li>
        ) : null}
        {isModulePermitted(MODULES.CONFIGURATIONS) ? (
          <li
            className={`${styles.nav}
						 ${
               isActive('/configurations') && !menus.configurations.isOpen
                 ? styles.active
                 : ''
             }
							 ${menus.configurations.isOpen ? styles.open : ''}`}
            onClick={() =>
              handleOpenSubMenu('configurations', 'configurations')
            }
          >
            <p className={`${styles.link}`}>
              <ConfIcon
                fill={
                  isActive('/configurations') // ? '#00a29d'
                    ? '#fff'
                    : '#fff'
                  // : '#b3b3b3'
                }
                id="4"
              />
              <span>Configurations</span>
              <i className="material-icons">
                {menus.configurations.isOpen
                  ? 'keyboard_arrow_up'
                  : 'keyboard_arrow_down'}
              </i>
            </p>
          </li>
        ) : null}
        <div
          className={`${styles.level2} ${
            menus.configurations.isOpen ? styles.open : ''
          } `}
        >
          <ul className={styles.navigations}>
            {isModulePermitted(MODULES.CONFIGURATIONS, SUBMODULES.CUSTOMERS) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/configurations/customers') ? styles.active : ''
                }`}
              >
                <Link
                  to="/configurations/customers"
                  className={`${styles.link}`}
                >
                  <img
                    src={
                      isExactMatch('/configurations/customers')
                        ? CustomerIconOn
                        : CustomerIconOn
                      // : CustomerIconOff
                    }
                    alt="Customer"
                  />
                  <span>Customer</span>
                </Link>
              </li>
            ) : null}
            {isModulePermitted(MODULES.CONFIGURATIONS, SUBMODULES.LOCATIONS) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/configurations/locations') ? styles.active : ''
                }`}
              >
                <Link
                  to="/configurations/locations"
                  className={`${styles.link}`}
                >
                  <img
                    src={
                      isExactMatch('/configurations/locations')
                        ? LocationOn
                        : LocationOn
                      // : LocationOff
                    }
                    alt="Location"
                  />
                  <span>Location</span>
                </Link>
              </li>
            ) : null}

            {isModulePermitted(MODULES.CONFIGURATIONS, SUBMODULES.REGION) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/configurations/region') ? styles.active : ''
                }`}
              >
                <Link to="/configurations/region" className={`${styles.link}`}>
                  <img
                    src={
                      isExactMatch('/configurations/region')
                        ? LocationOn
                        : LocationOn
                      // : LocationOff
                    }
                    alt="Location"
                  />
                  <span>Region</span>
                </Link>
              </li>
            ) : null}

            {isModulePermitted(MODULES.CONFIGURATIONS, SUBMODULES.PRODUCTS) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/configurations/products') ? styles.active : ''
                }`}
              >
                <Link
                  to="/configurations/products"
                  className={`${styles.link}`}
                >
                  <img
                    src={
                      isExactMatch('/configurations/products')
                        ? ProductIconOn
                        : ProductIconOn
                      // : ProductIconOff
                    }
                    alt="Product"
                  />
                  <span>Product</span>
                </Link>
              </li>
            ) : null}

            {isModulePermitted(
              MODULES.CONFIGURATIONS,
              SUBMODULES.STATUS_CODE,
            ) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/configurations/status-code')
                    ? styles.active
                    : ''
                }`}
              >
                <Link
                  to="/configurations/status-code"
                  className={`${styles.link}`}
                >
                  <div style={{ paddingLeft: '3px', paddingTop: '5px' }}>
                    <InventoryIcon
                      fill={
                        isExactMatch('/operations/inventory')
                          ? // ? '#00a29d'
                            '#fff'
                          : '#fff'
                        // : '#b3b3b3'
                      }
                      alt="Inventory"
                    />
                  </div>
                  <span>Status Code</span>
                </Link>
              </li>
            ) : null}

            {isModulePermitted(
              MODULES.CONFIGURATIONS,
              SUBMODULES.COMMODITY_CODE,
            ) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/configurations/commodity-code')
                    ? styles.active
                    : ''
                }`}
              >
                <Link
                  to="/configurations/commodity-code"
                  className={`${styles.link}`}
                >
                  <div style={{ paddingLeft: '3px', paddingTop: '5px' }}>
                    <InventoryIcon
                      fill={
                        isExactMatch('/configurations/commodity-code')
                          ? // ? '#00a29d'
                            '#fff'
                          : '#fff'
                        // : '#b3b3b3'
                      }
                      alt="Inventory"
                    />
                  </div>
                  <span>Commodity Code</span>
                </Link>
              </li>
            ) : null}

            {isModulePermitted(
              MODULES.CONFIGURATIONS,
              SUBMODULES.FRANCHISEES,
            ) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/configurations/franchisee')
                    ? styles.active
                    : ''
                }`}
              >
                <Link
                  to="/configurations/franchisee"
                  className={`${styles.link}`}
                >
                  <img
                    src={
                      isExactMatch('/configurations/franchisee')
                        ? FranchiseeIconOn
                        : FranchiseeIconOn
                      // : FranchiseeIconOff
                    }
                    alt="Franchisee"
                  />
                  <span>Franchisee</span>
                </Link>
              </li>
            ) : null}
            {isModulePermitted(
              MODULES.CONFIGURATIONS,
              SUBMODULES.UPLOADTRANSIT,
            ) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/configurations/upload') ? styles.active : ''
                }`}
              >
                <Link to="/configurations/upload" className={`${styles.link}`}>
                  <UploadIconConfig
                    fill={
                      isExactMatch('/configurations/upload') ? '#fff' : '#fff'
                    }
                    alt="UploadTransit"
                  />
                  <span>Upload</span>
                </Link>
              </li>
            ) : null}
            {isModulePermitted(
              MODULES.CONFIGURATIONS,
              SUBMODULES.COURIERCONNECT,
            ) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/configurations/CourierConnect')
                    ? styles.active
                    : ''
                }`}
              >
                <Link
                  to="/configurations/CourierConnect"
                  className={`${styles.link}`}
                >
                  <img
                    src={
                      isExactMatch('/configurations/CourierConnect')
                        ? CourierConnect
                        : CourierConnect
                      // : UsersIconOff
                    }
                    alt="Users"
                  />
                  <span>Courier Connect</span>
                </Link>
              </li>
            ) : null}
            {isModulePermitted(
              MODULES.CONFIGURATIONS,
              SUBMODULES.WAYBILLINVENTORY,
            ) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/configurations/WaybillInventory')
                    ? styles.active
                    : ''
                }`}
              >
                <Link
                  to="/configurations/WaybillInventory"
                  className={`${styles.link}`}
                >
                  <img
                    src={
                      isExactMatch('/configurations/WaybillInventory')
                        ? WaybillInventoryIcon
                        : WaybillInventoryIcon
                      // : UsersIconOff
                    }
                    alt="Users"
                  />
                  <span>Waybill Inventory</span>
                </Link>
              </li>
            ) : null}
            {isModulePermitted(MODULES.CONFIGURATIONS, SUBMODULES.PINCODE) ? (
              <li
                className={`${styles.nav} ${
                  isExactMatch('/configurations/pincode') ? styles.active : ''
                }`}
              >
                <Link to="/configurations/pincode" className={`${styles.link}`}>
                  <img
                    src={
                      isExactMatch('/configurations/pincode')
                        ? LocationOn
                        : LocationOn
                      // : UsersIconOff
                    }
                    alt="Users"
                  />
                  <span>Pincode</span>
                </Link>
              </li>
            ) : null}
            {isModulePermitted(MODULES.CONFIGURATIONS) ? (
              <li
                className={`${styles.nav} 
						${
              isActive('/configurations/user') &&
              !menus.configurations.user.isOpen
                ? styles.active
                : ''
            }
						${menus.configurations.user.isOpen ? styles.open : ''}`}
                onClick={() =>
                  handleOpenSubMenu('configurations', 'configurations.user')
                }
              >
                <p className={`${styles.link}`}>
                  {isActive('/configurations/user') ? (
                    <svg
                      tw="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      tw="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                  <span>User</span>
                  <i className="material-icons">
                    {menus.configurations.user.isOpen
                      ? 'keyboard_arrow_up'
                      : 'keyboard_arrow_down'}
                  </i>
                </p>
              </li>
            ) : null}
            <div
              className={`${styles.level3} ${
                menus.configurations.user.isOpen ? styles.open : ''
              } `}
            >
              {isModulePermitted(
                MODULES.CONFIGURATIONS,
                SUBMODULES.PERMISSIONS,
              ) ? (
                <li
                  className={`${styles.nav} ${
                    isExactMatch('/configurations/user/permissions')
                      ? styles.active
                      : ''
                  }`}
                >
                  <Link
                    to="/configurations/user/permissions"
                    className={`${styles.link}`}
                  >
                    <img
                      src={
                        isExactMatch('/configurations/user/permissions')
                          ? PermissionIconOn
                          : PermissionIconOn
                        // : PermissionIconOff
                      }
                      alt="Permissions"
                    />
                    <span>Permissions</span>
                  </Link>
                </li>
              ) : null}
              {isModulePermitted(MODULES.CONFIGURATIONS, SUBMODULES.ROLES) ? (
                <li
                  className={`${styles.nav} ${
                    isExactMatch('/configurations/user/roles')
                      ? styles.active
                      : ''
                  }`}
                >
                  <Link
                    to="/configurations/user/roles"
                    className={`${styles.link}`}
                  >
                    <img
                      src={
                        isExactMatch('/configurations/user/roles')
                          ? RoleIconOn
                          : RoleIconOn
                        // : RoleIconOff
                      }
                      alt="Role"
                    />
                    <span>Role</span>
                  </Link>
                </li>
              ) : null}
              {isModulePermitted(MODULES.CONFIGURATIONS, SUBMODULES.USERS) ? (
                <li
                  className={`${styles.nav} ${
                    isExactMatch('/configurations/user/users')
                      ? styles.active
                      : ''
                  }`}
                >
                  <Link
                    to="/configurations/user/users"
                    className={`${styles.link}`}
                  >
                    <img
                      src={
                        isExactMatch('/configurations/user/users')
                          ? UsersIconOn
                          : UsersIconOn
                        // : UsersIconOff
                      }
                      alt="Users"
                    />
                    <span>Users</span>
                  </Link>
                </li>
              ) : null}
            </div>
          </ul>
        </div>
      </ul>
      {/* <div className={styles.sidebarAction} onClick={toggleSidebarCollapse}>
				<img
					src={CollapseIcon}
					className={collapse ? styles.collapse : styles.expand}
				/>
			</div> */}
    </div>
  );
};

export default SideNav;
