import * as types from './bookingActionType';
import axios from 'axios';
import moment from 'moment-timezone';
import { PARTNER_URL } from '../../../../environments';

export function GetBookingList(
  locationInUse,
  page = 0,
  searchTerm = '',
  statusSelected,
  dateRange = {},
  dateRange1,
  defRange1,
) {
  const resDate = { dateRange1, defRange1 };
  const pageNo = !searchTerm ? page : 0;
  return function (dispatch) {
    dispatch(getBookingListInitiation());
    if(statusSelected != "" && statusSelected != "ALL" ){
    axios
      .post(`${PARTNER_URL}/consignments/get`, {
        locationIds: locationInUse?.length > 1 ? locationInUse : locationInUse == null ? null : [locationInUse],
        page: pageNo,
        searchTerm,
        consignmentStatus: statusSelected,
        ...dateRange,
      })
      .then((res) => {
        if (
          res.data.status.success === true &&
          res.data.status.message === 'SUCCESS'
        ) {
          dispatch(getBookingListSuccess(res.data, resDate));
        }
      })
      .catch((err) => {
        dispatch(getBookingListError(err));
      });
    }
    else{
      axios
      .post(`${PARTNER_URL}/consignments/get`, {
        locationIds: locationInUse?.length > 1 ? locationInUse : locationInUse == null ? null : [locationInUse], 
        page: pageNo,
        searchTerm,
        ...dateRange,
      })
      .then((res) => {
        if (
          res.data.status.success === true &&
          res.data.status.message === 'SUCCESS'
        ) {
          dispatch(getBookingListSuccess(res.data, resDate));
        }
      })
      .catch((err) => {
        dispatch(getBookingListError(err));
      });
    }
  };
}

export function getBookingListInitiation() {
  return {
    type: types.GET_BOOKING_LIST_INITIATED,
  };
}

export function getBookingListSuccess(obj, res) {
  return {
    type: types.GET_BOOKING_LIST_SUCCESS,
    obj,
    res,
  };
}
export function getBookingListError(BookingError) {
  return {
    type: types.GET_BOOKING_LIST_FAILURE,
    BookingError,
  };
}
export function getBookingLisrTempClear() {
  return {
    type: types.GET_BOOKING_LIST_TEMP_CLEAR,
  };
}
export function GetBooking(ids, token) {
  let body = JSON.stringify([ids]);
  return function (dispatch) {
    dispatch(getBookingInitiation());
    axios
      .post(`${PARTNER_URL}/consignments/ids`, body, {
        Authorization: `Bearer ${token}`,
      })

      .then((res) => {
        if (
          res.data.status.success === true &&
          res.data.status.message === 'SUCCESS'
        ) {
          dispatch(getBookingSuccess(res.data.response.consignmentResponse));
        }
      })
      .catch((err) => {
        dispatch(getBookingError(err.message));
      });
  };
}
export function getBookingInitiation() {
  return {
    type: types.GET_BOOKING_INITIATED,
  };
}
export function getBookingClear() {
  return {
    type: types.GET_BOOKING_TEMP_CLEAR,
  };
}
export function getBookingSuccess(BookingDetails) {
  let details = BookingDetails[0];
  try {
    let obj = {
      consignmentId: details.id,
      pickupRefNo: details.pickupRefNo,
      waybillNo: details.waybillNo,
      orderRefNo: details.orderRefNo ? details.orderRefNo : '',
      pickupDate: moment(details.pickupDate).format('YYYY-MM-DD'),
      bookingDate: details.bookingDate,
      expectedDeliveryDate: details.expectedDeliveryDate
        ? details.expectedDeliveryDate
        : moment(details.bookingDate).add(1, 'day').format('YYYY-MM-DD'),
      sourceLocationId: details.sourceLocId ? details.sourceLocId : '',
      destinationLocId: details.destinationLocId
        ? details.destinationLocId
        : '',
      productId: details.productId ? details.productId : '',
      productCode:
        details.productDTO && details.productDTO.code
          ? details.productDTO.code
          : '',
      pieces: details.pieces ? details.pieces : '',
      actualWeight: details.weight ? details.weight : '',
      volumetricWeight: details.volumetricWeight
        ? details.volumetricWeight
        : '',
      chargeableWeight: details.chargeableWeight
        ? details.chargeableWeight
        : '',
      shipmentValue: details.shipmentValue ? details.shipmentValue : '',
      commodity: details.commodity ? details.commodity : '',
      paymentType: details.paymentType ? details.paymentType : '',
      codAmount: details.codAmount ? details.codAmount : '',
      insuranceType: details.insuranceType ? details.insuranceType : '',
      vas: details.vas ? details.vas : '',
      billingType: details.billingType ? details.billingType : '',
      packageType: details.packageType ? details.packageType : '',
      billingCustCode: details.billingCustCode ? details.billingCustCode : '',
      customer: details.customerDTO
        ? {
            id: details.customerDTO.id ? details.customerDTO.id : '',
            name: details.customerDTO.name ? details.customerDTO.name : '',
            code: details.customerDTO.code ? details.customerDTO.code : '',
            contactNo: details.customerDTO.contactNo
              ? details.customerDTO.contactNo
              : '',
            email: details.customerDTO.email ? details.customerDTO.email : '',
            customerCode: details.customerDTO.code
              ? details.customerDTO.code
              : '',
            sameAsPickup: details.customerDTO.sameAsPickup
              ? details.customerDTO.sameAsPickup
              : '',

            billingAddress: details.customerDTO.billingAddress
              ? {
                  address1: details.customerDTO.billingAddress.address1
                    ? details.customerDTO.billingAddress.address1
                    : '',
                  address2: details.customerDTO.billingAddress.address2
                    ? details.customerDTO.billingAddress.address2
                    : '',
                  zipcode: details.customerDTO.billingAddress.zipcode
                    ? details.customerDTO.billingAddress.zipcode
                    : '',
                }
              : {},
            pickupAddress: details.customerDTO.pickupAddress
              ? [
                  {
                    address1: details.customerDTO.pickupAddress[0].address1
                      ? details.customerDTO.pickupAddress[0].address1
                      : '',
                    address2: details.customerDTO.pickupAddress[0].address2
                      ? details.customerDTO.pickupAddress[0].address2
                      : '',
                    zipcode: details.customerDTO.pickupAddress[0].zipcode
                      ? details.customerDTO.pickupAddress[0].zipcode
                      : '',
                  },
                ]
              : [
                  {
                    address1: '',
                    address2: '',
                    zipcode: '',
                  },
                ],
          }
        : {
            id: '',
            name: '',
            contactNo: '',
            email: '',
            code: '',
            sameAsPickup: true,
            billingAddress: {
              address1: '',
              address2: '',
              zipcode: '',
            },
            pickupAddress: [
              {
                address1: '',
                address2: '',
                zipcode: '',
              },
            ],
          },
      consignee: details.consigneeResponse
        ? {
            id: details.consigneeResponse.id
              ? details.consigneeResponse.id
              : '',
            code: '0000',
            name: details.consigneeResponse.name
              ? details.consigneeResponse.name
              : '',
            accountNo: details.consigneeResponse.accountNo
              ? details.consigneeResponse.accountNo
              : '',
            contactNo: details.consigneeResponse.contactNo
              ? details.consigneeResponse.contactNo
              : '',
            emailId: details.consigneeResponse.emailId
              ? details.consigneeResponse.emailId
              : '',
            addressId: details.consigneeResponse.addressId
              ? details.consigneeResponse.addressId
              : '',
            address: details.consigneeResponse.address
              ? {
                  id: details.consigneeResponse.address.id
                    ? details.consigneeResponse.address.id
                    : '',
                  address1: details.consigneeResponse.address.address1
                    ? details.consigneeResponse.address.address1
                    : '',
                  address2: details.consigneeResponse.address.address2
                    ? details.consigneeResponse.address.address2
                    : '',
                  zipcode: details.consigneeResponse.address.zipcode
                    ? details.consigneeResponse.address.zipcode
                    : '',
                }
              : {
                  id: '',
                  address1: '',
                  address2: '',
                  zipcode: '',
                },
          }
        : {
            code: '0000',
            name: '',
            accountNo: '',
            contactNo: '',
            emailId: '',
            addressId: '',
            address: {
              address1: '',
              address2: '',
              zipcode: '',
            },
          },
      // length: details.length,
      // breadth: details.breadth,
      // height: details.height,
      cft: details.cft ? details.cft : '',
      sourcePincode: '',
      vehicleNo: '',
      mpsDetailList: details.mpsDetailList ? details.mpsDetailList : '',
      customerCode:
        details.customerDTO && details.customerDTO.code
          ? details.customerDTO.code
          : '',
      customerName:
        details.consigneeResponse && details.consigneeResponse.name
          ? details.consigneeResponse.name
          : '',
      customerContactNo:
        details.customerDTO && details.customerDTO.contactNo
          ? details.customerDTO.contactNo
          : '',
      customerEmail:
        details.customerDTO && details.customerDTO.email
          ? details.customerDTO.email
          : '',
    };
    return {
      type: types.GET_BOOKING_SUCCESS,
      obj,
    };
  } catch (e) {}
}
export function getBookingError(BookingError) {
  return {
    type: types.GET_BOOKING_FAILURE,
    BookingError,
  };
}

export function EditBooking(detail, token) {
  let body = JSON.stringify([detail]);

  return function (dispatch) {
    dispatch(editBookingInitiation());
    axios
      .put(`${PARTNER_URL}/consignments`, body, {
        Authorization: `Bearer ${token}`,
      })

      .then((res) => {
        dispatch(editBookingSuccess(res));
      })
      .catch((err) => {
        dispatch(editBookingError(err.message));
      });
  };
}

export function editBookingInitiation() {
  return {
    type: types.EDIT_BOOKING_INITIATED,
  };
}

export function editBookingSuccess(res) {
  return {
    type: types.EDIT_BOOKING_SUCCESS,
    res,
  };
}

export function editBookingError(message) {
  return {
    type: types.EDIT_BOOKING_FAILURE,
    message,
  };
}
