import * as types from './pickupActionTypes';
import axios from 'axios';
import { PARTNER_URL } from '../../../../environments';

export function GetPRQ(
	locationInUse,
	page = 0,
	searchTerm = '',
	dateRange = {},
	filterTerm = {},
	isFilter,
	dateRange1,
	defRange1
) {
	const pageNo = !searchTerm ? page : 0;
	const filter = isFilter ? { ...filterTerm } : '';
	const dateFilter = {
		dateRange: dateRange1,
		defRange1,
		filters: filter,
	};
	const locUse = locationInUse === -1 ? null : locationInUse;
	return function (dispatch) {
		dispatch(getPRQInitiation());

		axios
			.post(`${PARTNER_URL}/pickup/prq/get`, {
				locationIds: locUse === null ? null : [locUse],
				page: pageNo,
				searchTerm,
				...dateRange,
				...filter,
			})
			.then((res) => {
				if (
					res.data.status.success === true &&
					res.data.status.message === 'SUCCESS'
				) {
					dispatch(getPRQSuccess(res.data, dateFilter));
				}
			})
			.catch((err) => {
				dispatch(getPRQError(err.message));
			});
	};
}

export function getPRQInitiation() {
	return {
		type: types.GET_PRQ_INITIATED,
	};
}

export function getPRQSuccess(obj, res) {
	return {
		type: types.GET_PRQ_SUCCESS,
		obj,
		res,
	};
}
export function getPRQError(BookingError) {
	return {
		type: types.GET_PRQ_FAILURE,
		BookingError,
	};
}
export function getPRQTempClear() {
	return {
		type: types.GET_PRQ_TEMP_CLEAR,
	};
}

export function GetPRS(
	locationInUse,
	page = 0,
	searchTerm = '',
	dateRange = {},
	filterTerm = {},
	isFilter,
	dateRange1,
	defRange1
) {
	const pageNo = !searchTerm ? page : 0;
	const filter = isFilter ? { ...filterTerm } : '';
	const dateFilter = {
		dateRange: dateRange1,
		defRange1,
		filters: filter,
	};
	const locUse = locationInUse === -1 ? null : locationInUse;
	return function (dispatch) {
		dispatch(getPRSInitiation());

		axios
			.post(`${PARTNER_URL}/pickup/prs/get`, {
				locationIds: locUse === null ? null : [locUse],
				page: pageNo,
				searchTerm,
				...dateRange,
				...filter,
			})
			.then((res) => {
				if (
					res.data.status.success === true &&
					res.data.status.message === 'SUCCESS'
				) {
					dispatch(getPRSSuccess(res.data, dateFilter));
				}
			})
			.catch((err) => {
				dispatch(getPRSError(err.message));
			});
	};
}

export function getPRSInitiation() {
	return {
		type: types.GET_PRS_INITIATED,
	};
}

export function getPRSSuccess(obj, res) {
	return {
		type: types.GET_PRS_SUCCESS,
		obj,
		res,
	};
}
export function getPRSError(BookingError) {
	return {
		type: types.GET_PRS_FAILURE,
		BookingError,
	};
}
export function getPRSTempClear() {
	return {
		type: types.GET_PRS_TEMP_CLEAR,
	};
}
