import { client } from '../../api-client';
import { NETWORK_URL } from '../../../../environments';

function createPartner(payload) {
  const config = {
    baseURL: NETWORK_URL,
    body: payload,
  };

  return client('partners', config);
}

export { createPartner };
