import React from 'react';
import ReactDOM from 'react-dom';
import { GlobalStyles } from 'twin.macro';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import * as redux from './store/createStore';
import { App } from './app/app';
import * as serviceWorker from './serviceWorker';
import theme from './app/hoc/material-ui.theme';
import NetworkInterceptor from './services/network-interceptor.service';

import './index.scss';
import setupReduxService from './services/redux-state.service';

NetworkInterceptor.setupInterceptors(redux.store);
setupReduxService(redux.store);

ReactDOM.render(
  <Provider store={redux.store}>
    <PersistGate loading={null} persistor={redux.persistor}>
      <MuiThemeProvider theme={theme}>
        <GlobalStyles />
        <App />
      </MuiThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
