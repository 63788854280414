import styled from '@emotion/styled';
// import logoImage from '../../../assets-stage.addup.network/images/logo@2x.png';
import logoImage from '../../../assets-stage.addup.network/images/kyte_logo.svg';

const Logo = styled.div`
  background-image: url(${logoImage});
  width: 195px;
  height: 79px;
  margin-left: 10px;
  background-size: contain;
  background-repeat: no-repeat;
`;

export default Logo;
