import Axios from 'axios';
import { NETWORK_URL, IAM_URL } from '../environments';
import { responseErrorHander } from './network-interceptor.service';

class UserService {
  async get() {
    try {
      const response = await Axios.get(`${NETWORK_URL}/users?status=false`);
      return response.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }

  async getById(userId) {
    try {
      const response = await Axios.get(`${NETWORK_URL}/users/${userId}`);
      return response.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }

  async getUserByEmail(email) {
    try {
      const response = await Axios.get(
        `${NETWORK_URL}/users/email?email=${email}`,
      );
      return response.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }

  async getLoggedInUser() {
    try {
      const response = await Axios.get(`${NETWORK_URL}/users/loggedIn/`);
      return response.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }

  async create(values) {
    try {
      const response = await Axios.post(`${NETWORK_URL}/users`, values);
      return response.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }

  getUserDetailsByEmail = async (email) => {
    const response = await Axios.get(`${NETWORK_URL}/users/email/${email}`);
    return response.data;
  };

  getDRSUsers = async (locationIds) => {
    try {
      locationIds =
        locationIds === null || locationIds === -1 ? null : [locationIds];

      const result = await Axios.post(`${NETWORK_URL}/users/drsusers`, {
        locationIds: locationIds,
      });
      return result.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  };

  // getDRSUsers = async (locationIds) => {
  //   try {
  //     locationIds =
  //       locationIds === null || locationIds === -1 ? -1 : [locationIds];

  //     const result = await Axios.get(
  //       `${NETWORK_URL}/users/usersByRegion?locationId=${locationIds}`,
  //     );
  //     return result.data;
  //   } catch (e) {
  //     return responseErrorHander(e);
  //   }
  // };

  async validateEmail(emailId) {
    try {
      const response = await Axios.get(`${NETWORK_URL}/users/email/${emailId}`);
      return response.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }

  async validateMobileNumber(mobileNo) {
    try {
      const response = await Axios.get(
        `${IAM_URL}/iam_users/validateMobile?mobileNo=${mobileNo}`,
      );
      return response.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }

  async editUser(userInfo) {
    try {
      const result = await Axios.put(`${NETWORK_URL}/users`, userInfo);
      return result.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }

  async resetPassword(password) {
    try {
      const result = await Axios.put(`${IAM_URL}/iam_users/reset`, {
        password,
      });
      return result.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }

  async inactivateUser(iamUserId) {
    try {
      const result = await Axios.put(`${IAM_URL}/iam_users/inactivate`, [
        iamUserId,
      ]);
      return result.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }
}

export default new UserService();
