export const EDIT_BOOKING_INITIATED = 'EDIT_BOOKING_INITIATED';
export const EDIT_BOOKING_SUCCESS = 'EDIT_BOOKING_SUCCESS';
export const EDIT_BOOKING_FAILURE = 'EDIT_BOOKING_FAILURE';
export const GET_BOOKING_INITIATED = 'GET_BOOKING_INITIATED';
export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS';
export const GET_BOOKING_FAILURE = 'GET_BOOKING_FAILURE';
export const GET_BOOKING_TEMP_CLEAR = 'GET_BOOKING_TEMP_CLEAR';
export const GET_BOOKING_LIST_INITIATED = 'GET_BOOKING_LIST_INITIATED';
export const GET_BOOKING_LIST_SUCCESS = 'GET_BOOKING_LIST_SUCCESS';
export const GET_BOOKING_LIST_FAILURE = 'GET_BOOKING_LIST_FAILURE';
export const GET_BOOKING_LIST_TEMP_CLEAR = 'GET_BOOKING_LIST_TEMP_CLEAR';
