import {
  FETCH_CONTROL_TOWER_REQUEST,
  FETCH_CONTROL_TOWER_SUCCESS,
  FETCH_CONTROL_TOWER_FAILURE,
} from '../types/controlTower.types';

const initialState = {
  loading: null,
  data: {},
  error: '',
};

const controlTowerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTROL_TOWER_REQUEST:
      return {
        loading: true,
      };
    case FETCH_CONTROL_TOWER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_CONTROL_TOWER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default controlTowerReducer;
