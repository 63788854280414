import Axios from 'axios';
import { responseErrorHander } from './network-interceptor.service';
import { IAM_URL } from '../environments';

class RoleService {
  async get() {
    try {
      const response = await Axios.get(`${IAM_URL}/roles`);
      return response.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }

  async create(values) {
    try {
      const response = await Axios.post(`${IAM_URL}/roles`, values);
      return response.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }

  async getRoleByName(name) {
    try {
      const response = await Axios.get(`${IAM_URL}/roles/name/${name}`);
      return response.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }

  async getRoleModules(id) {
    try {
      const response = await Axios.get(`${IAM_URL}/roles/${id}/modules`);
      return response.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }

  async getById(id) {
    try {
      const response = await Axios.get(
        `${IAM_URL}/roles/${id}`
      );
      return response.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }

  async updateStatus(roleId, status){
    try {
      const response = await Axios.put(
        `${IAM_URL}/roles/updateStatus?roleId=${roleId}&status=${status}`
      );
      return response.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }
  
  async editRole(values) {
    try {
      const response = await Axios.put(
        `${IAM_URL}/roles`,
        values
      );
      return response.data;
    } catch (e) {
      return responseErrorHander(e);
    }
  }
}

export default new RoleService();
