export const getQueryStringParams = (query, convertNumberToInt = false) => (query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        let [key, value] = param.split('=');

        value = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        params[key] = parseInt(value, 10) && convertNumberToInt ? parseInt(value, 10) : value;
        return params;
      }, {})
    : {});

export const createQueryString = (searchObj = {}) => {
  let result = '?';

  Object.keys(searchObj).forEach((key) => {
    if (searchObj[key] !== null && searchObj[key] !== undefined) {
      if (Array.isArray(searchObj[key])) {
        if (searchObj[key].length) {
          result += `${key}=${encodeURIComponent(searchObj[key])}&`;
        }
      } else {
        result += `${key}=${encodeURIComponent(searchObj[key])}&`;
      }
    }
  });
  return result.slice(0, -1);
};

export const isValidEmail = (email) => {
  const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (pattern.test(email)){
    return true;
  }
  return false;
}
