import axios from 'axios';
import { NETWORK_URL, IAM_URL } from '../environments';
import { createQueryString } from '../utils/utility-functions';

export const login = async (values) => {
  const query = createQueryString({ ...values, grant_type: 'password' });

  return axios
    .post(
      `${NETWORK_URL}/oauth/token${query}`,
      {},
      {
        'Content-Type': 'application/x-www-form-urlencoded',
        auth: {
          username: 'cubicom_erp',
          password: 'password_erp',
        },
      },
    )
    .then((response) => response.data)
    .catch((error) => error.data);
};

export const getOtp = async (values) => {
  const query = createQueryString({ ...values });
  return axios
    .get(`${NETWORK_URL}/generate_otp${query}`)
    .then((response) => response.data)
    .catch((error) => error.data);
};

export const forgotPassword = async (email) =>
  axios
    .post(`${IAM_URL}/iam_users/forgot`, { email })
    .then((response) => response.data);
