import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, Button, Paper } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import styles from './track-my-shipment.module.scss';
import DataTable from '../../common/components/data-table/data-table';
import { getWeybillDetails, getLatLngUpdate, getETA } from '../../../services/tracking.service';
import {
  showSnackBar,
  hideSnackBar,
} from '../../../store/common/actions/common.actions';
import Loader from '../../common/components/loader/loader.component';
import CustomizedSnackbars from '../../common/components/snack-bar/snack-bar.component';
import 'react-step-progress-bar/styles.css';
import Map from './map/Map';
import { ProgressBar, Step } from 'react-step-progress-bar';
import 'twin.macro';
const vr = require('../../../assets-stage.addup.network/images/edgistify.png');

class TrackMyShipment extends PureComponent {
  constructor(props) {
    super(props);
    this.refreshLatLng = this.refreshLatLng.bind(this);
    this.state = {
      search: '',
      client_key: 15941939809,
      isDataFetched: false,
      latLngResponse: null,
      destinationCoordinates: {},
      consignmentStatus: null,
      ETA : "NA",
      destinationLocation : "",
      eventStatus: [],
      percent: 0,
    };
    this.columns = [
      {
        title: 'DATE',
        key: 'eventDate',
      },
      {
        title: 'TIME',
        key: 'eventTime',
      },
      {
        title: 'EVENT',
        key: 'event',
      },
      {
        title: 'LOCATION',
        key: 'location',
      },
    ];
    document.title = 'Kyte |   Track My Shipment';
  }

  componentDidMount() {
    const {
      match: {
        params: { client_key },
      },
    } = this.props;
    this.getWaybillDetails(client_key);
    
  }
  refreshLatLng = async () => {
    const { client_key } = this.state;
    const { drsId } = this.state;
    
    
    const result = await getLatLngUpdate(client_key,drsId);
    const { status = {}, response = {} } = result;
    if(response?.lat != null){
    const { lat, lng} = response;
    // Testing
    // const lat = 12.971599 ;
    // const lng = 77.594566;
    this.setState({latLngResponse:{lat: lat, lng: lng}})
    this.fetchETA()
    }
 
  }
  fetchETA = async () => {
    const {latLngResponse, destinationCoordinates} = this.state;
    const response = await getETA(latLngResponse, destinationCoordinates);
    this.setState({ETA: response.response})
  }
  refreshLocation = async () => {
    const {consignmentStatus} = this.state;
    this.refreshLatLng();
    if(consignmentStatus.event == "Out For Delivery" || consignmentStatus.event == "OFD" ){
      this.interval = setInterval(async () => {
      
        if (consignmentStatus.event === "Delivered" || consignmentStatus.event === null) {
             
          clearInterval(this.interval);
      } else {
          this.refreshLatLng();
          
          
      }
      }, 300000);
    }
  }

  getPercent = (status) => {
    let percent = 0;
    switch (status) {
      case 'Delivered':
      case 'Undelivered':
        percent = 100;
        return percent;
      case 'Out For Delivery':
        percent = 75;
        return percent;
      case 'In Transit':
      case 'In scan':
      case 'Out scan':
        percent = 50;
        return percent;
      case 'Dispatched':
      case 'Picked Up':
      case 'PICKUP COMPLETED':
        percent = 25;
        return percent;
      case 'Booked':
        percent = 10;
        return percent;
      default:
        return null;
    }
  };
  getDestinationCoordinates = async (destinationLocation) => {
    try {
      await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${destinationLocation}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`)
                    .then((res) => res.json())
                    .then( async (data) => {
                     
                      // setOriginLatitude(data?.results[0].geometry.location.lat);
                      // setOriginLongitude(data?.results[0].geometry.location.lng)
                    
                      this.setState({ destinationCoordinates: data?.results[0]?.geometry?.location });
                    })
      

    } catch (error) {
      return Promise.reject(error);
    }
  }
  getETA = async (destinationLocation,currentLocation) => {
    try {
      await fetch(
        `https://maps.googleapis.com/maps/api/directions/json?origin=${currentLocation.lat},${currentLocation.lng}&destination=${destinationLocation.lat},${destinationLocation.lng}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`)
                    .then((res) => res.json())
                    .then( async (data) => {
                      this.setState({ ETA : data?.routes[0]?.legs[0]?.duration.text });
                        
                    })
      

    } catch (error) {
      return Promise.reject(error);
    }
  }

  getWaybillDetails = async (search) => {
    const { client_key } = this.state;
    const { action__showSnackBar } = this.props;
    try {
      const result = await getWeybillDetails(client_key, search);
      const { status = {}, response = {} } = result;
      const {
        waybillNo = '',
        trackingEventResponse,
        latLngResponseList = [],
        consigneeName = '',
        consigneeAddress = '',
        consigneeContactNo = '',
        sourceLocation = '',
        destinationLocation = '',
        franchiseeName = '',
        podImgUrl,
        drsId,
        sigImgUrl,
        orderRefNo,
        expectedDelDate,
        bookingDate,
        receivedBy,
      } = response;
      if(destinationLocation){
        this.setState({consignmentStatus: trackingEventResponse.eventLog[0]})
        this.setState({destinationLocation: destinationLocation})
        this.setState({drsId:drsId})
        await this.getDestinationCoordinates(destinationLocation);
        await this.refreshLocation();        
      }
      
      if (!trackingEventResponse) {
        action__showSnackBar({
          variant: 'error',
          message: status.message || 'Something went wrong!',
        });
        this.setState({ isDataFetched: false });
      } else {
        const {
          paginator: { pageNo = 0, pageSize = 0, totalPages = 0 },
        } = trackingEventResponse || {};
        let { eventLog = [] } = trackingEventResponse || {};
        let eventStatus = [];
        trackingEventResponse.eventLog.map((status) => {
          return eventStatus.push(status.event);
        });
        let percent = this.getPercent(eventStatus[0]);
        this.setState({
          eventStatus,
          percent,
        });
        eventLog = eventLog.map((item) => {
          const trackTime = item.eventDate
            ? moment(item.eventDate).format('HH:mm')
            : '';
          item.eventDate = item.eventDate
            ? moment(item.eventDate).format('DD-MM-YYYY')
            : '';
          item.eventTime = trackTime;
          return item;
        });
        const details = {
          trackingId: waybillNo || '-',
          orderId: orderRefNo || '-',
          cpd: expectedDelDate || '-',
          bookedOn: moment(bookingDate).format('DD-MM-YYYY') || '-',
          recieverName: receivedBy || '-',
          podImgUrl: podImgUrl ? (
            <a href={podImgUrl} target="_blank">
              Click here
            </a>
          ) : (
            'NA'
          ),
          sigImgUrl: sigImgUrl ? (
            <a href={sigImgUrl} target="_blank">
              Click here
            </a>
          ) : (
            'NA'
          ),
        };
        const data = {
          waybillNo: waybillNo || '-',
          consigneeName: consigneeName || '-',
          consigneeAddress: consigneeAddress || '-',
          consigneeContactNo: consigneeContactNo || '-',
          sourceLocation: sourceLocation || '-',
          destinationLocation: destinationLocation || '-',
          franchiseeName: franchiseeName || '-',
          podImgUrl: podImgUrl ? (
            <a href={podImgUrl} target="_blank">
              Click here
            </a>
          ) : (
            'NA'
          ),
          sigImgUrl: sigImgUrl ? (
            <a href={sigImgUrl} target="_blank">
              Click here
            </a>
          ) : (
            'NA'
          ),
          eventLog,
        };

        const meta = {
          page: pageNo + 1, // in API response pageNo starts from 0
          size: pageSize,
          totalPages,
          totalRecords:
            totalPages > 1
              ? pageSize * totalPages
              : eventLog
              ? eventLog.length
              : 0,
        };
        this.setState({ ...data, meta, isDataFetched: true, latLngResponseList: latLngResponseList, ...details });
      }
    } catch (e) {
      if (e.data && e.data.errorMessage) {
        action__showSnackBar({
          variant: 'error',
          message: e.data.errorMessage,
        });
      } else {
        action__showSnackBar({
          variant: 'error',
          message: 'Network Error',
        });
      }
    }
  };
  
  onSearch = (waybillNoList) => {
    if (waybillNoList) {
      this.getWaybillDetails(waybillNoList);
      this.setState({
        search: '',
      });
    }
  };

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.triggerSearch();
    }
  };

  triggerSearch = () => {
    const { search } = this.state;
    this.onSearch(search);
  };

  handleChange = (current) => {
    this.setState({ current });
  };

  handleTrack = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  render() {
    // const {
    //   match: {
    //     // params: { waybillNo },
    //   },
    // } = this.props;
    const {
      meta,
      podImgUrl,
      sigImgUrl,
      eventLog = [],
      search,
      isDataFetched,
      trackingId,
      orderId,
      cpd,
      bookedOn,
      recieverName,
      ETA,
      destinationLocation,
      destinationCoordinates,
      latLngResponse
    } = this.state;
   
    const {
      action__hideSnackBar,
      common: { loader, snackbar = { show: false } },
    } = this.props;
    if(!destinationCoordinates?.lat){

      return (
        null
      )
    }
    else{
    return (
      
      <div className={styles.container}>
        <div className={styles.content}>
          <img className={styles.vrLogo} src={vr} alt="vr1" />
          <div className={styles.searchBar}>
            <div className={styles.heading}>
              <span>Track Shipment</span>
              {/* <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={this.refreshLocation}
                          className={styles.stickyAction}
                        >
                          REFRESH
              </Button> */}
            </div>
           
            <div className={styles.searchContainer}>
              <div className={styles.search}>
                {/* <TextField
                  fullWidth
                  variant="outlined"
                
                  //value={waybillNo && !search ? waybillNo : search}
                  value={search}
                  onKeyDown={this._handleKeyDown}
                  onChange={(e) => this.handleTrack(e)}
                  className={styles.searchInput}
                      
                 
                /> */}
                <InputAdornment position="end">
                        {/* <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={this.triggerSearch}
                          className={styles.stickyAction}
                          style={{marginRight:'10px'}}
                        >
                          TRACK
                        </Button> */}
                        {/* <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={this.refreshLocation}
                          className={styles.stickyAction}
                        >
                          REFRESH
                        </Button> */}
                </InputAdornment>
              </div>
            </div>
          </div>
          
          {/* <Map
              // isMarkerShown={true}
              // isDirectionShown={true}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `calc(100% )` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              // fePod={latLngResponseList}
              // data={[]}
              // // selectedDeliveryBoyIndex={0}
              destinationCoordinates={destinationCoordinates}
              currentCoordinates={latLngResponseList != null ? latLngResponseList[latLngResponseList.length -1] : null}
          /> */}
        
      
          
          
      
            { 
              latLngResponse != null ?
              <div  className={styles.mapContainer}>
          <Map
              // isMarkerShown={true}
              // isDirectionShown={true}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `calc(100% )` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              // fePod={latLngResponseList}
              // data={[]}
              // // selectedDeliveryBoyIndex={0}
              destinationCoordinates={destinationCoordinates}
              currentCoordinates={latLngResponse != null ? latLngResponse : null}
          />
          </div>
          :
          <div  className={styles.mapContainer1}>
          <div>
          <p>Live Tracking is Currently Unavailable</p>
          </div>
          </div>
        }
          {isDataFetched && (
            <span>
              <Paper className={styles.paperBox}>
                <div
                  className={styles.orderDetails}
                 
                >
                  <div>
                    <div className={styles.items}>
                      <p
                        className={styles.trackingInfoHeading}
                        style={{ width: '200px' }}
                      >
                        Destination Address:
                      </p>
                      &nbsp;
                      <p className={styles.trackingInfoValue}>{destinationLocation}</p>
                    </div>
                    <div className={styles.items}>
                      <p
                        className={styles.trackingInfoHeading}
                        style={{ width: '200px' }}
                      >
                        Estimated Time of Arrival :
                      </p>
                      &nbsp;
                      <p className={styles.trackingInfoValue}>{ETA}</p>
                    </div>
                  </div>
                </div>
              </Paper>
              <Paper className={styles.paperBox}>
                <div
                  className={styles.orderDetails}
                 
                >
                  <div>
                    <div className={styles.items}>
                      <p
                        className={styles.trackingInfoHeading}
                        style={{ width: '100px' }}
                      >
                        Courier:
                      </p>
                      &nbsp;
                      <p className={styles.trackingInfoValue}>VR1</p>
                    </div>
                    <div className={styles.items}>
                      <p
                        className={styles.trackingInfoHeading}
                        style={{ width: '100px' }}
                      >
                        Reciever Name:
                      </p>
                      &nbsp;
                      <p className={styles.trackingInfoValue}>{recieverName}</p>
                    </div>
                  </div>
                  <div>
                    <div className={styles.items}>
                      <p
                        className={styles.trackingInfoHeading}
                        style={{ width: '74px' }}
                      >
                        Tracking ID:
                      </p>
                      &nbsp;
                      <p className={styles.trackingInfoValue}>{trackingId}</p>
                    </div>
                    <div className={styles.items}>
                      <p
                        className={styles.trackingInfoHeading}
                        style={{ width: '74px' }}
                      >
                        Order ID:
                      </p>
                      &nbsp;
                      <p className={styles.trackingInfoValue}>{orderId}</p>
                    </div>
                  </div>
                  <div>
                    <div className={styles.items}>
                      <p
                        className={styles.trackingInfoHeading}
                        style={{ width: '75px' }}
                      >
                        Booked On:
                      </p>
                      &nbsp;
                      <p className={styles.trackingInfoValue}>{bookedOn}</p>
                    </div>
                    <div className={styles.items}>
                      <p
                        className={styles.trackingInfoHeading}
                        style={{ width: '75px' }}
                      >
                        CPD:
                      </p>
                      &nbsp;
                      <p className={styles.trackingInfoValue}>{cpd}</p>
                    </div>
                  </div>
                  <div>
                    <div className={styles.items}>
                      <p
                        className={styles.trackingInfoHeading}
                        style={{ width: '75px' }}
                      >
                        Sig Image:
                      </p>
                      &nbsp;
                      <p className={styles.trackingInfoValue}>{sigImgUrl}</p>
                    </div>
                    <div className={styles.items}>
                      <p
                        className={styles.trackingInfoHeading}
                        style={{ width: '75px' }}
                      >
                        POD Image:
                      </p>
                      &nbsp;
                      <p className={styles.trackingInfoValue}>{podImgUrl}</p>
                    </div>
                  </div>
                </div>
              </Paper>

              <div style={{ marginTop: '20px' }}>
                <Paper className={styles.paperBox}>
                  <div className={styles.trackStatus}>
                    <p className={styles.trackStatusText}>Booked</p>
                    <p className={styles.trackStatusText}>Dispatched</p>
                    <p className={styles.trackStatusText}>In Transit</p>
                    <p className={styles.trackStatusText}>Out For Delivery</p>
                    <p className={styles.trackStatusText}>
                      {this.state.eventStatus
                        ? this.state.eventStatus[0] === 'UNDEL'
                          ? 'Undelivered'
                          : 'Delivered'
                        : 'Delivered'}
                    </p>
                  </div>
                  <div className={styles.progressContainer}>
                    <ProgressBar
                      percent={this.state.percent}
                      filledBackground="#36B964"
                      height={5}
                    >
                      <Step transition="scale">
                        {({ accomplished }) => (
                          <div
                            className={` ${
                              accomplished ? styles.colored : styles.uncolored
                            }`}
                          ></div>
                        )}
                      </Step>
                      <Step transition="scale">
                        {({ accomplished }) => (
                          <div
                            className={`${
                              accomplished ? styles.colored : styles.uncolored
                            }`}
                          ></div>
                        )}
                      </Step>
                      <Step transition="scale">
                        {({ accomplished }) => (
                          <div
                            className={`${
                              accomplished ? styles.colored : styles.uncolored
                            }`}
                          ></div>
                        )}
                      </Step>
                      <Step transition="scale">
                        {({ accomplished }) => (
                          <div
                            className={`${
                              accomplished ? styles.colored : styles.uncolored
                            }`}
                          ></div>
                        )}
                      </Step>
                      <Step transition="scale">
                        {({ accomplished }) => (
                          <div
                            className={`${
                              accomplished ? styles.colored : styles.uncolored
                            }`}
                          ></div>
                        )}
                      </Step>
                    </ProgressBar>
                  </div>
                </Paper>
              </div>       
              <div className={styles.detailsContainer}>
                <div className={styles.paper}>
                  <div className={styles.trackingEvent}>
                    <p className={styles.caption}>Tracking Event</p>
                    <div className={styles}>
                      <DataTable
                        autoPaginate
                        rows={eventLog}
                        columns={this.columns}
                        meta={meta}
                        handlePaginationChange={(page) =>
                          this.getRecords(page - 1)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </span>
          )}
        </div>
        {loader ? <Loader /> : null}
        <CustomizedSnackbars
          message={snackbar.message || ''}
          variant={snackbar.variant || 'success'}
          show={snackbar.show}
          onClose={action__hideSnackBar}
        />
      </div>
    );
  }
  }
}

const mapStateToProps = (state) => ({
  common: { ...state.common },
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      action__showSnackBar: showSnackBar,
      action__hideSnackBar: hideSnackBar,
    },
    dispatch,
  );

TrackMyShipment.propTypes = {
  action__showSnackBar: PropTypes.func,
  action__hideSnackBar: PropTypes.func,
};

TrackMyShipment.defaultProps = {
  action__showSnackBar: Function,
  action__hideSnackBar: Function,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackMyShipment);
