import React from 'react';
import { InfoWindow } from 'react-google-maps';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import 'twin.macro';

import { convertToLocalTimeStamp } from '../../../common/utils/DateFormatter';

const WayPointsInfoWindow = ({ infoWindowData, setIndex }) => {
  return (
    <InfoWindow
      position={{ lat: infoWindowData.lat, lng: infoWindowData.lng }}
      onCloseClick={() => {
        setIndex(-1);
      }}
    >
      {infoWindowData.entityType === 'PICKUP' ? (
        <div tw="grid grid-cols-1 gap-2">
          <div tw="flex justify-between items-center">
            <div tw="font-bold mr-2">{infoWindowData.prqNo}</div>
            <div tw="flex items-center justify-center">
              <div tw="bg-yellow-500 rounded-full font-bold py-1 px-4 flex-shrink-0">
                {infoWindowData.paymentType}
              </div>
            </div>
          </div>
          <div tw="flex justify-between items-center">
            <div tw="font-bold  mr-2">{infoWindowData.consigneeName}</div>
            {/* <div tw="text-gray-600 font-bold">
            {infoWindowData.amount}
          </div> */}
          </div>
          <div tw="text-gray-600">
            Consignment Status: {infoWindowData.consignmentStatus}
          </div>
          <div tw="text-gray-600">
            {new Date(infoWindowData.eventDate).toUTCString()}
          </div>
        </div>
      ) : (
        <div tw="grid grid-cols-1 gap-2">
          <div tw="flex justify-between items-center">
            <div tw="font-bold mr-2">{infoWindowData.waybillNo}</div>
            <div tw="flex items-center justify-center">
              <div tw="bg-yellow-500 rounded-full font-bold py-1 px-4 flex-shrink-0">
                {infoWindowData.paymentType}
              </div>
            </div>
          </div>
          <div tw="flex justify-between items-center">
            <div tw="font-bold  mr-2">{infoWindowData.consigneeName}</div>
            <div tw="text-gray-600 font-bold">{infoWindowData.amount}</div>
          </div>
          {infoWindowData.consignmentStatus === 'DEL' ? (
            <div tw="text-gray-600">Delivered</div>
          ) : (
            <div tw="text-gray-600">UnDelivered: {infoWindowData.reason}</div>
          )}
          <div tw="text-gray-600">
            {convertToLocalTimeStamp(infoWindowData.eventDate)}
          </div>
        </div>
      )}
    </InfoWindow>
  );
};

export default WayPointsInfoWindow;
