import * as React from 'react';

import StyledTextFiled from '../styled-components/TextFiled';
import StyledError from '../styled-components/Error';
import { useController } from 'react-hook-form';

function MobileNumber({
  id = '',
  name = '',
  control,
  defaultValue = '',
  error,
  ...props
}) {
  const {
    field: { onChange, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const pattern = /^[0-9\b]+$/g;

    if (value === '' || pattern.test(value)) {
      onChange(value);
    }
  };

  return (
    <>
      <StyledTextFiled
        id={id}
        onChange={handleChange}
        error={!!error}
        {...props}
        {...inputProps}
      />
      {error ? <StyledError>{error}</StyledError> : null}
    </>
  );
}

export default React.forwardRef(MobileNumber);
