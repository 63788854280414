import { client } from '../../api-client';
import { IAM_URL } from '../../../../environments';

function validateMobileNumber(mobileNumber) {
  const config = {
    baseURL: IAM_URL,
  };

  return client(`iam_users/validateMobile?mobileNo=${mobileNumber}`, config);
}

function validateEmailAddress(emailAddress) {
  const config = {
    baseURL: IAM_URL,
  };

  return client(`iam_users/validateEmail?email=${emailAddress}`, config);
}

export { validateMobileNumber, validateEmailAddress };
