import * as types from './bookingActionType';

const initialState = {
	getBookingDetails: {},
	getBookingError: {},
	getBookingListDetails: {},
	tempBookingList: {},
	tempDateRange: {},
};
export default function getBookingDetails(state = initialState, actions) {
	switch (actions.type) {
		case types.GET_BOOKING_INITIATED:
			return {
				...state,
				IsBookingDetailsLoading: true,
				IsBookingDetailsError: false,
				IsBookingDetailsSuccess: false,
			};

		case types.GET_BOOKING_SUCCESS:
			return {
				...state,
				IsBookingDetailsLoading: false,
				IsBookingDetailsError: false,
				IsBookingDetailsSuccess: true,
				getBookingDetails: actions.obj,
			};

		case types.GET_BOOKING_FAILURE:
			return {
				...state,
				IsBookingDetailsLoading: false,
				IsBookingDetailsError: true,
				IsBookingDetailsSuccess: false,
				getBookingDetailsError: actions.BookingError,
			};
		case types.GET_BOOKING_TEMP_CLEAR:
			return {
				...state,
				getBookingDetails: {},
			};
		case types.GET_BOOKING_LIST_INITIATED:
			return {
				...state,
				IsBookingListDetailsLoading: true,
				IsBookingListDetailsError: false,
				IsBookingListDetailsSuccess: false,
			};

		case types.GET_BOOKING_LIST_SUCCESS:
			return {
				...state,
				IsBookingListDetailsLoading: false,
				IsBookingListDetailsError: false,
				IsBookingListDetailsSuccess: true,
				getBookingListDetails: actions.obj,
				tempBookingList: actions.obj,
				tempDateRange: actions.res,
			};

		case types.GET_BOOKING_LIST_FAILURE:
			return {
				...state,
				IsBookingListDetailsLoading: false,
				IsBookingListDetailsError: true,
				IsBookingListDetailsSuccess: false,
				getBookingListDetailsError: actions.BookingError,
			};
		case types.GET_BOOKING_LIST_TEMP_CLEAR:
			return {
				...state,
				tempBookingList: {},
				tempDateRange: {},
			};
		default:
			return state;
	}
}
