import * as React from 'react';
import { useController } from 'react-hook-form';

import StyledNativeSelect from '../styled-components/NativeSelectField';
import StyledError from '../styled-components/Error';

function NativeSelectField({
  id = '',
  name = '',
  control,
  defaultValue = '',
  error,
  ...props
}) {
  const {
    field: { ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <>
      <StyledNativeSelect id={id} {...inputProps} {...props} />
      {error ? <StyledError>{error}</StyledError> : null}
    </>
  );
}

export default React.forwardRef(NativeSelectField);
