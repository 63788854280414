import React from 'react';
import { Route } from 'react-router-dom';
import NotFound from '../not-found/not-found.component';

const GuardedRoute = ({
  component: Component,
  canActivate,
  redirectTo,
  ...rest
}) => (
  <Route
    {...rest}
    render={
      (props) =>
        !canActivate || canActivate() === true ? <Component {...props} /> : null
      // <Route component={NotFound} />
      // <Redirect to={redirectTo} />
    }
  />
);

export default GuardedRoute;
