export const GET_INVENTORY_INITIATED = 'GET_INVENTORY_INITIATED';
export const GET_INVENTORY_SUCCESS = 'GET_INVENTORY_SUCCESS';
export const GET_INVENTORY_FAILURE = 'GET_INVENTORY_FAILURE';
export const GET_INVENTORY_TEMP_CLEAR = 'GET_INVENTORY_TEMP_CLEAR';

export const GET_INVENTORY_SCAN_INITIATED = 'GET_INVENTORY_SCAN_INITIATED';
export const GET_INVENTORY_SCAN_SUCCESS = 'GET_INVENTORY_SCAN_SUCCESS';
export const GET_INVENTORY_SCAN_WITHDATA_SUCCESS =
	'GET_INVENTORY_SCAN_WITHDATA_SUCCESS';
export const GET_INVENTORY_SCAN_FAILURE = 'GET_INVENTORY_SCAN_FAILURE';
