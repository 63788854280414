import * as types from './handoverActionTypes';
import axios from 'axios';
import { PARTNER_URL } from '../../../../environments';

export function GetHandoverList(
  page = 0,
  searchFilters = {},
  dateRange,
  filterTerm = {},
  isFilter,
  defRange1,
  filters,
) {
  const filter = isFilter ? { ...filterTerm } : '';
  const dateFilter = {
    dateRange: filters,
    filters: filter,
    defRange1,
  };

  return function (dispatch) {
    dispatch(getHandoverInitiation());

    axios
      .post(`${PARTNER_URL}/handover/get`, {
        page,
        ...searchFilters,
        ...dateRange,
        ...filter,
      })
      .then((res) => {
        if (
          res.data.status.success === true &&
          res.data.status.message === 'SUCCESS'
        ) {
          dispatch(getHandoverSuccess(res.data, dateFilter));
        }
      })
      .catch((err) => {
        dispatch(getHandoverError(err.message));
      });
  };
}

export function getHandoverInitiation() {
  return {
    type: types.GET_HANDOVER_INITIATED,
  };
}

export function getHandoverSuccess(obj, res) {
  return {
    type: types.GET_HANDOVER_SUCCESS,
    obj,
    res,
  };
}
export function getHandoverError(BookingError) {
  return {
    type: types.GET_HANDOVER_FAILURE,
    BookingError,
  };
}
export function getHandoverTempClear() {
  return {
    type: types.GET_HANDOVER_TEMP_CLEAR,
  };
}
