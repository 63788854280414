import * as types from './pickupActionTypes';

const initialState = {
	getPRSDetails: {},
	getPRQDetails: {},
	tempPRSDateFilter: {},
	tempPRQDateFilter: {},
};
export default function getHandoverDetails(state = initialState, actions) {
	switch (actions.type) {
		case types.GET_PRQ_INITIATED:
			return {
				...state,
				IsPRQLoading: true,
				IsPRQError: false,
				IsPRQSuccess: false,
			};

		case types.GET_PRQ_SUCCESS:
			return {
				...state,
				IsPRQLoading: false,
				IsPRQError: false,
				IsPRQSuccess: true,
				getPRQDetails: actions.obj,
				tempPRQDateFilter: actions.res,
			};

		case types.GET_PRQ_FAILURE:
			return {
				...state,
				IsPRQLoading: false,
				IsPRQError: true,
				IsPRQSuccess: false,
			};
		case types.GET_PRQ_TEMP_CLEAR:
			return {
				...state,
				getPRQDetails: {},
				tempPRQDateFilter: {},
			};
		case types.GET_PRS_INITIATED:
			return {
				...state,
				IsPRSLoading: true,
				IsPRSError: false,
				IsPRSSuccess: false,
			};

		case types.GET_PRS_SUCCESS:
			return {
				...state,
				IsPRSLoading: false,
				IsPRSError: false,
				IsPRSSuccess: true,
				getPRSDetails: actions.obj,
				tempPRSDateFilter: actions.res,
			};

		case types.GET_PRS_FAILURE:
			return {
				...state,
				IsPRSLoading: false,
				IsPRSError: true,
				IsPRSSuccess: false,
			};
		case types.GET_PRS_TEMP_CLEAR:
			return {
				...state,
				getPRSDetails: {},
				tempPRSDateFilter: {},
			};
		default:
			return state;
	}
}
