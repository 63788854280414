import React from 'react';
import { InputBase, TextField } from '@material-ui/core';
import BootstrapInput from './bootstrap-input';

const PatternInput = (props) => {
  const {
    inputformat,
    validatepattern,
    replacepattern,
    maxallowedchars,
  } = props;

  const validate = (event) => {
    let key = event.key;
    if (!validatepattern.test(key)) {
      if (
        maxallowedchars &&
        event.target.value.toString().length >= maxallowedchars
      ) {
        event.target.value = event.target.value.substring(0, maxallowedchars);
        event.preventDefault();
        return false;
      } else {
        return key;
      }
    }
    event.preventDefault();
    return false;
  };

  const validatePaste = (event) => {
    if (!validatepattern.test(event.target.value)) {
      return true;
    }
    event.target.value = event.target.value.replace(replacepattern, '');
    if (
      maxallowedchars &&
      event.target.value.toString().length >= maxallowedchars
    ) {
      event.target.value = event.target.value.substring(0, maxallowedchars);
    }
    event.preventDefault();
    return false;
  };

  if (inputformat === 'BootstrapInput') {
    return (
      <BootstrapInput
        {...props}
        onKeyPressCapture={validate}
        onChangeCapture={validatePaste}
      />
    );
  } else if (inputformat === 'TextField') {
    return (
      <TextField
        {...props}
        onKeyPressCapture={validate}
        onChangeCapture={validatePaste}
      />
    );
  } else if (inputformat === 'input') {
    return (
      <input
        {...props}
        onKeyPressCapture={validate}
        onChangeCapture={validatePaste}
      />
    );
  } else {
    return (
      <InputBase
        {...props}
        onKeyPressCapture={validate}
        onChangeCapture={validatePaste}
      />
    );
  }
};

export default PatternInput;
