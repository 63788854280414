import { combineReducers } from 'redux';
import CommonReducer from './common/reducers/common.reducers';
import DoHandoverReducer from '../app/routes/operations/handover/do-handover/redux/reducer/do-handover.reducer';
import LoginReducer from './common/reducers/login.reducer';
import BookingDetailsReducer from '../app/routes/operations/bookings/bookingReducer';
import InventoryReducer from '../app/routes/operations/inventory/inventory-list/inventory-reducers';
import InventoryScanReducer from '../app/routes/operations/inventory/inventory-list/inventory-scan-reducer';
import HandoverReducer from '../app/routes/operations/handover-new/handoverReducer';
import PickupReducer from '../app/routes/operations/pickup/pickupReduces';
import controlTowerReducer from './common/reducers/controlTower.reducer';
import fePerformanceReducer from './common/reducers/fePerformance.reducer';
import fePodReducer from './common/reducers/fePod.reducer';

const rootReducers = combineReducers({
  common: CommonReducer,
  login: LoginReducer,
  doHandover: DoHandoverReducer,
  bookingDetails: BookingDetailsReducer,
  Inventory: InventoryReducer,
  InventoryScan: InventoryScanReducer,
  HandoverDetails: HandoverReducer,
  PickupDetails: PickupReducer,
  controlTower: controlTowerReducer,
  fePerformance: fePerformanceReducer,
  fePod: fePodReducer,
});

export default rootReducers;
