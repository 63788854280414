import { handle } from 'redux-pack';

export default function LoginReducer(
  state = { loader: false, locations: [], permissions: [] },
  action,
) {
  switch (action.type) {
    case 'GET_LOCATIONS':
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          loader: true,
        }),
        finish: (prevState) => ({
          ...prevState,
          loader: false,
        }),
        failure: (prevState) => ({
          ...prevState,
          loader: false,
          locations: [],
        }),
        success: (prevState) => ({
          ...prevState,
          loader: false,
          locations: action.payload.locationResponse || [],
        }),
      });
    case 'SET_LOCATION':
      return {
        ...state,
        locationInUse: action.payload,
      };

    case 'SET_PERMISSIONS':
      return {
        ...state,
        permissions: action.payload,
      };

    case 'SET_AUTH':
      return {
        ...state,
        auth: action.payload,
      };

    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };

    case 'USER_ROLE': {
      return {
        ...state,
        userRole: action.payload,
      };
    }

    case 'USER_EMAIL': {
      return {
        ...state,
        userEmail: action.payload,
      };
    }

    case 'CLEAR_AUTH':
      return {
        ...state,
        auth: undefined,
        user: undefined,
        permissions: [],
      };

    default:
      return state;
  }
}
