import * as types from './inventory-actionTypes';

const initialState = {
	getInventoryDetails: {},
	getInventoryError: {},
	tempInventoryDetails: {},
	tempDateFilter: {},
};
export default function getInventory(state = initialState, actions) {
	switch (actions.type) {
		case types.GET_INVENTORY_INITIATED:
			return {
				...state,
				IsInventoryLoading: true,
				IsInventoryError: false,
				IsInventorySuccess: false,
			};
		case types.GET_INVENTORY_SUCCESS:
			return {
				...state,
				IsInventoryLoading: false,
				IsInventoryError: false,
				IsInventorySuccess: true,
				getInventoryDetails: actions.InventoryDetails,
				tempInventoryDetails: actions.InventoryDetails,
				tempDateFilter: actions.dateFilter,
			};

		case types.GET_INVENTORY_FAILURE:
			return {
				...state,
				IsInventoryLoading: false,
				IsInventoryError: true,
				IsInventorySuccess: false,
				getInventoryError: actions.InventoryError,
			};
		case types.GET_INVENTORY_TEMP_CLEAR:
			return {
				...state,
				getInventoryDetails: {},
				tempInventoryDetails: {},
				tempDateFilter: {},
			};
		default:
			return state;
	}
}
