export const CustomerIconOff = require('./customer.svg');
export const CustomerIconOn = require('./customer (2).svg');
export const LocationOff = require('./location.svg');
export const LocationOn = require('./location (2).svg');
export const PermissionIconOff = require('./permission.svg');
export const PermissionIconOn = require('./permission (2).svg');
export const ProductIconOff = require('./product.svg');
export const ProductIconOn = require('./product (2).svg');
export const RoleIconOff = require('./role.svg');
export const RoleIconOn = require('./role (2).svg');
export const UsersIconOff = require('./users.svg');
export const UsersIconOn = require('./users (2).svg');
export const BookingIcon = require('./icon_booking.svg');
export const OfdIcon = require('./icon_ofd.svg');
export const DelIcon = require('./icon_del.svg');
export const UndelIcon = require('./icon_undel.svg');
export const CollapseIcon = require('./collapse.svg');
export const FranchiseeIconOn = require('./franchisee-on.svg');
export const FranchiseeIconOff = require('./franchisee-off.svg');
export const AddIcon = require('./add.svg');
export const RemoveIcon = require('./remove.svg');
export const CloseIcon = require('./close.svg');
export const ManifestIcon = require('./manifest.svg');
export const PickupIcon = require('./pickup.svg');
export const CourierConnect = require('./courierconnect.svg');
export const TransporterIcon = require('./transporter.svg');
export const RouteMaster = require('./route.svg');
export const LineHaulTruck = require('./linehaulTruck.svg');
export const Trip = require('./trip.svg');
export const Vehicle = require('./vehicle.svg');
export const Driver = require('./driver.svg');
export const successTick = require('./successTick.svg');
export const BaggingIcon = require('./bagging.svg');
export const DebaggingIcon = require('./debagging.svg');
export const WaybillInventoryIcon = require('./waybill-inventory.svg');
export const CODIcon = require('./cod.svg');
export const CODRemittanceIcon = require('./cod-remittance.svg');
export const CODAuditIcon = require('./cod-audit.svg');
export const CustRemittanceIcon = require('./cust-remittance.svg');
