import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import 'twin.macro';

const Flyout = (props) => {
  const node = React.useRef(null);
  const handleLogout = () => {
    localStorage.removeItem('usr');
    localStorage.removeItem('usrn');
    localStorage.removeItem('access_token');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('scope');
    props.setLocationInUse();
    props.onLogout();
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  });

  const handleClick = (event) => {
    if (
      !node.current.contains(event.target) &&
      node.current.offsetHeight > 0 &&
      event.target.id !== 'user-symbol'
    ) {
      props.handleFlyout();
    }
  };

  return (
    <div
      ref={node}
      style={{ ...styles.wrapper, ...props.cstyle }}
      tw="flex flex-col items-stretch border divide-y shadow-md"
    >
      <div tw="p-2">Welcome {props.uname}</div>
      <Link
        tw="p-2 cursor-pointer"
        to={`/configurations/user/profile/${props.userId}`}
        onClick={() => {
          props.handleFlyout();
        }}
      >
        Profile
      </Link>
      <Link
        tw="p-2"
        to="/configurations/user/reset"
        onClick={() => {
          props.handleFlyout();
        }}
      >
        Reset Password
      </Link>
      <Link tw="p-2 cursor-pointer" to="#" onClick={handleLogout}>
        Logout
      </Link>
    </div>
  );
};

const styles = {
  wrapper: {
    width: 200,
    height: 0,
    zIndex: 1,
    position: 'absolute',
    right: 10,
    top: 64,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    overflowY: 'hidden',
    transition: 'all 0.2s',
    borderBottomRadius: 5,
    background: '#FFFFFF',
  },
};

const mapStateToProps = (state) => {
  return {
    userId: state.login.user.userId,
  };
};

export default connect(mapStateToProps)(Flyout);
