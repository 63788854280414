import * as React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import tw from 'twin.macro';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';

import Label from './styled-components/Label';
import TextFiled from './react-form-hook-components/TextFiled';
import MobileNumberField from './react-form-hook-components/MobileNumberField';
import NativeSelectField from './react-form-hook-components/NativeSelectField';
import RoundedButton from '../../common/components/form-components/rounded-button';
import {
  hideSnackBar,
  showSnackBar,
} from '../../../store/common/actions/common.actions';
import { connect } from 'react-redux';
import * as partnerClient from '../../../utils/api/network/partner/partner-client';
import * as IAmUserClient from '../../../utils/api/i-am/i-am-user/i-am-user-client';

const cacheValue = (asyncValidate) => {
  let _valid = false;
  let _value = '';

  return async (value) => {
    if (value !== _value) {
      const response = await asyncValidate(value);
      _value = value;
      _valid = response;
      return response;
    }
    return _valid;
  };
};

const FormGroup = tw.div`
  space-y-1
`;

function SignUp({ showSnackBar, hideSnackBar, setMobilePage }) {
  const validationSchema = yup.object().shape(
    {
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      email: yup
        .string()
        .email('Invalid email address')
        .required('Email address is required')
        .test({
          name: 'unique-email',
          message: 'Email address is already exits',
          test: React.useRef(
            cacheValue(async (value) => {
              if (
                !yup
                  .string()
                  .email('Invalid email address')
                  .required('Email address is required')
                  .isValidSync(value)
              )
                return false;
              try {
                await validateEmailAddress(value);
                return true;
              } catch (err) {
                return false;
              }
            }, []),
          ).current,
        }),
      company: yup.string().required('Company name is required'),
      countryCode: yup.string().required('Country code is required'),
      mobileNumber: yup
        .string()
        .required('Mobile number is required')
        .test({
          name: 'unique-mobile-no',
          message: 'Mobile number is already exits',
          test: React.useRef(
            cacheValue(async (value) => {
              const isValid = yup
                .string()
                .required('Mobile number is required')
                .isValidSync(value);

              if (!isValid) {
                return true;
              }
              try {
                await validateMobileNumber(value);
                return true;
              } catch (err) {
                return false;
              }
            }),
          ).current,
        }),
    },
    [],
  );

  const { register, control, handleSubmit, errors, formState } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const { mutateAsync: createPartner } = useMutation(
    partnerClient.createPartner,
    {
      onSuccess: () => {
        showSnackBar({
          variant: 'success',
          message: 'Partner is created successfully.',
        });
        setTimeout(hideSnackBar, 5000);
      },
      onError: (error) => {
        showSnackBar({ variant: 'error', message: error.status.message });
        setTimeout(hideSnackBar, 5000);
      },
    },
  );

  const { mutateAsync: validateMobileNumber } = useMutation(
    IAmUserClient.validateMobileNumber,
  );

  const { mutateAsync: validateEmailAddress } = useMutation(
    IAmUserClient.validateEmailAddress,
  );

  return (
    <form
      tw="grid max-w-md grid-cols-4 gap-4"
      onSubmit={handleSubmit(async (values) => {
        const payload = {
          name: values.company,
          email: values.email,
          countryCode: values.countryCode,
          contactNo: values.mobileNumber,
          userName: values.firstName,
          lastName: values.lastName,
          serviceType: 'CARRIER',
        };

        await createPartner(payload);
        setMobilePage();
      })}
    >
      <FormGroup tw="col-span-2">
        <Label htmlFor="firstName">First Name</Label>
        <TextFiled
          id="firstName"
          name="firstName"
          control={control}
          error={errors?.firstName?.message}
        />
      </FormGroup>
      <FormGroup tw="col-span-2">
        <Label htmlFor="lastName">Last Name</Label>
        <TextFiled
          id="lastName"
          name="lastName"
          control={control}
          error={errors?.lastName?.message}
        />
      </FormGroup>
      <FormGroup tw="col-span-4">
        <Label htmlFor="email">Email</Label>
        <TextFiled
          type="email"
          id="email"
          name="email"
          control={control}
          error={errors?.email?.message}
        />
      </FormGroup>
      <FormGroup tw="col-span-4">
        <Label htmlFor="company">Company</Label>
        <TextFiled
          id="company"
          name="company"
          control={control}
          error={errors?.company?.message}
        />
      </FormGroup>
      <FormGroup tw="col-span-4">
        <Label htmlFor="mobileNumber">Mobile Number</Label>
        <div tw="relative">
          <div tw="absolute flex h-9">
            <NativeSelectField
              id="countryCode"
              tw="ring-transparent w-24"
              name="countryCode"
              control={control}
              defaultValue="91"
              error={errors?.countryCode?.message}
            >
              <option value="91" label="🇮🇳 91" />
              <option value="61" label="🇲🇾 MY" />
            </NativeSelectField>
          </div>
          <MobileNumberField
            id="mobileNumber"
            tw="pl-24 pr-3"
            name="mobileNumber"
            control={control}
            error={errors?.mobileNumber?.message}
          />
        </div>
      </FormGroup>
      <FormGroup tw="flex items-start col-span-4">
        <RoundedButton
          tw="focus:outline-none"
          type="submit"
          color="primary"
          variant="contained"
          disabled={!formState.isValid || !formState.isDirty}
        >
          Complete Sign up
        </RoundedButton>
      </FormGroup>
    </form>
  );
}

function mapStateToDispatch(dispatch) {
  return {
    showSnackBar: (config) => dispatch(showSnackBar(config)),
    hideSnackBar: () => dispatch(hideSnackBar()),
  };
}

export default connect(null, mapStateToDispatch)(SignUp);
