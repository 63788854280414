import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NotFound from '../common/components/not-found/not-found.component';
import AppLayout from '../common/components/layout/layout.component';
import Login from './login/login';
import Loader from '../common/components/loader/loader.component';
import GuardedRoute from '../common/components/GuardedRoute/guarded-route.component';
import TrackMyShipment from './track-my-shipment/track-my-shipment.container';
import { MODULES } from '../common/constants';
import { isModulePermitted } from '../../services/redux-state.service';
import { IdleLogout } from '../common/utils/IdleLogout';
import { clearAuth } from '../../store/common/actions/login.actions';
import PropTypes from 'prop-types';
import SignUp from './signup';

const Dashboard = lazy(() => import('./new-dashboard'));
const Reports = lazy(() => import('./reports/reports.container'));
const Tracking = lazy(() => import('./tracking/tracking.container'));
const Configurations = lazy(() =>
  import('./configurations/configurations.index'),
);
const Linehaul = lazy(() => import('./linehaul/linehaul.index'));
const Operations = lazy(() => import('./operations/operations.index'));
const Track = lazy(() => import('./track/track.container'));
const ExternalTrack = lazy(() => import('./track/external.track.container'));
const COD = lazy(() => import('./cod'));
const Feeder = lazy(() => import('./feeder/feeder.index'));
const PUD = lazy(() => import('./pud/pud.index'));
const Billing = lazy(() => import('./billing/billing.index'));

function useRouteChange(action__clearAuth) {
  let location = useLocation();
  useEffect(
    (data) => {
      IdleLogout.logoutUserIfTimeOutExceeded(null, action__clearAuth);
      IdleLogout.setLastActivityTime();
    },
    [location],
  );
}

const Routes = (props) => {
  const { login = {}, action__clearAuth } = props;
  const { auth, user } = login;
  useRouteChange(action__clearAuth);
  const routes = [
    {
      path: '/configurations',
      component: Configurations,
      exact: false,
      canActivate: isModulePermitted(MODULES.CONFIGURATIONS),
    },
    {
      path: '/linehaul',
      component: Linehaul,
      exact: false,
      canActivate: isModulePermitted(MODULES.LINEHAUL),
    },
    // {
    //   path: '/feeder',
    //   component: Feeder,
    //   exact: false,
    //   canActivate: true,
    // },
    // {
    //   path: '/pud',
    //   component: PUD,
    //   exact: false,
    //   canActivate: true,
    // },
    {
      path: '/reports',
      component: Reports,
      exact: true,
      canActivate: isModulePermitted(MODULES.REPORTS),
    },
    {
      path: '/tracking/:waybillNo',
      component: Track,
      exact: true,
      canActivate: isModulePermitted(MODULES.TRACKING),
    },
    {
      path: '/tracking/external/:waybillNo',
      component: ExternalTrack,
      exact: true,
      canActivate: isModulePermitted(MODULES.TRACKING),
    },
    {
      path: '/tracking',
      component: Tracking,
      exact: true,
      canActivate: isModulePermitted(MODULES.TRACKING),
    },
    {
      path: '/operations',
      component: Operations,
      exact: false,
      canActivate: isModulePermitted(MODULES.OPERATIONS),
    },
    {
      path: '/dashboard',
      component: Dashboard,
      exact: true,
      canActivate: isModulePermitted(MODULES.DASHBOARD),
    },
    {
      path: '/',
      component: Dashboard,
      exact: true,
      canActivate: isModulePermitted(MODULES.DASHBOARD),
    },
    {
      path: '/cod',
      component: COD,
      exact: false,
      canActivate: isModulePermitted(MODULES.COD),
    },
    {
      path: '/billing',
      component: Billing,
      exact: false,
      canActivate: true,
    },
  ];
  if (auth && user) {
    return (
      <AppLayout {...props}>
        <Suspense fallback={<Loader />}>
          <Switch>
            {routes.map((route) => (
              <GuardedRoute
                canActivate={() => route.canActivate}
                redirectTo="/"
                exact={route.exact}
                key={route.path}
                path={route.path}
                component={route.component}
              />
            ))}
            {/* <Route path="/" render={() => <Redirect from="" to="/dashboard" />} /> */}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </AppLayout>
    );
  }
  return (
    <Switch>
      <Route
        path="/track-my-shipment/:client_key"
        component={TrackMyShipment}
      />
      <Route path="/" exact component={Login} />
      <Route path="/signup" exact component={SignUp} />
      <Route render={() => <Redirect from="" to="/" />} />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  login: state.login,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      action__clearAuth: clearAuth,
    },
    dispatch,
  );

Routes.propTypes = {
  action__clearAuth: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
