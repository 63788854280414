import React from 'react';
import { Button, Icon } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import styles from './not-found.module.scss';

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      color: theme.palette.primary.main
    },
    button: {
      margin: theme.spacing(1),
    },
    container: {
      backgroundColor: theme.palette.background.default
    }
  }),);

const NotFound = () => {
  const classes = useStyles();
  return (
    <div className={`${styles.container} ${classes.container}`}>
      <div className={styles.content}>
        <p className={`${styles.heading} ${classes.text}`}> 404 Error </p>
        <p className={`${styles.subtitle} ${classes.text}`}> We couldn’t find what you are looking for ! </p>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button variant="outlined" color="primary" className={`${styles.action} ${classes.button}`}>
            <Icon>home</Icon> <span className={styles.actionText}> Go to Home </span>
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
