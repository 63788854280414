import axios from 'axios';
import { NETWORK_URL } from '../environments';
import { responseErrorHander } from './network-interceptor.service';

export const getLocationIdByPincode = async (pincode) => {
  try {
    const response = await axios.get(`${NETWORK_URL}/locations/getLocationByPincode/${pincode}`);
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
}

export const getCityByZipcode = async (zipcode) => {
  try {
    const response = await axios.get(
      `${NETWORK_URL}/pincodes/zipcodes/${zipcode}`,
    );
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const getAllZipcode = async (zipcode) => {
  try {
    const response = await axios.get(`${NETWORK_URL}/pincodes/serviceable`);
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const getAllCities = async () => {
  try {
    const response = await axios.get(`${NETWORK_URL}/cities`);
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const getServiceablePincodes = async (cityIds = []) => {
  try {
    const response = await axios.post(`${NETWORK_URL}/pincodes/city`, cityIds);
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const createLocation = async (values) => {
  const { selectedPincodes = [] } = values;
  const pincodeIds = selectedPincodes.map((pincode) => pincode.id);

  const form = {
    name: values.name,
    code: values.code,
    type: values.type,
    hubId: values?.hub?.id || null,
    address: {
      address1: values.address1,
      address2: values.address2,
      zipcode: values.zipcode,
      cityId: values.cityId,
    },
    pincodeIds,
  };

  try {
    const response = await axios.post(`${NETWORK_URL}/locations`, form);
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const getLocationByCode = async (code) => {
  try {
    const response = await axios.get(`${NETWORK_URL}/locations/code/${code}`);
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const checkServiceablityByPincode = async (code) => {
  try {
    const response = await axios.get(
      `${NETWORK_URL}/pincodes/serviceable/${code}`,
    );
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const getAllLocations = async () => {
  try {
    const response = await axios.get(`${NETWORK_URL}/locations`);
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const validateName = async (name) => {
  try {
    const response = await axios.get(`${NETWORK_URL}/locations/name/${name}`);
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const getById = async (id) => {
  try {
    const response = await axios.get(`${NETWORK_URL}/locations/${id}`);
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const getMultipleLocationsByIds = async (payload) => {
  try {
    const response = await axios.post(`${NETWORK_URL}/locations/ids`, payload);
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const updateStatus = async (locationId, status) => {
  try {
    const response = await axios.put(
      `${NETWORK_URL}/locations/updateStatus?locationId=${locationId}&status=${status}`,
    );
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const editLocation = async (values) => {
  const { selectedPincodes = [] } = values;
  const pincodeIds = selectedPincodes.map((pincode) => pincode.id);

  const form = {
    id: values.id,
    name: values.name,
    code: values.code,
    type: values.type,
    hubId: values?.hub?.id || null,
    openTime: values.openTime,
    closeTime: values.closeTime,
    address: {
      id: values.addressId,
      address1: values.address1,
      address2: values.address2,
      zipcode: values.zipcode,
      cityId: values.cityId,
    },
    pincodeIds,
  };

  try {
    const response = await axios.put(`${NETWORK_URL}/locations`, form);
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const getHubs = async () => {
  try {
    const response = await axios.get(`${NETWORK_URL}/locations/hub`);
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

export const getBranches = async () => {
  try {
    const response = await axios.get(`${NETWORK_URL}/locations/branch`);
    return response.data;
  } catch (e) {
    return responseErrorHander(e);
  }
};

