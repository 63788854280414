import { client } from '../../api-client';
import { NETWORK_URL } from '../../../../environments';

function getUsers(id = '') {
  const config = {
    baseURL: NETWORK_URL,
  };

  return client(`users/${id}`, config);
}

function getLoggedInUserDetails() {
  const config = {
    baseURL: NETWORK_URL,
  };

  return client(`users/loggedIn`, config);
}

export default { getUsers, getLoggedInUserDetails };
