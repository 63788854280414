import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const pallete = {
  danger: {
    backgroundColor: '#DC3D14',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#DC3D14',
    },
  },
};

const RoundedButton = withStyles(() => ({
  root: (props) => {
    return {
      borderRadius: '24px',
      fontWeight: 'bold',
      paddingTop: '4px',
      paddingBottom: '4px',
      ...pallete[props.color],
    };
  },
}))(Button);

export default RoundedButton;
