/** @jsx jsx */
import { jsx } from '@emotion/react';
import tw, { styled } from 'twin.macro';

const NativeSelectField = styled.select(({ disabled, error }) => [
  tw`block border-0 h-full w-full py-2 px-3 rounded-md focus:outline-none ring-1 ring-gray-300`,
  disabled ? tw`bg-gray-200` : tw`focus:ring-2 focus:ring-blue-700 `,
  error ? tw`ring-red-400 focus:ring-2 focus:ring-red-400` : tw``,
]);

export default NativeSelectField;
