import * as React from 'react';
import ReplayIcon from '@material-ui/icons/Replay';
import Modal from 'react-modal';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import 'twin.macro';

import RoundedButton from '../form-components/rounded-button';

Modal.setAppElement('#root');

function HardRefresh() {
  const releaseDate = '2021-01-16';
  const [isOpen, setIsOpen] = React.useState(() => {
    const storedReleaseDate = localStorage.getItem('refreshDate');
    if (releaseDate === storedReleaseDate) {
      return false;
    } else {
      return true;
    }
  });

  const handleHardRefresh = () => {
    window.location.reload(true);

    window.localStorage.setItem('refreshDate', releaseDate);
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 9999,
          backgroundColor: 'rgba(44, 44, 44, 0.7)',
          backdropFilter: 'blur(4px)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderWidth: 0,
          borderRadius: '1rem',
        },
      }}
    >
      <div tw="flex flex-col p-10 mx-10 space-y-6 bg-white w-96">
        <p tw="font-semibold text-center text-custom-4b4b4b">
          We’ve made several awesome changes to our dashboard that require you
          to <br />
          refresh the page!
        </p>
        <div tw="mx-auto">
          <RoundedButton
            color="primary"
            variant="contained"
            onClick={handleHardRefresh}
          >
            <ReplayIcon style={{ transform: 'scaleX(-1)', marginRight: 8 }} />
            refresh
          </RoundedButton>
        </div>
        <p tw="text-xs text-center text-custom-4b4b4b">
          To refresh your page, click the refresh button above.
        </p>
      </div>
    </Modal>
  );
}

export default HardRefresh;
