import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styles from './layout.module.scss';
import Header from '../header/header.component';
import SideNav from '../side-nav/side-nav.component';
import errorConfigs from '../../../../utils/error-configs';
import { errorHandler } from '../../../../store/common/actions/common.actions';
import {
	setLocationInUse,
	setPermissions,
	setUser,
} from '../../../../store/common/actions/login.actions';
import Loader from '../loader/loader.component';
import StatusModal from '../status-modal/status-modal';
import rolesService from '../../../../services/roles.service';
import { logout } from '../../utils/common';

class Layout extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			collapse: true,
		};
	}

	async componentDidMount() {
		const {
			history = {},
			login: { auth, user },
		} = this.props;
		if (!auth || !user) {
			logout();
			return history.replace('/');
		}
		this.getPermissions(this.props);
	}

	getPermissions = async (props) => {
		const {
			login: { user },
			action__setPermissions,
		} = props;
		const { roleId } = user;
		const permissionsResponse = await rolesService.getRoleModules(roleId);
		const { status = {}, response = {} } = permissionsResponse;
		if (status.success) {
			const { permissionModule = [] } = response;
			action__setPermissions(permissionModule);
		}
	};

	toggleSidebarCollapse = () => {
		const { collapse } = this.state;
		this.setState({ collapse: !collapse });
	};

	handleError(error, errorHandlerAction) {
		switch (error.status) {
			case 401:
				return (
					<StatusModal
						message={errorConfigs['401'].message}
						status='failed'
						onClose={() => errorHandlerAction(null)}
					/>
				);
			case 403:
				return (
					<StatusModal
						message={errorConfigs['403'].message}
						status='failed'
						onClose={() => errorHandlerAction(null)}
					/>
				);
			default:
				return (
					<StatusModal
						message={errorConfigs['default'].message}
						status='failed'
						onClose={() => errorHandlerAction(null)}
					/>
				);
		}
	}

	render() {
		const {
			error,
			action__errorHandler,
			action__setLocationInUse,
			children,
			common,
			login,
			history,
		} = this.props;
		const { permissions } = login;
		const { loader } = common;
		const { collapse } = this.state;

		return (
			<div className={styles.container}>
				<Header setLocationInUse={action__setLocationInUse} history={history} />
				<SideNav
					collapse={collapse}
					toggleSidebarCollapse={this.toggleSidebarCollapse}
					permissions={permissions}
					{...this.props}
				/>
				<div className={`${collapse ? '' : styles.blurScreen}`}></div>
				<div className={` ${styles.routes} ${styles.full} `}>{children}</div>
				{loader ? <Loader /> : null}
				{error ? this.handleError(error, action__errorHandler) : []}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	common: { ...state.common },
	login: { ...state.login },
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			action__errorHandler: errorHandler,
			action__setLocationInUse: setLocationInUse,
			action__setPermissions: setPermissions,
			action__setUser: setUser,
		},
		dispatch
	);

Layout.propTypes = {
	error: PropTypes.object,
	children: PropTypes.object,
	common: PropTypes.object,
	login: PropTypes.object,
	history: PropTypes.object,
	action__errorHandler: PropTypes.func,
	action__setLocationInUse: PropTypes.func,
	action__setPermissions: PropTypes.func,
	action__setUser: PropTypes.func,
};

Layout.defaultProps = {
	error: null,
	children: {},
	common: {},
	login: {},
	history: {},
	action__errorHandler: Function,
	action__setLocationInUse: Function,
	action__setPermissions: Function,
	action__setUser: Function,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
