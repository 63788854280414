import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
  primary: { main: '#00358d', contrastText: '#ffffff' },
  secondary: { main: '#de404e', contrastText: '#283e4a' },
  default: { main: '#4B4B4B', contrastText: '#ffffff' },
};

const theme = createMuiTheme({
  palette,
  typography: {
    fontSize: 14,
    fontFamily: 'proxima-nova, sans-serif',
  },
  overrides: {
    MuiFormControlLabel: {
      root: {
        color: '#6E6E6E',
        marginRight: '32px',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
        fontFamily: 'proxima-nova, sans-serif',
      },
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: '#36B964',
        },
        '&$active': {
          color: '#36B964',
        },
      },
      active: {},
      completed: {},
    },
  },
});

export default theme;
