import Axios from 'axios';
import { PARTNER_URL } from '../../environments';

async function client(
  endpoint,
  { baseURL = PARTNER_URL, body, ...customConfig } = {},
) {
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    const token = JSON.parse(localStorage.getItem('persist:root')).login.auth
      .access_token;

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  } catch (error) {}

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.data = body;
  }

  try {
    const { data } = await Axios(`${baseURL}/${endpoint}`, config);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export { client };
