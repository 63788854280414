import loginImage from '../../../assets-stage.addup.network/images/bg.svg';

const LoginStyles = {
  wrapper: {
    width: '100vw',
    height: '100vh',
    background:
      'transparent linear-gradient(90deg, #003A8A 7%, #00A29D 80%) 0% 0% no-repeat padding-box',
  },

  rowFirst: {
    width: '100%',
    height: '100%',
    position: 'relative',
    background: `url(${loginImage}) 0% 0% no-repeat padding-box`,
    backgroundSize: 'contain',
  },

  rightPaneWrapper: {
    width: '40%',
    // marginLeft: -35,
    background: '#FAFAFA 0% 0% no-repeat padding-box',
    borderBottomLeftRadius: 30,
    borderTopLeftRadius: 30,
    padding: 10,
    boxShadow: '0px 0px 48px #2C2C2C33',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
  },

  rightPaneColumn: {
    width: 341,
    alignSelf: 'center',
  },
};

export default LoginStyles;
